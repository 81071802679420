export const rootNode = {
  root: {
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
    children: [
      {
        type: 'paragraph',
        indent: 0,
        direction: null,
        format: '',
        version: 1,
        children: []
      }
    ]
  }
};

export const enum ModalOpenState {
  Button,
  Youtube
}

export const enum EditorPlugin {
  YoutubeEmbedment,
  MentionedProduct
}
