import { NPLInputHierarchy } from './types';

export const getInputTextClassName = (size: string) => {
  switch (size) {
    case 'sm':
      return 'text-body-sm';
    case 'md':
      return 'text-body-md';
  }
};

export const getInputPaddingClassName = (size: string) => {
  switch (size) {
    case 'sm':
      return 'px-16 py-8';
    case 'md':
      return 'px-8 py-12';
  }
};

export const getInputDisplayType = (
  type: NPLInputHierarchy,
  disabled: boolean
) => {
  switch (type) {
    case 'filled':
      return 'border border-white-default bg-npl-neutral-light-solid-2 ';
    case 'outline':
      if (disabled) {
        return 'bg-npl-neutral-light-solid-3';
      }
      return 'ring-inset ring-1 ring-npl-neutral-light-solid-7 bg-transparent shadow-npl-styles-button-shadow hover:ring-npl-neutral-light-solid-8';
  }
};

export const getInputFocusClassName = (type: NPLInputHierarchy) => {
  switch (type) {
    case 'filled':
      return 'border border-white-default bg-npl-neutral-light-solid-2 ';
    case 'outline':
      return 'focus:ring-npl-neutral-light-solid-8 focus:border-npl-neutral-light-solid-8';
  }
};
