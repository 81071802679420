import { useMaskito } from '@maskito/react';
import classNames from 'classnames';
import React, { ForwardRefRenderFunction, Ref, forwardRef } from 'react';

import NPLInputBottomSection from '../components/input/NPLInputBottomSection';
import NPLInputTopSection from '../components/input/NPLInputTopSection';
import NPLInputLeadTailSection from './components/NPLInputLeadTailSection';
import type { NPLInputProps } from './types';
import {
  getInputDisplayType,
  getInputFocusClassName,
  getInputPaddingClassName,
  getInputTextClassName
} from './utils';

type NPLInputPropsWithoutRef = Omit<NPLInputProps, 'forwardRef'>;

const NPLInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  NPLInputProps
> = (
  {
    // Above input
    topSectionProps,
    // Lead and tail section
    leadSection,
    tailSection,
    // Input props
    size = 'md',
    hierarchy = 'outline',
    rounded,
    disabled,
    value = '',
    maskPattern,
    alignText = 'left',
    maxLength,
    error,
    testId,
    // Bottom input
    bottomSectionProps,
    // Other native input props
    ...nativeInputProps
  },
  ref
) => {
  const maskPatternRef = useMaskito({ options: maskPattern });

  const inputRef = (ref as Ref<HTMLInputElement>) || maskPatternRef;

  return (
    <div className="c-NPLInput flex flex-col gap-6 py-12">
      {topSectionProps && Object.values(topSectionProps).length > 0 && (
        <NPLInputTopSection {...topSectionProps} />
      )}
      <div
        className={classNames([
          'c-NPLInput flex min-h-[40px] rounded-8 items-center ring-inset-[2] text-body-sm transition-all',
          {
            'overflow-hidden rounded-full': rounded,
            'bg-white-default': !disabled
          },
          getInputDisplayType(hierarchy, disabled)
        ])}>
        {leadSection && (
          <NPLInputLeadTailSection component={leadSection} />
        )}

        <input
          ref={inputRef}
          data-test-id={
            testId ||
            `npl-input-${topSectionProps?.label?.replace(/\s/g, '-').toLowerCase() || ''}`
          }
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          className={classNames([
            `w-full h-full border-1 focus:ring-inset focus:ring-1 border-transparent flex-shrink flex-grow rounded-8 outline-none px-8 transition-all bg-transparent`,
            {
              'rounded-l-0': leadSection,
              'rounded-r-0': tailSection,
              'border-npl-error-light-9 ring-npl-error-light-9 focus:ring-npl-error-light-9':
                error,
              'text-npl-text-icon-on-light-surface-tertiary': disabled,
              'text-right': alignText === 'right'
            },
            getInputPaddingClassName(size),
            getInputTextClassName(size),
            !error && getInputFocusClassName(hierarchy)
          ])}
          {...nativeInputProps}
        />

        {tailSection && (
          <NPLInputLeadTailSection component={tailSection} />
        )}
      </div>
      <NPLInputBottomSection
        {...bottomSectionProps}
        error={error}
        valueLength={typeof value === 'string' ? value?.length : 0}
        maxLength={maxLength}
      />
    </div>
  );
};

const NPLInput = forwardRef<HTMLInputElement, NPLInputPropsWithoutRef>(
  NPLInputBase
);

export default NPLInput;
