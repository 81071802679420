import classNames from 'classnames';
import { FC } from 'react';

import { NPLModalContentProps } from '../types';

const NPLModalContent: FC<NPLModalContentProps> = ({
  children,
  className
}) => {
  return (
    <div
      className={classNames(
        'c-NPLModalContent h-full overflow-auto p-24 hide-scrollbar',
        className
      )}>
      {children}
    </div>
  );
};

export default NPLModalContent;
