import { useRouter } from 'next/navigation';
import { useMemo, useState } from 'react';

import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  MEMBER_PORTAL_DEFAULT_PAGE_ROUTE
} from '@/utility/routesHelper';

import useLandingPageJoinAsAffiliateProgram from '@/features/Affiliates/hooks/useLandingPageJoinAsAffiliateProgram';
import { markInitialSignupOverlaySeen } from '@/features/community/utils';

import { useAuthContext } from '@/contexts/AuthContext';

import useUserRoleInCommunity from './useUserRoleInCommunity';

/**
 * Custom hook for retrieving common public page data.
 * @param {Object} props - The props object.
 * @param {Object} props.communityInfo - The community information object.
 * @returns {Object} - The common public page data.
 * @property {boolean} fetchingUserCommunities - Indicates if user communities are being fetched.
 * @property {boolean} isPendingApproval - Indicates if the user is pending approval in the community.
 * @property {boolean} isCommunityAdmin - Indicates if the user is a community admin.
 * @property {boolean} isCommunityMember - Indicates if the user is a community member.
 */
const useUserCommunitiesData = (props) => {
  const router = useRouter();
  const { communityInfo, hasToken } = props;
  const communityId = communityInfo?._id;
  const isDemoCommunity = communityInfo?.isDemo;

  const { user } = useAuthContext();

  const [isSignUpModalOpened, setIsSignUpModalOpened] = useState(false);
  // If isSignupOnlyForCommunity is true, then checkout should only happen for community instead of comm + entity.
  // This is needed for when non member wants to join as affiliate on entity page.
  const [isSignupModalOnlyForCommunity, setIsSignupModalOnlyForCommunity] =
    useState(false);
  const [
    isSignupModalToBecomeAffiliate,
    setIsSignupModalToBecomeAffiliate
  ] = useState(false);
  const [affiliateInfo, setAffiliateInfo] = useState({
    isAffiliateProduct: false,
    communityHasActiveAffiliateProduct: false
  });

  // Identify the type of user in the community.
  const {
    isFetching: loadingUserRoles,
    isUserCommunityRoleFetched,
    communityDataWithUserRole,
    isPendingApproval,
    isCommunityMember,
    isCommunityAdmin,
    isOnGracePeriod,
    refetchUserRoleInCommunity
  } = useUserRoleInCommunity({ hasToken, communityId });

  // user.hasAffiliate is true is user is not an affilaite of the community
  const isCommunityAffiliate = useMemo(
    () => Boolean(user?.hasAffiliate) && isCommunityMember,
    [user, isCommunityMember]
  );

  const joinAsAffiliateProps = useLandingPageJoinAsAffiliateProgram({
    affiliateInfo,
    communityId,
    communityTitle: communityInfo?.title,
    isUserCommunityRoleFetched,
    isPendingApproval
  });

  // nav helper functions
  const goToMemberPortal = () => {
    let route = MEMBER_PORTAL_DEFAULT_PAGE_ROUTE;

    if (isCommunityMember) route += `?activeCommunityId=${communityId}`;

    router.push(route);
  };

  const goToCMP = () => {
    const path = `${CM_PORTAL_HOMEPAGE_ROUTE}?activeCommunityId=${communityId}`;
    router.push(path);
  };

  // const openSignUpModal = (onlySignupToCommunity = false) => {
  const openSignUpModal = (
    params = {
      onlySignupToCommunity: false,
      isSignupModalToBecomeAffiliate: false
    }
  ) => {
    const { onlySignupToCommunity, isSignupModalToBecomeAffiliate } =
      params || {};

    setIsSignUpModalOpened(true);

    setIsSignupModalOnlyForCommunity(Boolean(onlySignupToCommunity));
    setIsSignupModalToBecomeAffiliate(
      Boolean(isSignupModalToBecomeAffiliate)
    );
  };

  const onSignUpPopupClose = () => {
    setIsSignUpModalOpened(false);

    setIsSignupModalOnlyForCommunity(false);
    setIsSignupModalToBecomeAffiliate(false);
    markInitialSignupOverlaySeen(communityId);
  };
  return {
    // community data
    isDemoCommunity,

    // user data
    activeCommunity: communityDataWithUserRole,
    communityDataWithUserRole,
    isUserCommunityRoleFetched,

    loadingUserRoles,
    isPendingApproval,
    isCommunityAdmin,
    isCommunityMember,
    isCommunityAffiliate,
    refetchUserRoleInCommunity,
    isOnGracePeriod,

    // nav helper functions
    goToMemberPortal,
    goToCMP,

    // signup popup
    isSignUpModalOpened,
    setIsSignUpModalOpened,
    openSignUpModal,
    isSignupModalOnlyForCommunity,
    isSignupModalToBecomeAffiliate,
    onSignUpPopupClose,

    // affiliate
    joinAsAffiliateProps,
    // setIsAffiliateProduct
    setAffiliateInfo
  };
};

export default useUserCommunitiesData;
