import classNames from 'classnames';
import React from 'react';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import NextImage from '@/components/common/NextImage';

type NPLGlassImageBackgroundProps = WithComponentClassName<{
  src: string;
}>;

const NPLGlassImageBackground: React.FC<NPLGlassImageBackgroundProps> = ({
  componentClassName,
  src
}) => {
  return (
    <div
      className={classNames(componentClassName, 'w-full h-full relative')}>
      <NextImage
        {...{
          className: `w-full h-full`,
          mobileImgProps: {
            src: src,
            quality: 1,
            layout: 'fill',
            height: '100%',
            objectFit: 'cover'
          }
        }}
      />
      <div
        className="w-full h-full absolute left-0 top-0 z-1 backdrop-blur-[120px] before:backdrop-blur-[120px] before:block before:w-full before:h-full"
        style={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.01) 100%), rgba(255, 255, 255, 0.6)'
        }}
      />
      <div className="absolute left-0 top-0 z-1 h-full w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-white-default" />
    </div>
  );
};

export default withComponentClassName(NPLGlassImageBackground, false);
