import Script from 'next/script';
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import config from '@/utility/config';

export const CommonIntercomContext = createContext({
  boot: () => {},
  shutdown: () => {},
  show: () => {},
  isIntercomLoaded: false
});

export const IntercomProvider = ({ children, appId, apiBase }) => {
  const [isIntercomLoaded, setIsIntercomLoaded] = useState(false);
  //if the delay value is undefined, Intercom loading will be started immediately
  const [isIntercomLoadingStart, setIsIntercomLoadingStart] =
    useState(false);
  const bootIntervalRef = useRef();

  const currentAppId = appId || config.intercomAppId;

  const boot = (params) => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleBoot = () =>
      window.Intercom('boot', {
        api_base: apiBase || config.intercomApiBase,
        app_id: currentAppId,
        ...params
      });

    if (!window.Intercom) {
      setIsIntercomLoadingStart(true);
      bootIntervalRef.current = setInterval(() => {
        if (window.Intercom) {
          setIsIntercomLoaded(true);
          clearInterval(bootIntervalRef.current);
          bootIntervalRef.current = null;

          handleBoot();
        }
      }, 1000);
    } else {
      setIsIntercomLoaded(true);
      handleBoot();
    }
  };

  const shutdown = () => {
    if (!isIntercomLoaded && !window.Intercom) {
      return;
    }
    window.Intercom('shutdown');
  };

  const show = () => {
    if (!isIntercomLoaded && !window.Intercom) {
      return;
    }
    window.Intercom('show');
  };

  //auto show intercom after loading with the timer
  //0 means no auto show
  useEffect(() => {
    if (
      isIntercomLoaded &&
      window.Intercom &&
      config.intercomAutoShowAfterMiliseconds
    ) {
      const timer = setTimeout(() => {
        window.Intercom('show');
      }, config.intercomAutoShowAfterMiliseconds);
      return () => clearTimeout(timer);
    }
  }, [isIntercomLoaded]);

  return (
    <>
      {isIntercomLoadingStart && (
        <Script
          dangerouslySetInnerHTML={{
            __html: `
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${currentAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`
          }}
          strategy="lazyOnload"
          defer
          async
        />
      )}
      <CommonIntercomContext.Provider
        value={{
          isIntercomLoaded,
          boot,
          shutdown,
          show
        }}>
        {children}
      </CommonIntercomContext.Provider>
    </>
  );
};

export const useIntercom = () => useContext(CommonIntercomContext);
