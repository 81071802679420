import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import type {
  MentionedCardEntityDataType,
  MentionedCardSizeType
} from './types';

const MentionedCommunityCard = dynamic(
  () => import('./components/MentionedCommunityCard')
);
const MentionedProductCard = dynamic(
  () => import('./components/MentionedProductCard')
);
const SmallMentionedCommunityCard = dynamic(
  () => import('./components/SmallMentionedCommunityCard')
);
const SmallMentionedProductCard = dynamic(
  () => import('./components/SmallMentionedProductCard')
);

type MentionedCardProps = WithComponentClassName<{
  entity: MentionedCardEntityDataType;
  size: MentionedCardSizeType;
}>;

const MentionedCard: React.FC<MentionedCardProps> = ({
  componentClassName,
  size,
  entity
}) => {
  const { type } = entity || {};
  return (
    <div
      className={classNames(
        componentClassName,
        'flex justify-center w-full'
      )}>
      {type === 'SUBSCRIPTION' ? (
        <>
          {size === 'sm' ? (
            <SmallMentionedCommunityCard entity={entity} />
          ) : (
            <MentionedCommunityCard entity={entity} />
          )}
        </>
      ) : (
        <>
          {size === 'sm' ? (
            <SmallMentionedProductCard entity={entity} />
          ) : (
            <MentionedProductCard entity={entity} />
          )}
        </>
      )}
    </div>
  );
};

export default withComponentClassName(MentionedCard, false);
