import {
  $getSelection,
  COMMAND_PRIORITY_LOW,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { ChangeEvent, useEffect, useState } from 'react';
import { useKeyPressEvent } from 'react-use';

import { t } from '@/utility/localization';

import NPLButton from '@/components/npl/NPLButton';
import NPLInput from '@/components/npl/NPLInput/NPLInput';
import NPLModalNew from '@/components/npl/NPLModalNew';

import { checkIfUrlIsValid } from '@/pages/portal/utils/common';

import { INSERT_BUTTON_COMMAND } from '../../plugins/ButtonPlugin';

const InsertButtonModal = ({ activeEditor, onClose }) => {
  const [link, setLink] = useState('');
  const [label, setLabel] = useState('');

  const [linkErrorMessage, setLinkErrorMessage] = useState('');
  const [labelErrorMessage, setLabelErrorMessage] = useState('');
  const onSubmit = () => {
    let hasError = false;

    if (!label) {
      setLabelErrorMessage(t('label-is-required'));
      hasError = true;
    }

    if (!link) {
      setLinkErrorMessage(t('link-is-required'));
      hasError = true;
    }

    if (link && !checkIfUrlIsValid(link)) {
      setLinkErrorMessage(t('invalid-url'));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    let formattedLink = link;

    if (link.indexOf('https') === -1 && link.indexOf('http') === -1) {
      formattedLink = `https://${link}`;
    }
    activeEditor.dispatchCommand(INSERT_BUTTON_COMMAND, {
      link: formattedLink,
      label
    });
    onClose();
  };

  useEffect(() => {
    if (link !== '' && linkErrorMessage !== '') {
      setLinkErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);
  useEffect(() => {
    if (label !== '' && labelErrorMessage !== '') {
      setLabelErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useEffect(() => {
    // SELECTION_CHANGE_COMMAND fires when selection changes within a Lexical editor.
    activeEditor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        if (selection && selection?.getTextContent()?.length > 0) {
          setLabel(selection?.getTextContent());
        }
      },
      COMMAND_PRIORITY_LOW
    );
  }, [activeEditor]);

  useKeyPressEvent('Enter', onSubmit);

  return (
    <NPLModalNew onClose={onClose}>
      {(onClose) => (
        <NPLModalNew.Container size={500}>
          <NPLModalNew.Header title={t('add-button')} />
          <NPLModalNew.Content className="flex flex-col gap-16">
          <NPLInput
            topSectionProps={{
              label: t('text-to-display')
            }}
            placeholder={t('the-call-to-action-for-the-button')}
            value={label}
            testId="button-label"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setLabel(e.target.value)
            }
            error={labelErrorMessage}
          />
          <NPLInput
            topSectionProps={{
              label: t('url')
            }}
            placeholder={t('paste-your-url-here')}
            testId="button-link"
            value={link}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setLink(e.target.value)
            }
            error={linkErrorMessage}
          />
          </NPLModalNew.Content>
          <NPLModalNew.Footer>
            <div className="mt-16 flex flex-row justify-end gap-16">
              <NPLButton
                size="lg"
                onClick={onClose}
                hierarchy="outline"
                buttonText={t('back')}
              />
              <NPLButton
                size="lg"
                onClick={onSubmit}
                hierarchy="accent_primary"
                buttonText={t('add-button')}
              />
            </div>
          </NPLModalNew.Footer>
        </NPLModalNew.Container>
      )}
    </NPLModalNew>
  );
};

export default InsertButtonModal;
