import { FC, createContext, useContext, useEffect, useState } from 'react';

import { NPLModalContextType, NPLModalProviderProps } from '../types';

const NPLModalContext = createContext<NPLModalContextType>({
  onClose: () => {},
  isClosed: false,
  closeWithAnimation: () => {},
  animationDuration: 250
});

export const NPLModalProvider: FC<NPLModalProviderProps> = ({
  children,
  onClose
}) => {
  const [isClosed, setIsClosed] = useState(false);

  const animationDuration = 250;

  const closeWithAnimation = () => {
    setIsClosed(true);
  };

  const contextValue: NPLModalContextType = {
    onClose,
    isClosed,
    closeWithAnimation,
    animationDuration
  };

  // This is useEffect is to prevent layout shifting when modal is closed
  // hence the use of setTimeout
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (!isClosed) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      setTimeout(() => {
        document.body.style.overflow =
          originalStyle === 'hidden' ? 'initial' : originalStyle;
      }, animationDuration);
    };
  }, [isClosed]);

  return (
    <NPLModalContext.Provider value={contextValue}>
      {typeof children === 'function'
        ? children(closeWithAnimation)
        : children}
    </NPLModalContext.Provider>
  );
};

export const useNPLModal = () => {
  const context = useContext(NPLModalContext);
  return context;
};
