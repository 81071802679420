// < 12 dec createdAt
// community must have chat
// user must be logged in and a member
// have a endDate for banner to not show
import React, { FC } from 'react';

import Icon from '@/components/npl/Icon';

import AnimatedShowHideDiv from '../AnimatedShowHideDiv/AnimatedShowHideDiv';
import { useMemberEducationBanner } from './useMemberEducationBanner';

type Props = {
  children: React.ReactNode;
  fireStoreId: 'chatTabMovedNotice';
  shouldShow?: boolean;
};

const MemberEducationBanner: FC<Props> = ({
  fireStoreId,
  children,
  shouldShow
}) => {
  const { isBannerShown, hideBanner } = useMemberEducationBanner(
    fireStoreId,
    shouldShow
  );

  return (
    <AnimatedShowHideDiv show={isBannerShown}>
      <div className="w-full text-center py-12 bg-npl-neutral-light-alpha-2 relative">
        {children}
        <div
          className="absolute right-0 top-0 bottom-0 h-full flex justify-center items-center cursor-pointer py-12 px-8"
          onClick={hideBanner}>
          <Icon
            name="x-close"
            width={16}
            height={16}
            className="fill-npl-text-secondary-on-light"
          />
        </div>
      </div>
    </AnimatedShowHideDiv>
  );
};

export default MemberEducationBanner;
