import { usePathname } from 'next/navigation';
import React, { FC } from 'react';

import { openInNewTab } from '@/utility/helpers';
import { formatDateTimeWithLocale, t } from '@/utility/localization';
import { ensureHttps } from '@/utility/stringHelper';

import NextImage from '@/components/common/NextImage';
import { showToast } from '@/components/common/ToastContainer';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import type { ActionType } from '@/pages/member/members/components/MemberCard/MemberDetailsModal';
import { copyTextToClipboard } from '@/pages/portal/utils/events';

import { socialMediaIcons } from '../constants';
import { getSocialMediaLink } from '../utils';

export type UserDataType = {
  country: string;
  description: string;
  fullName: string;
  profileImage: string;
  socialMedia: { [key: string]: string };
  joinDate?: string;
};

type Props = {
  userData: UserDataType;
  editProfile?: (action?: ActionType) => void;
  isLoading?: boolean;
  isViewingOwnProfile?: boolean; // If true, can edit profile
  justViewingProfile?: boolean; // View state
  onSubmit?: (e: React.FormEvent) => void;
};

const PreviewProfile: FC<Props> = ({
  userData,
  onSubmit,
  editProfile,
  justViewingProfile,
  isViewingOwnProfile = false,
  isLoading
}) => {
  const {
    profileImage,
    fullName,
    description,
    country,
    joinDate,
    socialMedia
  } = userData || {};

  const pathname = usePathname();

  const isDefaultProfileImage = userData?.profileImage?.includes(
    'd2yjtdaqamc55g.cloudfront.net'
  );

  // get list of social media keys
  const supportedSocialMediaList = Object.keys(socialMediaIcons);

  // check if social media is supported, and then check
  // if there is value for any supported social media
  const hasSocialMedia =
    Object.keys(userData?.socialMedia)?.filter((key) => {
      if (supportedSocialMediaList?.includes(key)) {
        return userData?.socialMedia?.[key];
      }
    })?.length > 0;

  const isProfileComplete = Boolean(
    !isDefaultProfileImage && userData?.description && hasSocialMedia
  );

  const isUserProfilePath = pathname.includes('profile');

  // The user needs to key in a link for these fields
  const checkIsSocialMediaTypeALink = (socialMediaType: string) => {
    return (
      socialMediaType === 'website' ||
      socialMediaType === 'linkedin' ||
      socialMediaType === 'facebook'
    );
  };

  const checkIfSocialMediaValueIsALink = (socialMediaValue: string) => {
    return socialMediaValue?.includes('https://');
  };

  return !isLoading || !userData?.joinDate ? (
    <div className="flex flex-col items-center w-full gap-16 relative py-24">
      <div className="w-120 h-120 relative">
        <NextImage
          mobileImgProps={{
            src: profileImage,
            layout: 'fill',
            objectFit: 'cover'
          }}
          className="rounded-full"
        />
        {isViewingOwnProfile && !isUserProfilePath && (
          <div className="bottom-[-10px] left-0 right-0 z-10 absolute flex justify-center">
            <div className="px-2 text-overline-xs font-semibold uppercase bg-npl-background-dark-primary text-npl-background-light-primary rounded-4">
              {t('you')}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 text-center">
        {fullName && (
          <div className="text-heading-sm font-semibold text-npl-text-icon-on-light-surface-primary">
            {fullName}
          </div>
        )}
        {country && (
          <div className="text-label-md text-npl-text-secondary-on-light">
            {country}
          </div>
        )}
      </div>
      {joinDate && (
        <div className="flex gap-4 items-center">
          <Icon
            name="calendar"
            width={16}
            height={16}
            className="fill-npl-text-icon-on-light-surface-secondary"
          />
          <div className="text-label-md text-npl-text-icon-on-light-surface-secondary items-center">
            {t('joined-on-date', {
              dateStr: joinDate
                ? formatDateTimeWithLocale(joinDate, 'MMMM yyyy')
                : ''
            })}
          </div>
        </div>
      )}

      {description && (
        <div className="text-body-sm text-center">{description}</div>
      )}
      {socialMedia && Object.keys(socialMedia)?.length > 0 && (
        <div className="flex gap-8 flex-wrap">
          {Object.keys(socialMedia)?.map((social) => {
            const hasValue = !!socialMedia[social];
            // For legacy social media, do not render them
            if (!socialMediaIcons[social]) {
              return <></>;
            }
            if (hasValue) {
              if (social === 'discord') {
                return (
                  <NPLIconButton
                    key={social}
                    icon={{
                      name: 'discord-coloured',
                      path: 'social'
                    }}
                    hierarchy="neutral_secondary"
                    size="md"
                    onClick={() => {
                      copyTextToClipboard({ text: socialMedia[social] });
                      showToast({ text: t('copied') });
                    }}
                  />
                );
              }
              if (social === 'website') {
                return (
                  <NPLIconButton
                    key={social}
                    icon={socialMediaIcons[social]}
                    hierarchy="neutral_secondary"
                    size="md"
                    onClick={() => openInNewTab(socialMedia[social])}
                  />
                );
              }
              return (
                <NPLIconButton
                  key={social}
                  icon={{
                    name: socialMediaIcons[social],
                    path: 'social'
                  }}
                  hierarchy="neutral_secondary"
                  size="md"
                  onClick={() => {
                    // NEW flow: Check if socialMediaType should be a link, and then ensure it is a link
                    // if not, then get the link and open in new tab

                    // OLD flow, Any socialMediaType could be a link, so check for https
                    const url =
                      checkIsSocialMediaTypeALink(social) ||
                      checkIfSocialMediaValueIsALink(socialMedia[social])
                        ? ensureHttps(socialMedia[social])
                        : getSocialMediaLink(socialMedia[social], social);
                    openInNewTab(url);
                  }}
                />
              );
            }
          })}
        </div>
      )}
      {isViewingOwnProfile && !isProfileComplete && !isUserProfilePath && (
        <div className="flex gap-8 flex-wrap justify-center max-w-[360px]">
          {isDefaultProfileImage && (
            <NPLButton
              leadIcon="plus"
              hierarchy="accent_secondary"
              buttonText={t('add-profile-image')}
              size="sm"
              onClick={() => editProfile('addProfilePicture')}
            />
          )}
          {!userData?.description && (
            <NPLButton
              leadIcon="plus"
              hierarchy="accent_secondary"
              buttonText={t('add-bio')}
              size="sm"
              onClick={() => editProfile('addDescription')}
            />
          )}
          {!hasSocialMedia && (
            <NPLButton
              leadIcon="plus"
              hierarchy="accent_secondary"
              buttonText={t('add-social-links')}
              size="sm"
              onClick={() => editProfile('addSocialLinks')}
            />
          )}
        </div>
      )}
      {isViewingOwnProfile && (
        <div className="absolute top-16 right-0">
          <NPLButton
            hierarchy="neutral_secondary"
            buttonText={t('edit')}
            size="md"
            onClick={() => editProfile()}
            stretch
          />
        </div>
      )}
      {!justViewingProfile && (
        <NPLButton
          hierarchy="accent_primary"
          buttonText={t('looking-good')}
          onClick={onSubmit}
          size="lg"
          stretch
        />
      )}
    </div>
  ) : (
    <div className="flex flex-col items-center w-full gap-16 relative">
      <div className="w-120 h-120 bg-npl-neutral-light-alpha-3 animate-pulse rounded-full"></div>
      <div className="h-80 bg-npl-neutral-light-alpha-3 animate-pulse rounded-12 w-full"></div>
    </div>
  );
};

export default PreviewProfile;
