import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { t } from '@/utility/localization';
import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import { getMentionedProducts } from '@/services/community/communitiesMagicReachService';

import NextImage from '@/components/common/NextImage';
import { showErrorToast } from '@/components/common/ToastContainer';
import NPLButton from '@/components/npl/NPLButton';
import NPLSearchDropdown from '@/components/npl/NPLSearchDropdown';

import { MentionedEntityDataType } from '../types';

type MentionedProductDropdownProps = WithComponentClassName<{
  communityId: string;
  onSelect: (selected: unknown) => void;
}>;

const MentionedProductDropdown: React.FC<
  MentionedProductDropdownProps
> = ({ componentClassName, onSelect, communityId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entities, setEntities] = useState<Array<MentionedEntityDataType>>(
    []
  );
  const [isEntityOptionsLoading, setIsEntityOptionsLoading] =
    useState(false);

  useEffect(() => {
    const fetchEntities = async () => {
      setIsEntityOptionsLoading(true);
      if (communityId) {
        const { data, error } = await getMentionedProducts(communityId, {
          search: searchTerm
        });
        if (error) {
          showErrorToast(error);
          return;
        }
        setEntities(data?.data?.products || []);
      }

      setIsEntityOptionsLoading(false);
    };

    fetchEntities();
  }, [searchTerm]);

  return (
    <div className={classNames(componentClassName)}>
      <NPLSearchDropdown
        hasSearch
        options={entities.map((entity) => ({
          label: entity?.title,
          value: entity?.entityObjectId,
          metadata: {
            thumbnail:
              entity?.type === 'SUBSCRIPTION'
                ? entity?.profileImage
                : entity?.thumbnail,
            subtitle: t(entity?.type)
          }
        }))}
        loading={isEntityOptionsLoading}
        onSearch={setSearchTerm}
        searchPlaceholder={t('search-by-product-name')}
        onChange={(id) =>
          onSelect(
            entities?.find((entity) => entity?.entityObjectId === id)
          )
        }
        hasMore={false}
        side="bottom"
        renderTrigger={() => (
          <NPLButton
            size="sm"
            hierarchy="neutral_secondary"
            buttonText={t('product')}
            leadIcon="plus"
          />
        )}>
        {(option) => (
          <NPLSearchDropdown.Item {...option}>
            <NPLSearchDropdown.Lead>
              <div className="w-24 h-24 relative rounded-4">
                <NextImage
                  mobileImgProps={{
                    src: option?.metadata?.thumbnail,
                    layout: 'fill',
                    objectFit: 'cover'
                  }}
                  className="rounded-4"
                />
              </div>
            </NPLSearchDropdown.Lead>
            <NPLSearchDropdown.Content
              eyebrow={option?.metadata?.subtitle}
              label={option?.label}
            />
          </NPLSearchDropdown.Item>
        )}
      </NPLSearchDropdown>
    </div>
  );
};

export default withComponentClassName(MentionedProductDropdown, false);
