import * as yup from 'yup';

import { t } from '@/utility/localization';

import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';

export const getCreateClassSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .trim()
      .max(100, t('title-cannot-be-more-than-100-letters'))
      .required(t('title-is-required')),
    description: yup
      .string()
      .max(5000, t('description-cannot-be-more-than-5000-letters'))
      .notRequired(),
    thumbnail: yup.mixed().required(t('thumbnail-is-required')),
    priceType: yup
      .string()
      .oneOf([PRICE_TYPES.FIXED, PRICE_TYPES.FLEXIBLE])
      .required(t('price-type-is-required')),
    minAmount: yup.string().when('priceType', {
      is: PRICE_TYPES.FLEXIBLE,
      then: () =>
        yup.string().trim().required(t('please-enter-an-amount')),
      otherwise: () => yup.string().notRequired()
    }),
    suggestedAmount: yup.string().when('priceType', {
      is: PRICE_TYPES.FLEXIBLE,
      then: () =>
        yup.string().trim().required(t('please-enter-an-amount')),
      otherwise: () => yup.string().notRequired()
    }),
    amount: yup.string().when('priceType', {
      is: PRICE_TYPES.FIXED,
      then: () => yup.string().required(t('please-enter-an-amount')),
      otherwise: () => yup.string().notRequired()
    })
  });

export const editClassSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(100, t('title-cannot-be-more-than-100-letters'))
    .required(t('title-is-required')),
  description: yup
    .string()
    .max(5000, t('description-cannot-be-more-than-5000-letters'))
    .notRequired(),
  image: yup
    .mixed()
    .test('imageTest', t('thumbnail-is-required'), function (value) {
      const thumbnailExists = !!this.parent.thumbnail;
      return thumbnailExists || value;
    }),
  priceType: yup
    .string()
    .oneOf([PRICE_TYPES.FIXED, PRICE_TYPES.FLEXIBLE])
    .required(t('price-type-is-required')),
  minAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  suggestedAmount: yup.string().when('priceType', {
    is: PRICE_TYPES.FLEXIBLE,
    then: () => yup.string().trim().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  }),
  amount: yup.string().when('priceType', {
    is: PRICE_TYPES.FIXED,
    then: () => yup.string().required(t('please-enter-an-amount')),
    otherwise: () => yup.string().notRequired()
  })
});
