import classNames from 'classnames';
import Link from 'next/link';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import HoverCardComponent from '@/components/common/HoverCard/HoverCardComponent';
import MentionedCard from '@/components/common/MentionedCard';
import type {
  MentionedCardLinkType,
  MentionedCardSizeType
} from '@/components/common/MentionedCard/types';
import NplPopoverMenu from '@/components/common/NPLPopoverMenu/NplPopoverMenu';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import { getLinkTypeOptions, getSizeOptions } from '../constants';
import type { MentionedEntityDataType } from '../types';
import {
  getSelectedLinkTypeOption,
  getSelectedSizeOption,
  sanitizeMentionedProductData
} from '../utils';

type MentionedProductCardContainerProps = WithComponentClassName<{
  deleteNode: () => void;
  entityData: MentionedEntityDataType;
  isEditable: boolean;
  link: string;
  linkType: MentionedCardLinkType;
  size: MentionedCardSizeType;
  updateLinkType: (link: MentionedCardLinkType) => void;
  updateSize: (size: MentionedCardSizeType) => void;
}>;

const MentionedProductCardContainer: React.FC<
  MentionedProductCardContainerProps
> = ({
  componentClassName,
  updateSize,
  size,
  entityData,
  deleteNode,
  updateLinkType,
  linkType,
  link,
  isEditable
}) => {
  const sizeOptions = getSizeOptions();
  const linkTypeOptions = getLinkTypeOptions();
  const selectedLinkTypeOption = getSelectedLinkTypeOption(linkType);

  const cardData = sanitizeMentionedProductData(entityData, size === 'lg');

  const isFree =
    entityData &&
    !entityData.price &&
    (!entityData.priceDetails || !entityData.priceDetails.localAmount);

  const { type } = cardData;

  if (!isEditable) {
    return (
      <Link href={link} target="_blank" className="p-8 w-full">
        <MentionedCard size={size} entity={cardData} />
      </Link>
    );
  }

  return (
    <div
      className={classNames(
        componentClassName,
        'w-full flex justify-center'
      )}>
      <HoverCardComponent
        align="center"
        side="top"
        sideOffset={0}
        closeDelay={100}
        triggerElement={
          <div className="p-8 rounded-12 hover:bg-npl-yellow-dark-alpha-3 transition-all duration-300 w-full">
            <MentionedCard size={size} entity={cardData} />
          </div>
        }
        contentElement={
          <div className="flex space-x-8 items-center">
            <HeadlessDropdown
              renderField={() => (
                <NPLButton
                  hierarchy="plain"
                  size="sm"
                  tailIcon="chevron-down"
                  buttonText={getSelectedSizeOption(size)?.title}
                />
              )}
              renderOptions={({ closeDropdown }) => (
                <NplPopoverMenu
                  onClose={closeDropdown}
                  options={sizeOptions}
                  onOptionClick={(option) => {
                    updateSize(option.id as MentionedCardSizeType);
                  }}
                  selectedOptionId={size}
                />
              )}
            />

            <div className="border-l-1 border-npl-separator-alpha"></div>
            {type === 'SESSION' || isFree ? (
              <NPLButton
                hierarchy="plain"
                size="sm"
                leadIcon={selectedLinkTypeOption?.icon}
                buttonText={selectedLinkTypeOption?.title}
              />
            ) : (
              <HeadlessDropdown
                renderField={() => (
                  <NPLButton
                    hierarchy="plain"
                    size="sm"
                    leadIcon={selectedLinkTypeOption?.icon}
                    tailIcon="chevron-down"
                    buttonText={selectedLinkTypeOption?.title}
                  />
                )}
                renderOptions={({ closeDropdown }) => (
                  <NplPopoverMenu
                    onClose={closeDropdown}
                    options={linkTypeOptions}
                    onOptionClick={(option) => {
                      updateLinkType(option.id as MentionedCardLinkType);
                    }}
                    selectedOptionId={linkType}
                  />
                )}
              />
            )}

            <div className="border-l-1 border-npl-separator-alpha"></div>
            <NPLIconButton
              icon="link-external-01"
              hierarchy="plain"
              size="sm"
              link={{
                href: link,
                target: '_blank'
              }}
            />
            <NPLIconButton
              icon="trash-01"
              hierarchy="plain"
              size="sm"
              onClick={() => {
                deleteNode();
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default withComponentClassName(
  MentionedProductCardContainer,
  false
);
