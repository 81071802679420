import { FC } from 'react';
import { createPortal } from 'react-dom';

import NPLModalBackdrop from './components/NPLModalBackdrop';
import NPLModalContainer from './components/NPLModalContainer';
import NPLModalContent from './components/NPLModalContent';
import NPLModalFooter from './components/NPLModalFooter';
import NPLModalHeader from './components/NPLModalHeader';
import { NPLModalProvider, useNPLModal } from './context/NPLModalContext';
import { NPLModalProps } from './types';

const NPLModal: FC<NPLModalProps> & {
  Container: typeof NPLModalContainer;
  Content: typeof NPLModalContent;
  Footer: typeof NPLModalFooter;
  Header: typeof NPLModalHeader;
} = ({ children, onClose, isCloseOnBackdropClickAllowed = true }) => {
  const modalContent = (
    <NPLModalProvider onClose={onClose}>
      {(closeWithAnimation) => (
        <>
          <NPLModalBackdrop
            isCloseOnBackdropClickAllowed={isCloseOnBackdropClickAllowed}
          />
          {typeof children === 'function'
            ? children(closeWithAnimation)
            : children}
        </>
      )}
    </NPLModalProvider>
  );

  if (typeof window === 'undefined') return null;

  return createPortal(modalContent, document.body);
};

NPLModal.Header = NPLModalHeader;
NPLModal.Content = NPLModalContent;
NPLModal.Footer = NPLModalFooter;
NPLModal.Container = NPLModalContainer;

export default NPLModal;
export { useNPLModal };
