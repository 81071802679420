import classNames from 'classnames';
import { FC } from 'react';

import { useNPLModal } from '..';
import NPLIconButton from '../../NPLIconButton';
import { NPLModalHeaderProps } from '../types';

const NPLModalHeader: FC<NPLModalHeaderProps> = ({
  title,
  className,
  hasCloseIcon = true,
  description
}) => {
  const { closeWithAnimation } = useNPLModal();

  return (
    <div
      className={classNames(
        'c-NPLModalHeader bg-white-default items-center justify-between border-b-1 border-npl-neutral-light-solid-6 px-24 py-16',
        className
      )}>
      <div className="flex justify-between gap-16 items-center">
        {typeof title === 'string' ? (
          <h2 className="text-heading-xs font-semibold text-dark-1b">
            {title}
          </h2>
        ) : (
          title
        )}
        {hasCloseIcon && (
          <div>
            <NPLIconButton
              type="button"
              hierarchy="plain"
              icon="x-close"
              size="md"
              onClick={closeWithAnimation}
            />
          </div>
        )}
      </div>
      <div>
        {Boolean(description) && (
          <span className="mt-4">
            {typeof description === 'string' ? (
              <span className="text-body-md text-npl-text-secondary-on-light">
                {description}
              </span>
            ) : (
              description
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default NPLModalHeader;
