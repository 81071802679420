import React from 'react';

import { timeSince } from '@/utility/dateHelper';
import { t } from '@/utility/localization';

import ProfilePopUpWrapper from '@/features/ProfilePopUp/ProfilePopUpWrapper';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import NplPopoverMenu from '@/components/common/NPLPopoverMenu/NplPopoverMenu';
import NPLIconButton from '@/components/npl/NPLIconButton';

const CommentHeaderSection = ({
  firstName,
  lastName,
  createdAt,
  allowDelete,
  onDeleteClick,
  communityId,
  learnerObjectId
}) => {
  const date = (postDate) => {
    try {
      const postDateFormat = new Date(postDate);
      const timeSinceString = timeSince(postDateFormat);
      return timeSinceString === t('just-now')
        ? timeSinceString
        : t('ago', { time: timeSinceString });
    } catch (e) {
      console.error(e);
      return '';
    }
  };

  return (
    <div className="relative px-12 py-8">
      <div className="mb-6 flex items-center gap-4 mr-[56px]">
        <ProfilePopUpWrapper
          communityId={communityId}
          learnerId={learnerObjectId}>
          <p className="text-label-md font-medium text-npl-text-secondary-on-light">
            {firstName ?? ''} {lastName ?? ''}
          </p>
        </ProfilePopUpWrapper>

        <p className="text-label-sm text-npl-text-tertiary-on-light">
          {date(createdAt)}
        </p>
      </div>

      {allowDelete && (
        <div className="absolute right-0 top-0 rounded-12 p-4">
          <HeadlessDropdown
            alignDropdownWidth
            renderField={() => {
              return (
                <NPLIconButton
                  hierarchy="plain"
                  icon="dots-horizontal"
                  size="sm"
                  className="fill-npl-text-secondary-on-light"
                />
              );
            }}
            renderOptions={({ closeDropdown }) => {
              return (
                <NplPopoverMenu
                  options={[
                    {
                      id: 'delete',
                      title: t('delete-comment'),
                      icon: 'trash-01',
                      isDanger: true,
                      onClick: onDeleteClick
                    }
                  ]}
                  onClose={closeDropdown}
                />
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CommentHeaderSection;
