import classNames from 'classnames';
import { FC } from 'react';

import { NPLModalFooterProps } from '../types';

const NPLModalFooter: FC<NPLModalFooterProps> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={classNames(
        'c-NPLModalFooter w-full flex-shrink-0 min-h-0 border-t-1 border-npl-neutral-light-solid-6 px-24 py-16 bg-white-default',
        className
      )}>
      {children}
    </div>
  );
};

export default NPLModalFooter;
