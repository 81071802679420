import { replaceVariables } from '@/pages/portal/utils/common';

export const getFirstImageFromContent = (content) => {
  if (!content) {
    return null;
  }
  const findImageSrc = (node) => {
    if (Array.isArray(node.children)) {
      for (let child of node.children) {
        if (child.type === 'image') {
          return child.src;
        }
        const nestedImageSrc = findImageSrc(child);
        if (nestedImageSrc) {
          return nestedImageSrc;
        }
      }
    }
    return null;
  };

  return findImageSrc(content.root);
};

export const getDescriptionFromContent = (content, variables) => {
  const arrayOfText = [];
  const childrenTexts = content?.root?.children?.filter(
    (element) =>
      element.type === 'paragraph' ||
      element.type === 'link' ||
      element.type === 'list' ||
      element.type === 'autolink'
  );

  if (childrenTexts) {
    childrenTexts.forEach((element, index) => {
      if (element.type === 'list') {
        // Handle lists
        element.children.forEach((listItem, listIndex) => {
          const listItemText = listItem?.children
            ?.map((ele) => ele?.text)
            .filter(Boolean)
            .join('');
          if (listItemText) {
            // Check list type and add appropriate prefix
            const prefix =
              element.listType === 'number' ? `${listItem.value}. ` : '• ';
            arrayOfText.push(`${prefix}${listItemText}`);
            if (listIndex < element.children.length - 1) {
              arrayOfText.push('\n');
            }
          }
        });
      } else {
        // Handle paragraphs and links
        const text = element?.children
          ?.map((child) => {
            if (child.type === 'autolink') {
              return child.url;
            }
            return child?.type === 'variable'
              ? replaceVariables(child?.variable, variables)
              : child?.text;
          })
          .filter(Boolean)
          .join('');

        if (text) {
          arrayOfText.push(text);
        }
      }

      // Add line break if it's not the last element
      if (index < childrenTexts.length - 1) {
        arrayOfText.push('\n');
      }
    });
  }

  return arrayOfText.join('');
};
