import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { t } from '@/utility/localization';
import {
  getCommunityCheckoutRoute,
  getCommunityPageTabRoute,
  getMemberCommunityPageRoute
} from '@/utility/routesHelper';

import AnnouncementPreviewModal from '@/features/Announcements/AnnouncementPreviewModal';
import MemberCard from '@/features/Announcements/MemberCard/MemberCard';
import { trackCommPublicPagePostSeeMoreClick } from '@/features/Feed/analytics';
// import SignUpPopup from '@/features/SignUpPopup';
import { SIGNUP_ORIGIN } from '@/features/SignUpPopup/constants';
import { getIsUserProfileComplete } from '@/features/User/CompleteYourProfileModal/CompleteYourProfileModalWrapper';
import PreviewProfile from '@/features/User/CompleteYourProfileModal/steps/PreviewProfile';

import { useAuthContext } from '@/contexts/AuthContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Modal from '@/components/common/Modal';
import { showSuccessToast } from '@/components/common/ToastContainer';
import CustomCarousel from '@/components/features/CustomCarousel/CustomCarousel';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import useCommonPublicPageData from '@/hooks/useCommonPublicPageData';
import usePosts from '@/hooks/usePosts';
import useQueryParams from '@/hooks/useQueryParams';

import AboutCommunity from '../components/about/AboutCommunity';
import JoinWhatsappChatCard from '../components/chat/JoinWhatsappChatCard';
import EntityCard from '../components/common/EntityCard';
import MobileCTAFooter from '../components/home/MobileCTAFooter';
import EntityCardSkeletion from '../components/skeletons/EntityCardSkeletion';
import FeedSkeleton from '../components/skeletons/FeedSkeleton';
import { ENTITY_STATUS, TABS_ID } from '../constants';
import useCommunityEntity from '../hooks/useCommunityEntity';

const BecomeAnAffiliateCard = dynamic(
  () => import('@/features/Affiliates/components/BecomeAnAffiliateCard')
);
const YoureAnAffiliateOfTheProductCard = dynamic(
  () =>
    import(
      '@/features/Affiliates/components/YoureAnAffiliateOfTheProductCard'
    )
);
const CommunitySignupModal = dynamic(
  () => import('@/features/CommunitySignupModal/CommunitySignupModal'),
  {
    ssr: false
  }
);
const BecomeAnAffiliateModal = dynamic(
  () => import('@/features/Affiliates/components/BecomeAnAffiliateModal')
);

const Divider = () => (
  <div className="mx-24 h-[1px] bg-npl-neutral-light-solid-4"></div>
);

function HomeTab({
  communityPublicPageData,
  discountPercentageForHighestInterval,
  hasScrolledPastBanner,
  genericTrackingFields,
  isLoggedInVariant, // mainly to hide about description without flickering
  userCommunitiesData,
  mutateCommunityData,
  onJoinCommunity
}) {
  const { user, isLoggedIn } = useAuthContext();
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const { _id: communityId, slug: communityLink } =
    communityPublicPageData || {};

  const [postUsedToSignup, setPostUsedToSignup] = useState(null);
  const showPostSignupModal = !!postUsedToSignup;
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedMemberDetails, setSelectedMemberDetails] = useState(null);

  const {
    isCommunityMember,
    isCommunityAdmin,
    isPendingApproval,
    isCommunityAffiliate,
    isUserCommunityRoleFetched,
    refetchUserRoleInCommunity,
    setIsSignUpModalOpened,
    // affilaite program
    joinAsAffiliateProps,
    setAffiliateInfo
  } = userCommunitiesData || {};

  const {
    discountCodeFromQueryParams,
    pricingData,
    priceTagText,
    affiliateCode
  } = useCommonPublicPageData({ communityInfo: communityPublicPageData });

  // Affiliate Program Data start
  const isAffiliateProduct = Boolean(
    communityPublicPageData?.affiliateInfo?.isAffiliateProduct
  );

  const {
    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate
  } = joinAsAffiliateProps;

  useEffect(() => {
    if (communityPublicPageData?.affiliateInfo) {
      setAffiliateInfo(communityPublicPageData?.affiliateInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityPublicPageData?.affiliateInfo]);
  // Affiliate Program Data end

  const isApplicationBasedComm =
    communityPublicPageData?.request_approval ||
    communityPublicPageData?.applicationConfig?.requestApproval;

  const isCommunityCheckoutRequired =
    isApplicationBasedComm || communityPublicPageData?.isPaidCommunity;

  const { link: platformLink } = communityPublicPageData?.platform || {};

  const { upcomingEntities, ongoingEntities, isFetchingEntities } =
    useCommunityEntity(communityId);

  const {
    allPosts,
    loading: loadingPosts,
    paginationMetadata: postsPaginationMetadata,
    loadingMorePosts,
    fetchPosts
  } = usePosts({
    communityId,
    pageSize: 3
  });

  let currentPosts = allPosts || [];

  // only show 3 posts, still not checking for only CREATOR posts
  currentPosts = currentPosts.slice(0, 3);

  const { isGtEqMd } = useWindowWidthContext();

  const showSeeMoreCta = !!postsPaginationMetadata?.next;

  const goToCommunityCheckout = () => {
    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityPublicPageData?._id,
      communityCode: communityPublicPageData?.code,
      discountCode: discountCodeFromQueryParams,
      affiliateCode,
      recipientObjectId: currentRouterQuery?.recipientObjectId,
      requestor: currentRouterQuery?.requestor
    });

    router.push(checkoutRoute);
  };

  const onUnlockPostClick = (post) => {
    // For paid community or appln gated, go to checkout
    if (isCommunityCheckoutRequired) {
      goToCommunityCheckout();
      return;
    }

    // else open signup modal i.e for FREE Signup
    setPostUsedToSignup(post);
  };

  const closePostSignupModal = () => setPostUsedToSignup(null);

  const onSignViaPostSuccess = (isNewMember) => {
    if (isNewMember) {
      showSuccessToast(
        t('success-you-are-a-member-of-community', {
          communityName: communityPublicPageData?.title
        })
      );
    }

    refetchUserRoleInCommunity();
    mutateCommunityData();

    if (postUsedToSignup?.isStaticPost) {
      router.push({
        pathname: getMemberCommunityPageRoute(
          communityPublicPageData.slug
        ),
        query: {
          showSignupSuccessPrompt: 1
        }
      });
      return;
    }

    // Bring up the post via focusPostId as a query param
    // After routing to /home, the post will be shown
    router.push({
      pathname: `${communityLink}/home`,
      query: {
        focusPostId: postUsedToSignup?._id,
        showSignupSuccessPrompt: 1
      }
    });
  };

  const goToPostsTab = () => {
    router.push({
      pathname: getCommunityPageTabRoute({
        communitySlug: communityLink,
        tabId: TABS_ID.FEED
      })
    });
  };

  // Must wait for userCommunities to be fetched, so that the states do not flicker
  // between locked and unlocked post view
  const hasPostAndUserRolesLoaded =
    loadingPosts || !isUserCommunityRoleFetched;

  // To ensure that it does not overlap with profile completion modal
  const showJoinAsAffiliateModalExtra = isCommunityMember
    ? showJoinAsAffiliateModal && getIsUserProfileComplete(user)
    : showJoinAsAffiliateModal;

  const showYouAreAnAffiliateCard =
    isCommunityAffiliate && isAffiliateProduct;

  const hideMemberDetails = () => setSelectedMemberDetails(null);

  useEffect(() => {
    if (communityId) {
      fetchPosts({ postedBy: 'CREATOR' });
    }
  }, [communityId]);

  useEffect(() => {
    if (currentRouterQuery?.focusPostId && currentPosts) {
      if (currentRouterQuery?.showSignupSuccessPrompt) {
        setTimeout(() => {
          // only show post after succesScreen is gone
          setSelectedPost(
            currentPosts.find(
              (post) => post._id === currentRouterQuery?.focusPostId
            )
          );
          // Remove queries from the URL
          const newQuery = { ...currentRouterQuery };
          delete newQuery.focusPostId;
          delete newQuery.showSignupSuccessPrompt;

          router.replace({
            pathname: router.pathname,
            query: newQuery
          });
        }, 3500);
      }
    }
  }, [currentRouterQuery?.focusPostId, currentPosts]);

  return (
    <div>
      <div className="c-PublicPageHomeTab flex w-full flex-col gap-32 rounded-b-12 py-32">
        {/* About Section */}
        {!isLoggedInVariant && communityPublicPageData?.description && (
          <>
            <div className="px-24">
              <div>
                <AboutCommunity
                  communityPublicPageData={communityPublicPageData}
                />
              </div>
            </div>
            {/* Divider */}
            <Divider />
          </>
        )}

        {/* Dismissable WA Chat Card */}
        <JoinWhatsappChatCard
          isCommunityMember={isCommunityMember}
          isWhatsappExperienceCommunity={
            communityPublicPageData?.isWhatsappExperienceCommunity
          }
          waGroupChatLink={platformLink}
          communityId={communityPublicPageData?._id}
        />

        {isFetchingEntities && (
          <div className="px-16 md:px-24">
            <div className="mb-16 h-40 w-240 animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
            <div className="flex gap-16">
              <EntityCardSkeletion />
              <EntityCardSkeletion />
            </div>
          </div>
        )}

        {/* Ongoing Section */}
        {ongoingEntities?.length > 0 && (
          <>
            <div className="ml-16 md:ml-0 md:px-24">
              <CustomCarousel
                title={t('ongoing')}
                showArrowButtons={false}
                showTopRightArrowButtons={
                  isGtEqMd && ongoingEntities?.length > 2
                }
                showDotButtons={ongoingEntities?.length > 2}
                customOptions={{
                  loop: false,
                  align: 'start',
                  slidesToScroll: isGtEqMd ? 2 : 1
                }}>
                {ongoingEntities.map((ongoingEntity, index) => (
                  <div
                    className="mb-16 flex w-[320px] flex-shrink-0 pr-16 md:w-[51%]"
                    key={index}>
                    <EntityCard
                      entity={ongoingEntity}
                      communityLink={communityLink}
                      genericTrackingFields={genericTrackingFields}
                      communityConfig={communityPublicPageData?.config}
                      entityStatus={ENTITY_STATUS.ONGOING}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </>
        )}

        {/* Upcoming Section */}
        {upcomingEntities?.length > 0 && (
          <>
            <div className="ml-16 md:ml-0 md:px-24">
              <CustomCarousel
                title={t('upcoming')}
                showArrowButtons={false}
                showTopRightArrowButtons={
                  isGtEqMd && upcomingEntities?.length > 2
                }
                showDotButtons={upcomingEntities?.length > 2}
                customOptions={{
                  loop: false,
                  align: 'start',
                  slidesToScroll: isGtEqMd ? 2 : 1
                }}>
                {upcomingEntities.map((upcomingEntity, index) => (
                  <div
                    className="flex w-[320px] flex-shrink-0 pr-16 md:w-[51%] mb-16"
                    key={index}>
                    <EntityCard
                      entity={upcomingEntity}
                      communityLink={communityLink}
                      genericTrackingFields={genericTrackingFields}
                      communityConfig={communityPublicPageData?.config}
                      entityStatus={ENTITY_STATUS.ONGOING}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </>
        )}
        {/* Divider */}
        {(ongoingEntities?.length > 0 || upcomingEntities?.length > 0) && (
          <Divider />
        )}

        {/* Posts / Feed */}
        {hasPostAndUserRolesLoaded ? (
          <div className="mt-64 px-16 md:px-24">
            <FeedSkeleton />
          </div>
        ) : (
          currentPosts.length > 0 && (
            <div className="px-16 md:px-24">
              <div
                className="mb-16 text-heading-md font-semibold gap-8 flex flex-row items-center cursor-pointer"
                tabIndex={0}
                role="button"
                onClick={() => isCommunityMember && goToPostsTab()}>
                {t('latest-posts')}
                {isCommunityMember && (
                  <Icon
                    name="chevron-right"
                    fill="#1B1B1859"
                    width={20}
                    height={20}
                  />
                )}
              </div>
              <div className="flex flex-col gap-16">
                {currentPosts.map((post) => (
                  <MemberCard
                    key={post._id}
                    announcement={post}
                    communityLink={communityLink}
                    communityData={communityPublicPageData}
                    communityName={communityPublicPageData?.title}
                    communityId={communityPublicPageData?._id}
                    isLocked={!isCommunityMember}
                    onLockButtonClick={() => {
                      if (isCommunityMember) {
                        goToPostsTab();
                        trackCommPublicPagePostSeeMoreClick();
                      } else {
                        onUnlockPostClick(currentPosts[0]);
                      }
                    }}
                    showPreview={setSelectedPost}
                    goToSinglePostPage={setSelectedPost}
                  />
                ))}
                {/* See More Cta */}
                {showSeeMoreCta && !loadingMorePosts && (
                  <div className="flex justify-center mt-20">
                    <NPLButton
                      onClick={() => {
                        if (isCommunityMember) {
                          goToPostsTab();
                          trackCommPublicPagePostSeeMoreClick();
                        } else {
                          onUnlockPostClick(currentPosts[0]);
                        }
                      }}
                      hierarchy="outline"
                      buttonText={t('see-more-posts')}
                      size="lg"
                      stretch
                    />
                  </div>
                )}
                {loadingMorePosts && <FeedSkeleton />}
                {selectedPost && (
                  <AnnouncementPreviewModal
                    closeModal={() => setSelectedPost(null)}
                    showMenu={false}
                    community={{
                      ...communityPublicPageData,
                      link: communityLink
                    }}
                    onLockButtonClick={() =>
                      onUnlockPostClick(selectedPost)
                    }
                    isCommunityMember={isCommunityMember}
                    isAdmin={isCommunityAdmin}
                    communityLink={communityLink}
                    post={selectedPost}
                    readyOnly={!isCommunityMember && !isCommunityAdmin}
                    showPreviewModal={!!selectedPost}
                    user={user}
                    onSelectedMemberDetails={setSelectedMemberDetails}
                  />
                )}
              </div>
            </div>
          )
        )}

        {showJoinAsAffiliateCard && (
          <>
            <Divider />
            <div className="affiliate-card-section mx-24 md:max-w-[688px]">
              <BecomeAnAffiliateCard
                isCommunityMember={isCommunityMember}
                onClick={onOpenJoinAsAffiliateModal}
                communityId={communityId}
                entityId={communityId}
                entityType="SUBSCRIPTION"
                affiliateCode={affiliateCode}
                isSubscriptionEarningMode={isAffiliateProduct}
              />
            </div>
          </>
        )}

        {showYouAreAnAffiliateCard && (
          <div className="affiliate-card-section mx-24 md:max-w-[688px]">
            <YoureAnAffiliateOfTheProductCard />
          </div>
        )}

        {selectedMemberDetails && (
          <Modal
            customContainerClass="!max-w-[512px]"
            open={selectedMemberDetails}
            showCloseIcon={true}
            customCloseIconClass="!fixed !bg-npl-neutral-light-solid-1 !bg-opacity-50"
            withOutsideCloseIcon={true}
            onClose={hideMemberDetails}>
            {selectedMemberDetails?.firstName && (
              <PreviewProfile
                userData={{
                  fullName: `${selectedMemberDetails?.firstName} ${selectedMemberDetails?.lastName}`,
                  ...selectedMemberDetails
                }}
                justViewingProfile={true}
              />
            )}
          </Modal>
        )}

        {hasScrolledPastBanner && (
          <MobileCTAFooter
            priceTagText={priceTagText}
            setIsSignUpModalOpened={setIsSignUpModalOpened}
            discountPercentageForHighestInterval={
              discountPercentageForHighestInterval
            }
            isPendingApproval={isPendingApproval}
          />
        )}
        {/* Modals */}
        {/* On Signup via locked post */}
        {showPostSignupModal && (
          <CommunitySignupModal
            open={showPostSignupModal}
            onClose={closePostSignupModal}
            communityInfo={{
              ...communityPublicPageData,
              pricingData
            }}
            priceTagText={priceTagText}
            discountCodeFromQueryParams={discountCodeFromQueryParams}
            signUpOriginProps={{
              signUpOrigin: SIGNUP_ORIGIN.POST_PAGE,
              postInfo: postUsedToSignup
            }}
            newMemberSignupCallback={() => onSignViaPostSuccess(true)}
            existingMemberLoginCallback={onSignViaPostSuccess}
          />
        )}

        {showJoinAsAffiliateModalExtra && (
          <BecomeAnAffiliateModal
            onClose={onCloseJoinAsAffiliateModal}
            isLoggedIn={isLoggedIn}
            userProfileImgSrc={user?.learner?.profileImage}
            communityId={communityId}
            communityTitle={communityPublicPageData?.title}
            communityProfileImgSrc={communityPublicPageData?.profileImage}
            isCommunityMember={isCommunityMember}
            onJoinCommunity={() =>
              onJoinCommunity({
                isForAffiliateSignup: true
              })
            }
            onJoinAsAffiliate={onJoinAsAffiliate}
            isSubmittingJoinAsAffiliate={isSubmittingJoinAsAffiliate}
          />
        )}
      </div>
    </div>
  );
}

export default HomeTab;
