import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import NPLModalNew from '@/components/npl/NPLModalNew';

import useQueryParams from '@/hooks/useQueryParams';

import { PendingApplicationApprovalModal } from '@/pages/checkout-global/components/PendingApplicationApprovalModal/PendingApplicationApprovalModal';

const WithPendingCommMembershipApprovalModal = () => {
  const [showPendingApprovalModal, setShowPendingApprovalModal] =
    useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const { currentRouterQuery } = useQueryParams();
  const paymentMade = currentRouterQuery?.paymentMade === '1';

  const handleApplicationPendingModalClose = () => {
    setShowPendingApprovalModal(false);

    // Remove showMembershipPendingApproval and paymentMade from the URL
    const newQuery = { ...currentRouterQuery };
    delete newQuery.showMembershipPendingApproval;
    if (newQuery.paymentMade) delete newQuery.paymentMade;
    router.replace({
      pathname: pathname,
      query: newQuery
    });
  };

  useEffect(() => {
    const showMembershipPendingApprovalQuery =
      currentRouterQuery?.showMembershipPendingApproval === '1';

    if (showMembershipPendingApprovalQuery) {
      setShowPendingApprovalModal(true);
    }
  }, [currentRouterQuery?.showMembershipPendingApproval]);

  return showPendingApprovalModal ? (
    <NPLModalNew onClose={handleApplicationPendingModalClose}>
      {(onModalClose) => (
        <NPLModalNew.Container size={480}>
          <NPLModalNew.Content>
            <PendingApplicationApprovalModal
              withRefundNote={paymentMade} // if payment is made, then need to show that user will be refunded on rejection
              onClose={onModalClose}
            />
          </NPLModalNew.Content>
        </NPLModalNew.Container>
      )}
    </NPLModalNew>
  ) : null;
};

export default WithPendingCommMembershipApprovalModal;
