import { useLayoutEffect } from 'react';

const useRemoveScrollDuringOverlay = (isOpen: boolean) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow =
        originalStyle === 'hidden' ? 'initial' : originalStyle;
    };
  }, [isOpen]);
};

export default useRemoveScrollDuringOverlay;
