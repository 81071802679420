import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { t } from '@/utility/localization';

export const platformTypes = {
  discord: 'discord',
  facebook: 'facebook',
  whatsapp: 'whatsapp',
  telegram: 'telegram',
  slack: 'slack',
  linkedin: 'linkedin'
};

// session Storage keys
export const DISCORD_UUID_KEY = 'discordUuid';
export const SEEN_SIGN_UP_MODAL = 'initialSignupPopupSeen';

export const defaultBannerImg = `${STATIC_ASSET_BASE_URL}/na-website/community-product-page/nas-content-community/community-hero-banner-img.png`;

export const getDescriptionEmptyCardProps = () => ({
  title: t('add-a-description'),
  subtitle: t(
    'write-a-short-and-sweet-description-about-your-community-in-3-4-sentences-it-should-tell-your-community-members-about-your-community-and-what-it-offers-try-to-include-information-like-what-is-the-theme-of-your-community-what-are-your-goals-for-your-community-who-is-this-community-for-does-it-include-any-extras-like-courses-resources-live-events-or-videos'
  ),
  ctaText: t('add-description')
});
