import classNames from 'classnames';
import React, { FC } from 'react';

import Icon from '@/components/npl/Icon';

import type { Option } from './NplPopoverMenu';

type Props = {
  option: Option;
  isDangerClassName?: string;
  isSelected?: boolean;
  onClose?: () => void;
  onOptionClick?: (option: Option) => void;
};

const NPLPopOverOption: FC<Props> = ({
  option,
  onClose,
  onOptionClick,
  isDangerClassName = '',
  isSelected = false
}) => {
  return (
    <div key={option.id}>
      <button
        className={classNames(
          'flex justify-between w-full transition-all rounded-8 bg-white-default px-8 py-10 hover:bg-npl-neutral-light-solid-3',
          { 'items-center': !option?.description }
        )}
        onClick={(e) => {
          e.stopPropagation();
          option?.onClick ? option?.onClick() : onOptionClick(option);
          onClose?.();
        }}>
        <div className="flex gap-12">
          <div className="flex gap-4 items-center">
            {option.icon && (
              <Icon
                name={option.icon}
                width={16}
                height={16}
                fill={option?.isDanger ? '#E5484D' : '#1B1B18A6'}
              />
            )}
            {option.index && (
              <span className={`text-left text-label-md`}>
                {option?.index}
              </span>
            )}
          </div>
          <div className="flex flex-col ">
            <span
              className={`text-left text-label-md line-clamp-2 ${
                option?.isDanger
                  ? isDangerClassName
                  : 'text-npl-text-icon-on-light-surface-primary'
              }`}>
              {option?.title}
            </span>
            <span className="text-body-sm text-npl-text-icon-on-light-surface-secondary">
              {option?.description}
            </span>
          </div>
        </div>

        <div className="self-end">
          {isSelected && (
            <Icon
              className="fill-npl-text-tertiary-on-light"
              width={16}
              name="check"
            />
          )}
        </div>
      </button>
    </div>
  );
};

export default NPLPopOverOption;
