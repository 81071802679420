import { t } from '@/utility/localization';

import { SIGNUP_ORIGIN } from '@/features/SignUpPopup/constants';

import { MentionedCardEntityType } from '@/components/common/MentionedCard/types';
import { IconName } from '@/components/npl/Icon';

export const getSizeOptions = () => [
  {
    id: 'sm',
    title: t('small')
  },
  {
    id: 'lg',
    title: t('large')
  }
];

export const getLinkTypeOptions: () => Array<{
  icon: IconName;
  id: string;
  title: string;
}> = () => [
  {
    id: 'landingPage',
    title: t('product-page'),
    icon: 'layout-top'
  },
  {
    id: 'checkout',
    title: t('checkout-page'),
    icon: 'credit-card-02'
  }
];

export const entitySourceMap: {
  [K in Exclude<MentionedCardEntityType, 'SUBSCRIPTION'>]: string;
} = {
  EVENT: SIGNUP_ORIGIN.EVENT_PAGE,
  FOLDER: SIGNUP_ORIGIN.RESOURCE_PAGE,
  SESSION: SIGNUP_ORIGIN.ONE_ON_ONE_SESSION_PAGE,
  CHALLENGE: SIGNUP_ORIGIN.CHALLENGE_PAGE
};
