import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import { trackGAEvent } from '@/utility/analytics';
import {
  MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK,
  MEMBER_NAVBAR_LOGIN_CLICK
} from '@/utility/analyticsConsts';
import { ENTITY_SLUGS_PREFIX } from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  LOGIN_ROUTE,
  redirectSafelyOnAllBrowsers
} from '@/utility/routesHelper';
import sessionStorageService from '@/utility/sessionStorageService';

import { DISCORD_UUID_KEY } from '@/features/community/constants';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import useAnimateOpacityByScrolling from '@/hooks/useAnimateOpacityByScrolling';
import useQueryParams from '@/hooks/useQueryParams';

const GenericNavBar = ({
  communityInfo,
  hasScrolledPastBanner = true,
  openSignUpModal,
  isCommunityPageView = false,
  transition,
  showEntityCTAButton
}) => {
  const router = useRouter();
  const { isGtEqMd } = useWindowWidthContext();
  const pathname = usePathname();
  const { currentRouterQuery } = useQueryParams();
  const navbarRef = useRef(null);
  const currentRoute = pathname;
  const splittedRoute = currentRoute.split('/');

  const isNotOnHomePageAndEntityPage = splittedRoute.length === 3;
  const isOnEntityPage = splittedRoute.length === 4;

  const routerAsPath = pathname;

  const communityLink = communityInfo?.slug ?? communityInfo?.link;

  const navigateToCommunityHomePage = () => router.push(communityLink);

  useAnimateOpacityByScrolling(transition, navbarRef);

  const renderLeftSection = () => {
    // Base logo component that navigates to home
    const LogoIcon = () => (
      <div className="cursor-pointer" onClick={() => router.push('/')}>
        <Icon
          path="logo"
          name="nas-io-logo-grayscale"
          width={28}
          height={28}
          fillOpacity={0.5}
        />
      </div>
    );

    // Only show expanded nav section if scrolled past banner or not on home/entity page
    if (!hasScrolledPastBanner && !isNotOnHomePageAndEntityPage) {
      return <LogoIcon />;
    }

    return (
      <div className="flex md:animate-fadeInLibrary items-center gap-8 p-4">
        {/* Back button */}
        {isNotOnHomePageAndEntityPage && isCommunityPageView && (
          <div
            onClick={navigateToCommunityHomePage}
            className="hover:cursor-pointer">
            <Icon name="chevron-left" width={16} height={16} />
          </div>
        )}

        {/* Community profile or logo */}
        {isCommunityPageView ? (
          <div
            className="flex flex-row items-center gap-2.5 rounded-8 pr-8 transition-all cursor-pointer hover:bg-npl-neutral-light-alpha-4"
            onClick={() => {
              if (!isCommunityPageView) {
                navigateToCommunityHomePage();
                return;
              }
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            <div className="h-32">
              <NextImage
                alt={`${communityInfo?.title} profile`}
                mobileImgProps={{
                  src: communityInfo?.profileImage,
                  width: 32,
                  height: 32,
                  objectFit: 'cover'
                }}
                className="aspect-square h-32 w-32 rounded-8 object-cover"
              />
            </div>
            <span className="w-full font-semibold lg:text-label-lg hidden md:block">
              {communityInfo?.title}
            </span>
          </div>
        ) : (
          <LogoIcon />
        )}
      </div>
    );
  };

  const getEntityCTAText = () => {
    const entityType = splittedRoute[2];
    switch (entityType) {
      case ENTITY_SLUGS_PREFIX.CHALLENGES:
        return t('join-challenge');
      case ENTITY_SLUGS_PREFIX.EVENTS:
        return t('register');
      default:
        return t('join');
    }
  };

  useEffect(() => {
    // store the uuid in the session storage if it exists
    if (currentRouterQuery?.uuid) {
      sessionStorageService.setItem(
        DISCORD_UUID_KEY,
        currentRouterQuery?.uuid
      );
    }
  }, [currentRouterQuery?.uuid]);

  return (
    <div
      className={classNames(
        `c-GenericNavbar top-0 z-over-intercom flex h-56 w-full items-center pl-12 pr-20 sticky bg-white-default`
      )}
      ref={navbarRef}>
      <div className="flex-grow">
        <div className="flex items-center justify-between">
          {renderLeftSection()}
          <div className="flex items-center gap-8">
            {isNotOnHomePageAndEntityPage && (
              <NPLButton
                hierarchy="accent_primary"
                buttonText={isGtEqMd ? t('join-community') : t('join')}
                size="sm"
                onClick={() => {
                  openSignUpModal();
                  trackGAEvent(MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK, {
                    communityId: communityInfo?._id,
                    communityCode: communityInfo?.code
                  });
                }}
              />
            )}

            {isOnEntityPage && showEntityCTAButton && (
              <NPLButton
                hierarchy="accent_primary"
                buttonText={getEntityCTAText()}
                size="sm"
                onClick={() => {
                  openSignUpModal();
                  trackGAEvent(MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK, {
                    communityId: communityInfo?._id,
                    communityCode: communityInfo?.code
                  });
                }}
              />
            )}

            {(isGtEqMd || !isNotOnHomePageAndEntityPage) &&
              !showEntityCTAButton && (
                <NPLButton
                  hierarchy="outline"
                  buttonText={t('log-in')}
                  size="sm"
                  multiply
                  onClick={() => {
                    trackGAEvent(MEMBER_NAVBAR_LOGIN_CLICK, {
                      communityId: communityInfo?._id,
                      communityCode: communityInfo?.code
                    });
                    redirectSafelyOnAllBrowsers(
                      `${LOGIN_ROUTE}?redirectTo=${routerAsPath}`
                    );
                  }}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericNavBar;
