import classNames from 'classnames';
import { FC } from 'react';

import { LeadSectionProps } from '../types';

const NPLSearchDropdownLead: FC<LeadSectionProps> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={classNames(
        'c-NPLSearchDropdown-lead pr-8 py-12 text-label-lg text-npl-text-secondary-on-light ',
        className
      )}>
      {children}
    </div>
  );
};

export default NPLSearchDropdownLead;
