import { t } from '@/utility/localization';

export const VariableTypes = {
  CommunityName: '{community_name}',
  MemberName: '{name}',
  EventName: '{event_name}',
  CommunityManagerName: '{community_manager_name}'
};

export const replaceVariables = (content, variables) => {
  if (!variables) {
    return content;
  }

  let updatedContent = content;
  variables.forEach((item) => {
    if (updatedContent?.includes(item.variable)) {
      updatedContent = updatedContent?.replaceAll(
        item.variable,
        item.text
      );
    }
  });

  return updatedContent;
};

export const getRichTextVariables = () => ({
  community: {
    text: t('community-name'),
    variable: VariableTypes.CommunityName
  },
  member: {
    text: t('member-name-0'),
    variable: VariableTypes.MemberName
  },
  event: {
    text: t('event-name'),
    variable: VariableTypes.EventName
  }
});

export const convertToTipTapJSON = (string, variables) => {
  const JSONObject = {
    type: 'oneLiner',
    content: [
      {
        type: 'paragraph',
        // updated by this function
        content: []
      }
    ]
  };
  const strArr = string.split(' ');
  // if last element is '', change it to ' '
  if (strArr[strArr.length - 1] === '') {
    strArr[strArr.length - 1] = ' ';
  }
  // see which words are variables
  const variableIndices = [];
  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i].startsWith('{') && strArr[i].endsWith('}')) {
      if (variables.some((variable) => variable.variable === strArr[i])) {
        variableIndices.push(i);
      }
    }
  }

  // populate the JSON object
  for (let i = 0; i < strArr.length; i++) {
    // get text of variable
    let text = '';
    for (let key in variables) {
      if (variables[key].variable === strArr[i]) {
        text = variables[key].text;
      }
    }
    if (variableIndices.includes(i)) {
      JSONObject.content[0].content.push({
        type: 'mention',
        attrs: {
          id: { text: text, variable: strArr[i] },
          label: null
        }
      });
    } else {
      // check if there is word before this current word, if so add space at front of string
      if (i !== 0 || variableIndices.includes(i - 1)) {
        strArr[i] = ' ' + strArr[i];
      }
      // check if there is a variable after this current word, if so add space at end of string
      if (variableIndices.includes(i + 1)) {
        strArr[i] = strArr[i] + ' ';
      }
      JSONObject.content[0].content.push({
        type: 'text',
        text: strArr[i]
      });
    }
  }

  return JSONObject;
};

export const checkIfUrlIsValid = (url) => {
  const urlRegExp = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
  );
  return urlRegExp.test(url);
};
