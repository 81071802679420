import config from '@/utility/config';

import { getAxiosError, getAxiosErrorMsg } from '../helpers';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import { convertObjectToQueryString } from '../helpers/queryString';

const { communityApiBasePath } = config;

export const fetchSingleMessage = async (payload) => {
  const { communityId, messageId } = payload;
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${messageId}`;
  const res = await adminProtectedAxiosRequest.get(path);

  if (res.error) {
    return { error: res.error, data: null };
  }

  return { data: res.data.data, error: null };
};

export const fetchBucketMetadataByAdmin = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/fetch-bucket-meta-data`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const fetchAllDraftsByAdmin = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/communicate/get-drafts`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const fetchAllSentByAdmin = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/communicate/get-sent-emails`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const createMessageByAdmin = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message`;
    const res = await adminProtectedAxiosRequest.post(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getMentionedProducts = async (communityId, params) => {
  let updatedParams = {
    pageSize: 20,
    search: '',
    ...params
  };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/products`;
  const { data, error } = await adminProtectedAxiosRequest.get(path, {
    params: updatedParams
  });
  return { data, error };
};

export const getAnalyticsProductLinks = async (
  communityId,
  messageId,
  params
) => {
  let updatedParams = {
    pageNo: 1,
    pageSize: 20,
    ...params
  };
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${messageId}/links`;
  const { data, error } = await adminProtectedAxiosRequest.get(path, {
    params: updatedParams
  });
  return { data, error };
};

export const updateMessageByAdmin = async (payload, id) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${id}`;
    const res = await adminProtectedAxiosRequest.put(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getMessageByAdmin = async (communityId, draftId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${draftId}`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const searchRecipientsByAdmin = async ({
  communityId,
  pageNo,
  pageSize,
  bucketName,
  searchString
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/search-recipients`;
    const res = await adminProtectedAxiosRequest.get(path, {
      params: { pageNo, pageSize, bucketName, searchString }
    });
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getOptOutCounts = async ({ communityId }) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/count-opt-outs`;
    const res = await adminProtectedAxiosRequest.get(path, {
      params: { communityId }
    });
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getRecipientsByMessageId = async ({
  communityId,
  messageId,
  pageNo,
  pageSize,
  platform,
  clickedEmailStat = null,
  hasPaid = false,
  linkObjectId = null,
  messageType = null
}) => {
  const params = { pageNo, pageSize, platform };
  if (messageType) {
    params.messageType = messageType;
  }

  if (clickedEmailStat) {
    params[clickedEmailStat] = true;
  }

  if (hasPaid) {
    params.hasPaid = hasPaid;
  }

  if (linkObjectId) {
    params.linkObjectId = linkObjectId;
  }

  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${messageId}/recipients`;
    const res = await adminProtectedAxiosRequest.get(path, {
      params
    });
    return { data: res.data?.data, error: null };
  } catch (error) {
    return { error: getAxiosErrorMsg(error) };
  }
};

export const getBucketDataByAdmin = async ({ communityId, eventId }) => {
  try {
    const qs = eventId
      ? '?' + convertObjectToQueryString({ eventObjectId: eventId })
      : '';

    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/buckets${qs}`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const sendTestMessageByAdmin = async (payload) => {
  const { communityId, draftId } = payload;
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${draftId}/sendTest`;
  const { data, error } = await adminProtectedAxiosRequest.post(
    path,
    payload
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const sendMessageByAdmin = async (payload) => {
  try {
    const { communityId, draftId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${draftId}/send`;
    const res = await adminProtectedAxiosRequest.post(path, payload);

    if (res.error) {
      return { error: res.error, data: null };
    }

    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const countMessageRecipientsByAdmin = async ({
  communityId,
  selectionObject
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/count-recipients`;
    const res = await adminProtectedAxiosRequest.post(
      path,
      selectionObject
    );
    return { data: res.data, error: null };
  } catch (error) {
    return { error: getAxiosError(error).error };
  }
};

export const fetchOptedOutMembers = async (communityId, params) => {
  let queryString = new URLSearchParams(params).toString();

  if (queryString) {
    queryString = `?${queryString}`;
  }

  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/opt-outs${queryString}`;
  const response = await adminProtectedAxiosRequest.get(path);
  return { data: response.data, error: response.error };
};

export const scheduleMagicReachMessage = async (payload) => {
  const { communityId, draftId } = payload;

  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${draftId}/schedule`;

  const { error } = await adminProtectedAxiosRequest.put(path, payload);

  if (error) {
    return { error: getAxiosErrorMsg(error) };
  }

  return { error: null };
};

export const deleteMagicReachMessage = async (communityId, messageId) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${messageId}`;

  const { error } = await adminProtectedAxiosRequest.delete(path);

  if (error) {
    return { error: getAxiosErrorMsg(error) };
  }

  return { error: null };
};

export const removeScheduleFromMessage = async (
  communityId,
  messageId
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/message/${messageId}/schedule`;

  const { error } = await adminProtectedAxiosRequest.delete(path);

  if (error) {
    return { error: getAxiosErrorMsg(error) };
  }

  return { error: null };
};
