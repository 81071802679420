import config from './config';

export const UPCOMING_EVENTS_TAB = 'upcomingEvents';
export const PAST_EVENTS_TAB = 'pastEvents';
export const ONGOING_EVENTS_TAB = 'ongoing';

export const [EVENT_TYPE_LIVE, EVENT_TYPE_IN_PERSON] = [
  'live',
  'inPerson'
];

export const COUNTRY_DATA_SESSION = 'country_data_session';
export const STATIC_ASSET_BASE_URL = config.imagesBasePath;
export const NA_PRIMARY_COLOR = '#fbc91b';
export const DARK_BUTTON_TEXT_COLOR = '#000000';
export const DOCUMENT_TITLE_TEMPLATE = 'Nas Academy - We create Creators';
export const NA_CONTACT_EMAIL = 'hello@nas.io';
export const TRACK_CLICK_ID_KEY = 'clickid';
export const TRACK_SOURCE_KEY = 'source';
export const TRACK_MEDIUM_KEY = 'medium';
export const TRACK_CAMPAIGN_KEY = 'campaign';
export const TRACK_CAMPAIGN_ID_KEY = 'campaignid';
export const TRACK_CREATIVE_ID_KEY = 'creativeid';
export const TRACK_AD_GROUP_ID_KEY = 'adgroupid';
export const TRACK_AD_GROUP_NAME_KEY = 'adgroupname';
export const TRACK_AD_ID_KEY = 'adid';
export const TRACK_AD_NAME_KEY = 'adname';
export const TRACK_TWITTER_CLICK_ID_KEY = 'twclid';
export const IS_DEV_ENV = process?.env?.NODE_ENV === 'development';

export const routeListShowingChat = [
  '/about-us',
  '/subscribe',
  '/faq',
  '/privacy',
  '/enrollment-terms',
  '/creators',
  '/impact',
  '/trainers',
  '/business',
  '/how-it-works'
];

export const newFooterIcons = {
  facebook: 'facebook-neutral',
  instagram: 'instagram-neutral',
  twitter: 'twitter-neutral',
  youtube: 'youtube-neutral',
  linkedin: 'linkedin-neutral',
  tiktok: 'tiktok-neutral'
};

export const roles = {
  admin: 'admin',
  member: 'member',
  viewer: 'viewer',
  manager: 'manager'
};

export const platformTypes = {
  discord: 'discord',
  facebook: 'facebook',
  whatsapp: 'whatsapp',
  telegram: 'telegram',
  slack: 'slack',
  linkedin: 'linkedin'
};

export const S3_BUCKET = 'image-assets.nasdaily.com';

export const ALL = 'all';
export const NEAR_YOU = 'near-you';
export const NEWEST = 'newest';
export const NAS_ACADEMY = 'nas-academy';

export const memberTags = [ALL, NEAR_YOU, NEWEST];

export const NEWEST_DATA_SORTING_PARAM = 'desc';
export const OLDEST_DATA_SORTING_PARAM = 'asc';
export const ALL_MEMBERSHIPS_FILTER = 'all';
export const NAS_ACADEMY_MEMBERSHIPS_FILTER = 'nas';
export const NEAR_YOU_MEMBERSHIPS_FILTER = 'country';

export const membershipsParamsMapping = {
  [ALL]: {
    filter: ALL_MEMBERSHIPS_FILTER,
    sort: OLDEST_DATA_SORTING_PARAM
  },
  [NEAR_YOU]: {
    filter: NEAR_YOU_MEMBERSHIPS_FILTER,
    sort: OLDEST_DATA_SORTING_PARAM
  },
  [NEWEST]: { filter: '', sort: NEWEST_DATA_SORTING_PARAM },
  [NAS_ACADEMY]: {
    filter: NAS_ACADEMY_MEMBERSHIPS_FILTER,
    sort: OLDEST_DATA_SORTING_PARAM
  }
};

export const COMMUNITY_ID_KEY = 'activeCommunityId';

export const ImageUseCase = {
  MagicReach: 'magicReach',
  CustomWelcomeMail: 'customWelcomeEmail',
  CustomEventEmail: 'customEventEmail',
  Announcement: 'announcement',
  Challenge: 'challenge',
  Community: 'community',
  OneOnOneSession: 'oneOnOneSession',
  Product: 'product',
  Event: 'event',
  User: 'user',
  ChallengeSubmission: 'challengeSubmission'
};

export const EVENT_ACTION_TYPE = 'event';
export const POST_ACTION_TYPE = 'post';

export const PUBLIC_PAGE_TEXT_FIELDS = {
  title: 'title',
  description: 'description',
  shortURL: 'shortURL',
  byCommunity: 'byCommunity'
};
export const MAX_DESCRIPTION_CHARACTERS = 5000;

export const NASIO_OG_META_IMAGE_SRC =
  'https://framerusercontent.com/assets/p0dspbXwMc5AsNwHNf549nyE.png';
