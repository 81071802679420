import classNames from 'classnames';
import { FC } from 'react';

import { TailSectionProps } from '../types';

const NPLSearchDropdownTail: FC<TailSectionProps> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={classNames(
        'c-NPLSearchDropdown-tail pl-8 py-12 text-body-xs text-npl-text-secondary-on-light',
        className
      )}>
      {children}
    </div>
  );
};

export default NPLSearchDropdownTail;
