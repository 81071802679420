import classNames from 'classnames';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import config from '@/utility/config';
import { getDescriptionFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Editor from '@/components/common/Editor/Editor';
import { MentionedEntityDataType } from '@/components/common/Editor/nodes/MentionedProductNode/types';
import NextImage from '@/components/common/NextImage';

import { replaceVariables } from '@/pages/portal/utils/common';

import BlurImageWithLock from '../../components/BlurImageWithLock';
import { useImageLoader } from '../../components/useImageLoader';

type Props = {
  content: SerializedEditorState<SerializedLexicalNode>;
  editorVariables: Array<{
    text: string;
    variable: string;
  }>;
  feedCoverImageUrl: string;
  isPendingApproval: boolean;
  isStaticPost: boolean;
  isUnlockedView: boolean;
  onLockButtonClick: () => void;
  communityData?: {
    _id: string;
    code: string;
    isWhatsappExperienceCommunity: boolean;
    link: string;
  };
  fullView?: boolean;
  isLocked?: boolean;
  mentionedProducts?: Array<{
    productInfo: MentionedEntityDataType;
    productObjectId: string;
    type: string;
  }>;
  previewContent?: boolean;
};

export default function FeedContent({
  content,
  mentionedProducts,
  isUnlockedView,
  feedCoverImageUrl,
  onLockButtonClick,
  editorVariables,
  communityData,
  fullView = false,
  isPendingApproval,
  previewContent = false,
  isLocked = false
}: Props) {
  const { isGtEqMd } = useWindowWidthContext();
  const { dimensions, isLoading } = useImageLoader(feedCoverImageUrl);
  const paragraphRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const firstParagraphInContent = useMemo(
    () => getDescriptionFromContent(content),
    [content]
  );

  useEffect(() => {
    const checkTextTruncation = () => {
      if (paragraphRef.current) {
        const { scrollHeight, clientHeight } = paragraphRef.current;
        setIsTextTruncated(scrollHeight > clientHeight);
      }
    };

    checkTextTruncation();
    window.addEventListener('resize', checkTextTruncation);

    return () => {
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [firstParagraphInContent, isGtEqMd]);

  const renderImage = () => {
    if (isLoading) {
      return (
        <div
          className={`animate-pulse rounded-12 bg-npl-neutral-light-solid-4`}
        />
      );
    }

    if (isUnlockedView) {
      if (feedCoverImageUrl?.includes(config.NEXT_PUBLIC_CLOUDFRONT_URL)) {
        return (
          <NextImage
            className="rounded-12"
            mobileImgProps={{
              src: feedCoverImageUrl,
              width: dimensions.width,
              height: dimensions.height,
              objectFit: 'cover',
              quality: 50
            }}
          />
        );
      }
      return (
        <img
          src={feedCoverImageUrl}
          width={dimensions.width}
          height={dimensions.height}
          alt="cover"
          className="rounded-12"
        />
      );
    }

    return (
      <BlurImageWithLock
        imageUrl={feedCoverImageUrl}
        onClick={onLockButtonClick}
        isPendingApproval={isPendingApproval}
      />
    );
  };

  return (
    <div>
      {fullView ? (
        <Editor
          existingContent={content}
          editable={false}
          placeholderText={t('loading')}
          isAutoFocus={false}
          imageIdentity={null}
          customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
          variables={editorVariables}
          mentionedProducts={mentionedProducts}
          isLiveDataApplicable
          communityData={{
            _id: communityData?._id,
            code: communityData?.code,
            isWhatsappExperienceCommunity:
              communityData?.isWhatsappExperienceCommunity,
            slug: communityData?.link
          }}
        />
      ) : (
        (feedCoverImageUrl || firstParagraphInContent) && (
          <div>
            {firstParagraphInContent && !isLocked && (
              <div
                ref={paragraphRef}
                className={classNames(
                  'relative text-para-sm whitespace-pre-line',
                  {
                    'line-clamp-2': previewContent || isGtEqMd,
                    'line-clamp-4': !previewContent && !isGtEqMd,
                    'text-npl-text-secondary-on-light': previewContent,
                    'text-npl-text-icon-on-light-surface-primary':
                      !previewContent
                  }
                )}>
                {firstParagraphInContent &&
                  replaceVariables(
                    firstParagraphInContent,
                    editorVariables
                  )}
                {isTextTruncated && !previewContent && (
                  <div
                    onClick={onLockButtonClick}
                    className="absolute bottom-0 right-0 z-[1] cursor-pointer bg-white-default px-4 font-semibold text-npl-text-icon-on-light-surface-tertiary group-hover:bg-npl-neutral-light-solid-2">
                    ...{t('see-more')}
                  </div>
                )}
              </div>
            )}

            {feedCoverImageUrl && !previewContent && (
              <div className="mt-12">{renderImage()}</div>
            )}
          </div>
        )
      )}
    </div>
  );
}
