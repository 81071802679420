import Link from 'next/link';

import {
  ENTITY_TYPE_SLUG,
  getEntityPublicPageUrl
} from '@/utility/routesHelper';

import { sanitizeMentionedProductData } from '@/components/common/Editor/nodes/MentionedProductNode/utils';
import MentionedCard from '@/components/common/MentionedCard';

export const EntityPostCard = ({ entity, communitySlug }) => {
  const { type, slug, resourceSlug, thumbnail, cover, bannerImg } =
    entity || {};

  const entityTypeSlug = ENTITY_TYPE_SLUG[type];
  const entityUrl = getEntityPublicPageUrl({
    communitySlug: communitySlug,
    entityType: entityTypeSlug,
    entitySlug: slug || resourceSlug
  });

  const mentionedCardEntity = sanitizeMentionedProductData(
    {
      ...entity,
      thumbnail: thumbnail || cover || bannerImg
    },
    false
  );

  return (
    <Link
      target="_blank"
      href={entityUrl}
      className="p-8 rounded-12 flex flex-row items-center gap-12 justify-between"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <MentionedCard entity={mentionedCardEntity} size="sm" />
    </Link>
  );
};
