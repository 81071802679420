import React from 'react';

import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import InputWithSuggestions from '@/components/common/Form/InputWithSuggestions';
import RadioItemCard from '@/components/common/Form/RadioItemCard';

/**
 * Represents a component for selecting a community category on nas.io.
 * @component
 * @param {Object} props - The component properties.
 * @param {Object} props.selectedCategory - The selected category object with properties:
 *   - id: {string} The ID of the selected category.
 *   - label: {string} The label or description of the selected category.
 * @param {Function} props.setSelectedCategory - The function to set the selected category.
 * @returns {JSX.Element} The JSX element for the community category selection component.
 */

export const COMMUNITY_CATEGORIES_ID = {
  FINANCE: 'FINANCE',
  PERSONAL_DEVELOPMENT: 'PERSONAL_DEVELOPMENT',
  FITNESS: 'FITNESS',
  NUTRITION: 'NUTRITION',
  SPIRITUALITY_RELIGION: 'SPIRITUALITY_RELIGION',
  OTHERS: 'OTHERS'
};

const SelectCommCategory = ({
  selectedCategory,
  setSelectedCategory,
  otherCommCategoryInput,
  setOtherCommunityCategoryInput
}) => {
  const { isGtEqLg } = useWindowWidthContext();

  const communityCategoryOptions = [
    { id: COMMUNITY_CATEGORIES_ID.FINANCE, label: `📈 ${t('finance1')}` },
    {
      id: COMMUNITY_CATEGORIES_ID.PERSONAL_DEVELOPMENT,
      label: `🚀 ${t('personal-and-career-development')}`
    },
    { id: COMMUNITY_CATEGORIES_ID.FITNESS, label: `💪 ${t('fitness1')}` },
    {
      id: COMMUNITY_CATEGORIES_ID.NUTRITION,
      label: `🌱 ${t('nutrition')}`
    },
    {
      id: COMMUNITY_CATEGORIES_ID.SPIRITUALITY_RELIGION,
      label: `🔮️ ${t('spirituality-and-religion')}`
    },
    { id: COMMUNITY_CATEGORIES_ID.OTHERS, label: `🎁 ${t('others')}` }
  ];

  const isOtherCategoryRadioSelected = selectedCategory?.id === 'OTHERS';

  const OTHER_CATEGORY_SUGGESTIONS = [
    t('advocacy-and-social-impact'),
    t('arts-and-crafts'),
    t('book-club'),
    t('brand-community'),
    t('business-and-entrepreneurship'),
    t('content-creation'),
    t('crypto-and-blockchain'),
    t('cultural'),
    t('digital-nomad'),
    t('entertainment-and-pop-culture'),
    t('environmental-sustainability'),
    t('experiences'),
    t('fan-club'),
    t('fashion-and-beauty'),
    t('home-and-garden'),
    t('hospitality'),
    t('inner-circle'),
    t('language-learning'),
    t('local-community'),
    t('marketing'),
    t('mastermind'),
    t('medicine'),
    t('organization'),
    t('parenting-and-family'),
    t('pets-and-animals'),
    t('philanthropy-and-charity'),
    t('philosophy-and-ethics'),
    t('physical-spaces'),
    t('podcasting'),
    t('professional-services'),
    t('real-estate'),
    t('sales-0'),
    t('science'),
    t('side-hustle-and-remote-work'),
    t('social-media-0'),
    t('sports'),
    t('start-ups'),
    t('tech-and-innovation'),
    t('tools'),
    t('travel-and-adventure'),
    t('vip-community')
  ];

  return (
    <div className="c-SelectCommCategory">
      <h3 className="text-heading-xs font-semibold text-dark-1b lg:text-heading-md">
        {t('pick-a-community-category')}
      </h3>
      <div className="mt-16 grid grid-cols-1 gap-8 lg:mt-24 lg:grid-cols-2">
        {communityCategoryOptions.map((option) => (
          <RadioItemCard
            key={option.label}
            option={option}
            selected={selectedCategory?.id === option.id}
            onSelect={setSelectedCategory}
            size={isGtEqLg ? 'lg' : 'md'}
          />
        ))}
      </div>
      {isOtherCategoryRadioSelected && (
        <div className="mt-24">
          <InputWithSuggestions
            suggestions={OTHER_CATEGORY_SUGGESTIONS}
            name="other-community-category"
            value={otherCommCategoryInput}
            onChange={setOtherCommunityCategoryInput}
            focusInputOnMount={true}
          />
        </div>
      )}
    </div>
  );
};

export default SelectCommCategory;
