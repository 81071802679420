import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { t } from '@/utility/localization';
import { MEMBER_AFFILIATE_PRODUCTS_ROUTE } from '@/utility/routesHelper';

import { registerMemberAsAffiliate } from '@/services/affiliatesService';

import { useAuthContext } from '@/contexts/AuthContext';

import { showErrorToast } from '@/components/common/ToastContainer';
import { showSuccessToast } from '@/components/common/ToastContainer/ToastContainer';

import useQueryParams from '@/hooks/useQueryParams';

import { KEY_SHOW_JOIN_AS_AFFILIATE_MODAL } from '../constants';

type PropsType = {
  communityId: string;
  communityTitle: string;
  isPendingApproval: boolean;
  isUserCommunityRoleFetched: boolean;
  affiliateInfo?: {
    communityHasActiveAffiliateProduct?: boolean;
    isAffiliateProduct?: boolean;
  };
  handleJoinAsAffiliateSuccessCb?: () => void;
};

const useLandingPageJoinAsAffiliateProgram = ({
  affiliateInfo,
  communityId,
  communityTitle,
  isUserCommunityRoleFetched,
  isPendingApproval,
  handleJoinAsAffiliateSuccessCb
}: PropsType) => {
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const {
    user,
    isLoading: isFetchingUser,
    refreshUserData
  } = useAuthContext();
  const queryShowJoinAsAffiliateModal =
    !!currentRouterQuery?.[KEY_SHOW_JOIN_AS_AFFILIATE_MODAL];

  const [showJoinAsAffiliateModal, setShowJoinAsAffiliateModal] =
    useState(false);
  const [showJoinAsAffiliateCard, setShowJoinAsAffiliateCard] =
    useState(false);

  const [isSubmittingJoinAsAffiliate, setIsSubmittingJoinAsAffiliate] =
    useState(false);
  const [joinAsAffiliateError, setJoinAsAffiliateError] = useState(null);

  const isCommunityAffiliate = useMemo(
    () => Boolean(user?.hasAffiliate),
    [user?.hasAffiliate]
  );

  // if user is logged in but doesn't have affilaite info
  const isUserDataMissingAffiliateInfo = useMemo(() => {
    return user && !Object.hasOwnProperty.call(user || {}, 'hasAffiliate');
  }, [user]);

  // True if current product in community is active for affiliate
  const isAffiliateProduct = Boolean(affiliateInfo?.isAffiliateProduct);

  // True if any product in community is active for affiliate
  const communityHasActiveAffiliateProducts = Boolean(
    affiliateInfo?.communityHasActiveAffiliateProduct
  );

  const onOpenJoinAsAffiliateModal = () => {
    setShowJoinAsAffiliateModal(true);
  };

  const onCloseJoinAsAffiliateModal = () => {
    setShowJoinAsAffiliateModal(false);
  };

  const onJoinAsAffiliate = async () => {
    setIsSubmittingJoinAsAffiliate(true);
    setJoinAsAffiliateError(null);

    const resp = await registerMemberAsAffiliate({
      communityId
    });

    if (resp.error) {
      showErrorToast(resp.error);

      setJoinAsAffiliateError(resp.error);
      setIsSubmittingJoinAsAffiliate(false);
      return resp;
    }

    handleJoinAsAffiliateSuccessCb?.();

    const successMsg = t('youre-now-an-affiliate-of-communitytitle', {
      communityTitle
    });
    showSuccessToast(successMsg);

    setIsSubmittingJoinAsAffiliate(false);
    onCloseJoinAsAffiliateModal();
    setShowJoinAsAffiliateCard(false);

    refreshUserData();

    // Go to user affiliate products page
    router.push(MEMBER_AFFILIATE_PRODUCTS_ROUTE);

    return resp;
  };

  const showBecomeAnAffiliateNavMenuItem = useMemo(() => {
    if (
      !isUserCommunityRoleFetched ||
      isPendingApproval ||
      isCommunityAffiliate ||
      isUserDataMissingAffiliateInfo || // Don't show if user is logged in but affiliate information is missing. (Can happen if activeCommunityId is not part of user-profile API)
      !communityHasActiveAffiliateProducts // Don't show if community doesn't have any active affiliate products.
    ) {
      return false;
    }

    return true;
  }, [
    communityHasActiveAffiliateProducts,
    isCommunityAffiliate,
    isPendingApproval,
    isUserCommunityRoleFetched,
    isUserDataMissingAffiliateInfo
  ]);

  // Initialize show join affilicate card state
  useEffect(() => {
    if (!isAffiliateProduct) return;
    if (!isUserCommunityRoleFetched) return;
    if (isPendingApproval) return;
    if (isCommunityAffiliate) return;
    if (isUserDataMissingAffiliateInfo) return;
    if (isFetchingUser) return;

    setShowJoinAsAffiliateCard(true);
  }, [
    isAffiliateProduct,
    isUserCommunityRoleFetched,
    isPendingApproval,
    isCommunityAffiliate,
    isUserDataMissingAffiliateInfo,
    isFetchingUser
  ]);

  // Initialize show join affilicate modal state
  useEffect(() => {
    if (!isAffiliateProduct) return;
    if (!queryShowJoinAsAffiliateModal) return;
    if (!isUserCommunityRoleFetched) return;
    if (isPendingApproval) return;
    if (isCommunityAffiliate) return;
    if (isUserDataMissingAffiliateInfo) return;
    if (isFetchingUser) return;

    setShowJoinAsAffiliateModal(true);
  }, [
    isAffiliateProduct,
    queryShowJoinAsAffiliateModal,
    isUserCommunityRoleFetched,
    isPendingApproval,
    isCommunityAffiliate,
    isUserDataMissingAffiliateInfo,
    isFetchingUser
  ]);

  return {
    // navitem
    showBecomeAnAffiliateNavMenuItem,

    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate,
    joinAsAffiliateError
  };
};

export type TypeJoinAsAffiliateProps = {
  isSubmittingJoinAsAffiliate: boolean;
  joinAsAffiliateError: string | null;
  onCloseJoinAsAffiliateModal: () => void;
  onJoinAsAffiliate: () => void;
  onOpenJoinAsAffiliateModal: () => void;
  showBecomeAnAffiliateNavMenuItem: boolean;
  showJoinAsAffiliateCard: boolean;
  showJoinAsAffiliateModal: boolean;
};

export type TypeAffiliateInfo = {
  communityHasActiveAffiliateProduct: boolean;
  isAffiliateProduct: boolean;
};

export default useLandingPageJoinAsAffiliateProgram;
