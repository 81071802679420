// NOTE :
// The order of css imports in this file is intentional and must not be changed.
// Kindly make sure you or any auto formatters do not overwrite it.
// When you update this file, use the "Save without formatting" option.
import '../styles/tailwind/globals.scss';
import './app.scss';

import { SpeedInsights } from '@vercel/speed-insights/next';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useMemo, useRef } from 'react';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'shaka-player/dist/controls.css';

import { initiateGTM } from '@/utility/analytics';
import { sendContentHeightToTheFrameParent } from '@/utility/helpers';
import {
  fetchLanguageDataByLocale,
  getCurrentLocale,
  initializeLanguageData
} from '@/utility/localization';
import { getPosthogModule } from '@/utility/posthogService';
import { isLocalHost } from '@/utility/serverHelpers';

import { AuthContextProvider } from '@/contexts/AuthContext';
import { FirebaseProvider } from '@/contexts/FirebaseContext';
import { GoogleLoginProvider } from '@/contexts/GoogleLoginProvider';
import { PixelsContextProvider } from '@/contexts/PixelsContext';
import { WindowWidthContextProvider } from '@/contexts/WindowWidthContext/WindowWidthContext';

import ErrorBoundary from '@/components/common/ErrorBoundary';
import Head from '@/components/common/Head';
import RouteTracker from '@/components/common/RouteTracker';
import { ToastContainer } from '@/components/common/ToastContainer';

import useChatWidget from '@/hooks/useChatWidget';

import poppins from '../styles/poppins';
import WebVitals from './WebVitals';

const ConfettiContainer = dynamic(
  () => import('@/components/common/ConfettiContainer'),
  { ssr: false }
);
const NavigationProgressBar = dynamic(
  () =>
    import(
      '@/components/common/NavigationProgressBar/NavigationProgressBar'
    ),
  { ssr: false }
);

// Check that PostHog is client-side (used to handle Next.js SSR)
const initializePosthog = async () => {
  const posthog = await getPosthogModule();
  posthog?.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    capture_pageview: false,
    capture_pageleave: false,
    capture_performance: true
  });
};

if (typeof window !== 'undefined') {
  (function () {
    const currentLocale = getCurrentLocale();
    fetchLanguageDataByLocale(currentLocale).then((fullLanguageData) => {
      initializeLanguageData(fullLanguageData, currentLocale);
    });
  })();
}

// suppress useLayoutEffect warnings when running outside a browser
if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect;

function MyApp({ Component, pageProps }) {
  useChatWidget();

  const isAppMountedRef = useRef(false);

  //if the page page has custom layout that needs to be shared across multiple specific pages
  const getLayout = Component.getLayout ?? ((page) => page);

  if (!isAppMountedRef.current) {
    initializeLanguageData(
      pageProps?.languageData,
      pageProps?.currentLocale
    );
    isAppMountedRef.current = true;
  }

  useEffect(() => {
    (async () => {
      //re-initialize language data with full translation keys from the client-size
      const fullLanguageData = await fetchLanguageDataByLocale(
        pageProps?.currentLocale
      );
      initializeLanguageData(fullLanguageData, pageProps?.currentLocale);
    })();
    sendContentHeightToTheFrameParent();
  }, []);

  useEffect(() => {
    initiateGTM();
    initializePosthog();
  }, []);

  const renderAppProviders = useMemo(() => {
    return (
      <WindowWidthContextProvider>
        <GoogleLoginProvider>
          <PixelsContextProvider>
            <NavigationProgressBar />
            {getLayout(<Component {...pageProps} />)}
            <ToastContainer />
            <ConfettiContainer />
          </PixelsContextProvider>
        </GoogleLoginProvider>
      </WindowWidthContextProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps]);

  return (
    <div className={classNames('c-MyApp', poppins.variable)}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        html {
          font-family: ${poppins.style.fontFamily} !important;
        }
        .shaka-video-container {
          font-family: ${poppins.style.fontFamily} !important;
        }
      `}</style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />

        <meta
          name="google-site-verification"
          content="NQ1IZVU4g7iIaXwENMnrVBlPca7nJH3r6Dxv2ChX658"
        />

        <meta
          name="facebook-domain-verification"
          content="750yp8lttgw11xhcvmxzgl2z6e666b"
        />
      </Head>
      <SpeedInsights />
      <WebVitals />
      <RouteTracker />
      <AuthContextProvider>
        <FirebaseProvider>
          {isLocalHost() ? (
            renderAppProviders
          ) : (
            <ErrorBoundary>{renderAppProviders}</ErrorBoundary>
          )}
        </FirebaseProvider>
      </AuthContextProvider>
    </div>
  );
}

export default MyApp;
