import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { useAuthContext } from '@/contexts/AuthContext';
import { useFirebaseContext } from '@/contexts/FirebaseContext';

import Icon from '@/components/npl/Icon';

const JoinWhatsappGroupCard = dynamic(
  () => import('./JoinWhatsappGroupCard')
);

const JoinWhatsappChatCard = ({
  isWhatsappExperienceCommunity,
  waGroupChatLink,
  isCommunityMember,
  communityId
}) => {
  const { firestoreUtils } = useFirebaseContext();
  const { user } = useAuthContext();
  const [showJoinWAGroupCard, setShowJoinWAGroupCard] = useState(false);

  const showCard =
    showJoinWAGroupCard &&
    isWhatsappExperienceCommunity &&
    waGroupChatLink;

  const userId = user?._id;

  const fetchUserHaveSeenChatCard = async () => {
    if (!userId || !communityId) return false;

    const docRef = firestoreUtils?.createDocRef(
      'seenChatCardInHometab',
      userId
    );
    const data = await firestoreUtils?.fetchDoc(docRef);
    setShowJoinWAGroupCard(!data?.[communityId]);
    return Boolean(data?.[communityId]);
  };

  const setHaveSeenChatCard = async () => {
    try {
      if (!userId || !communityId) return;

      const docRef = firestoreUtils?.createDocRef(
        'seenChatCardInHometab',
        userId
      );

      // Update firestore
      await firestoreUtils?.updateDoc(
        docRef,
        {
          [communityId]: true
        },
        {
          merge: true
        }
      );

      setShowJoinWAGroupCard(false);
    } catch (error) {
      console.error(error);
    }
  };

  const hideJoinWAGroupCard = async (overridePhoneNumberCheck = false) => {
    if (user?.learner?.phoneNumber || overridePhoneNumberCheck) {
      setShowJoinWAGroupCard(false);
      //Store in firebase that it is seen
      await setHaveSeenChatCard();
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserHaveSeenChatCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return showCard ? (
    <div className="mx-24 p-24 bg-white-default rounded-16 shadow-npl-styles-shadow-01 relative">
      <JoinWhatsappGroupCard
        waGroupChatLink={waGroupChatLink}
        isCommunityMember={isCommunityMember}
        hideJoinWAGroupCard={hideJoinWAGroupCard}
      />
      <div
        onClick={() => hideJoinWAGroupCard(true)}
        role="button"
        tabIndex={0}
        className="absolute right-8 top-8 flex h-32 w-32 items-center justify-center">
        <Icon
          name="x-close"
          className="fill-npl-neutral-light-solid-8 hover:fill-dark-1b"
          width={16}
          height={16}
        />
      </div>
    </div>
  ) : null;
};

export default JoinWhatsappChatCard;
