import { useEffect, useState } from 'react';

import { useAuthContext } from '@/contexts/AuthContext';
import { useFirebaseContext } from '@/contexts/FirebaseContext';

export const useMemberEducationBanner = (
  fireStoreId: 'chatTabMovedNotice',
  shouldShow?: boolean
) => {
  const [isBannerShown, setIsBannerShown] = useState(shouldShow);
  const { firestoreUtils } = useFirebaseContext();
  const { user } = useAuthContext();
  const userId = user?._id;

  const fetchUserHaveSeenBanner = async () => {
    if (!userId) return false;
    const docRef = firestoreUtils?.createDocRef(
      'seenEducationBanner',
      userId
    );
    const data = await firestoreUtils?.fetchDoc(docRef);
    setIsBannerShown(!data?.[fireStoreId]);
    return Boolean(data?.[fireStoreId]);
  };

  const hideBanner = async () => {
    try {
      if (!userId) return;
      const docRef = firestoreUtils?.createDocRef(
        'seenEducationBanner',
        userId
      );
      await firestoreUtils?.updateDoc(
        docRef,
        { [fireStoreId]: true },
        { merge: true }
      );
      setIsBannerShown(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user && shouldShow) {
      fetchUserHaveSeenBanner();
    }
  }, [user, shouldShow]);

  return { isBannerShown, hideBanner };
};
