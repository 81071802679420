import classNames from 'classnames';
import React from 'react';

import Icon from './Icon';
import type { IconName } from './Icon';

export const NPL_BADGE_HIERARCHY = {
  SUCCESS: 'success',
  DANGER: 'danger',
  ACCENT: 'accent',
  INFO: 'info',
  OUTLINE: 'outline',
  ORANGE: 'orange',
  neutral: 'neutral',
  SKY: 'sky'
} as const;

type BadgeHierarchy =
  (typeof NPL_BADGE_HIERARCHY)[keyof typeof NPL_BADGE_HIERARCHY];

type BadgeSize = 'xs' | 'sm' | 'md';

interface NPLBadgeProps {
  text: string | React.JSX.Element;
  type: BadgeHierarchy;
  darkMode?: boolean;
  isFullyRounded?: boolean;
  leadIcon?: IconName<'common'>;
  size?: BadgeSize;
  tailIcon?: IconName<'common'>;
  tailIconOnClick?: () => void;
}

function NPLBadge({
  type,
  text,
  size = 'sm',
  leadIcon,
  tailIcon,
  tailIconOnClick,
  isFullyRounded = false,
  darkMode = false
}: NPLBadgeProps) {
  const getTypeClassName = (): string => {
    switch (type) {
      case NPL_BADGE_HIERARCHY.ACCENT:
        return 'text-npl-yellow-light-solid-11 bg-npl-yellow-light-solid-3';
      case NPL_BADGE_HIERARCHY.DANGER:
        return 'text-npl-error-light-11 bg-npl-error-light-3';
      case NPL_BADGE_HIERARCHY.SUCCESS:
        return 'text-npl-success-light-11 bg-npl-success-light-3';
      case NPL_BADGE_HIERARCHY.INFO:
        if (darkMode) {
          return 'text-npl-neutral-dark-solid-11 bg-npl-neutral-dark-solid-3';
        } else {
          return 'text-npl-neutral-light-solid-11 bg-npl-neutral-light-solid-3';
        }
      case NPL_BADGE_HIERARCHY.OUTLINE:
        if (darkMode) {
          return 'border-1 border-npl-neutral-dark-alpha-7 text-npl-text-secondary-on-dark';
        } else {
          return 'border-1 border-npl-neutral-light-alpha-7 text-npl-text-secondary-on-light';
        }
      case NPL_BADGE_HIERARCHY.ORANGE:
        return 'text-npl-orange-light-11 bg-npl-orange-light-3';
      case NPL_BADGE_HIERARCHY.neutral:
        return 'text-npl-text-secondary-on-light bg-npl-neutral-light-solid-4';
      case NPL_BADGE_HIERARCHY.SKY:
        return 'text-npl-sky-light-11 bg-npl-sky-light-3';
      default:
        return '';
    }
  };

  const getIconClassName = (): string => {
    switch (type) {
      case NPL_BADGE_HIERARCHY.ACCENT:
        return 'fill-npl-yellow-light-solid-11';
      case NPL_BADGE_HIERARCHY.DANGER:
        return 'fill-npl-error-light-11';
      case NPL_BADGE_HIERARCHY.SUCCESS:
        return 'fill-npl-success-light-11';
      case NPL_BADGE_HIERARCHY.INFO:
        if (darkMode) {
          return 'fill-npl-neutral-dark-alpha-11';
        } else {
          return 'fill-npl-neutral-light-alpha-11';
        }
      case NPL_BADGE_HIERARCHY.OUTLINE:
        if (darkMode) {
          return 'fill-npl-text-secondary-on-dark';
        } else {
          return 'fill-npl-text-secondary-on-light';
        }
      case NPL_BADGE_HIERARCHY.ORANGE:
        return 'fill-npl-orange-light-11';
      case NPL_BADGE_HIERARCHY.neutral:
        return 'fill-npl-text-secondary-on-light';
      case NPL_BADGE_HIERARCHY.SKY:
        return 'fill-npl-sky-light-11';
      default:
        return '';
    }
  };

  const getIconSize = (): number => {
    switch (size) {
      case 'xs':
        return 13;
      case 'sm':
        return 14;
      case 'md':
        return 16;
      default:
        return 14;
    }
  };

  const getTextSizeClassName = (): string => {
    switch (size) {
      case 'xs':
        return 'text-overline-xs font-semibold';
      case 'sm':
        return 'text-label-sm';
      case 'md':
        return 'text-label-md';
      default:
        return 'text-label-sm';
    }
  };

  const getPaddingClassName = (): string => {
    switch (size) {
      case 'sm':
        return 'py-2 px-4';
      case 'md':
        return 'p-8';
      default:
        return 'py-2 px-4';
    }
  };

  const renderIcon = (icon: IconName<'common'>): React.ReactNode => {
    return (
      <Icon
        name={icon}
        width={getIconSize()}
        height={getIconSize()}
        className={getIconClassName()}
      />
    );
  };

  return (
    <div
      className={classNames(
        `flex items-center gap-6 rounded-4 ${getTypeClassName()} ${getPaddingClassName()}`,
        isFullyRounded ? 'rounded-full' : 'rounded-4'
      )}>
      {leadIcon && renderIcon(leadIcon)}
      <div className={`${getTextSizeClassName()} text-center font-medium`}>
        {text}
      </div>
      {tailIcon && (
        <div
          className={classNames({ 'cursor-pointer': tailIconOnClick })}
          onClick={tailIconOnClick}>
          {renderIcon(tailIcon)}
        </div>
      )}
    </div>
  );
}

export default NPLBadge;
