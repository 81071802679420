import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  createCommand
} from 'lexical';
import { useEffect } from 'react';

import {
  $createMentionedProductNode,
  MentionedProductNode
} from '../../nodes/MentionedProductNode';
import type { MentionedProductNodeType } from '../../nodes/MentionedProductNode/types';
import { EditorCommunityDataType } from '../../types';

export const INSERT_MENTIONED_PRODUCT_COMMAND: LexicalCommand<unknown> =
  createCommand('INSERT_MENTIONED_PRODUCT_COMMAND');

export default function MentionedProductPlugin({
  communityData
}: {
  communityData: EditorCommunityDataType;
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([MentionedProductNode])) {
      throw new Error(
        'MentionedProductPlugin: MentionedProductNode not registered on editor'
      );
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_MENTIONED_PRODUCT_COMMAND,
        (payload: MentionedProductNodeType) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            if ($isRootNode(selection.anchor.getNode())) {
              selection.insertParagraph();
            }
            const node = $createMentionedProductNode({
              ...payload,
              communityData
            });
            selection.insertNodes([node, $createParagraphNode()]);
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
}
