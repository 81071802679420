import style from '@/features/community/components/OverviewSection.module.scss';

import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';

import { trackGAEvent } from '@/utility/analytics';
import { MEMBER_JOIN_COMMUNITY_CLICK } from '@/utility/analyticsConsts';
import { checkIfWebIsInFrame, formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';
import { getCommunityPageTabRoute } from '@/utility/routesHelper';

import { getPlatformDetails } from '@/features/community/utils';

import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import { TABS_ID } from '../../constants';
import UpsellDiscountDisplay from './UpsellDiscountDisplay';

function CommunityDetailsSection({
  communityPublicPageData = {},
  openSignUpModal,
  priceTagText,
  isPendingApproval,
  discountPercentageForHighestInterval,
  discountCodeFromQueryParams,
  joinCommunityButtonCTAProps,
  discountedCodeData
}) {
  const router = useRouter();

  const {
    backgroundImage,
    profileImage,
    title,
    host,
    memberCount,
    _id: communityId,
    code,
    applicationConfig
  } = communityPublicPageData || {};

  const requestApproval = applicationConfig?.requestApproval;
  const platformName = communityPublicPageData?.platform?.name;

  const onJoinCommunityClick = () => {
    trackGAEvent(MEMBER_JOIN_COMMUNITY_CLICK, {
      communityId: communityId,
      communityCode: code
    });

    openSignUpModal();
  };

  const hideTotalMemberCount =
    communityPublicPageData?.config?.hideMemberCount;

  const getPlatform = useCallback(() => {
    return getPlatformDetails(platformName)?.getIcon({
      customClassNames: 'w-24 h-24',
      width: 24,
      height: 24
    });
  }, [platformName]);

  const isInFrame = checkIfWebIsInFrame();

  const routeToPeopleTab = () => {
    router.push({
      pathname: getCommunityPageTabRoute({
        communitySlug: communityPublicPageData?.slug,
        tabId: TABS_ID.COMMUNITY
      })
    });
  };

  if (isInFrame) {
    return (
      <div className="mx-auto flex max-w-[480px] flex-col justify-center">
        <div
          className={` flex rounded-t-16 bg-npl-transparent-white-50 ${
            discountCodeFromQueryParams ? '' : 'rounded-b-16'
          } mt-16 border-1 border-npl-transparent-black-10 px-8 py-20`}>
          {!hideTotalMemberCount && (
            <div className="flex w-full max-w-[112px] flex-col items-center gap-2 px-8">
              <div className="text-label-sm">
                {memberCount > 1 ? t('members') : t('member')}
              </div>
              <div className="text-heading-xs font-semibold">
                {formatNumber(memberCount)}
              </div>
            </div>
          )}
          <div
            className={classNames(
              `flex flex-1 flex-col items-center px-8 text-center`,
              {
                'border-l-1 border-npl-transparent-black-10':
                  !hideTotalMemberCount
              }
            )}>
            <div className="text-label-sm">{t('access')}</div>
            <div>
              {priceTagText === t('free') ? (
                <span className="text-heading-xs font-semibold">
                  {priceTagText}
                </span>
              ) : (
                priceTagText
              )}
            </div>
            {discountPercentageForHighestInterval && (
              <div className="mt-2 text-label-sm text-npl-yellow-light-solid-11">
                {discountPercentageForHighestInterval.text}
              </div>
            )}
          </div>
        </div>
        <div className="mt-16 w-full">
          <NPLButton
            size="xl"
            stretch
            {...joinCommunityButtonCTAProps}
            onClick={onJoinCommunityClick}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="c-CommunityDetailsSection mx-auto flex max-w-[480px] flex-col justify-center pb-32">
      {/* Banner */}
      <div className="relative">
        {backgroundImage ? (
          <NextImage
            className="aspect-h-9 aspect-w-16 md:rounded-16"
            mobileImgProps={{
              src: backgroundImage,
              layout: 'fill',
              objectFit: 'cover',
              priority: true,
              quality: 100
            }}
          />
        ) : (
          <div
            key={'backgroundCss'}
            className={`aspect-h-9 aspect-w-16 h-auto w-full rounded-16 ${style['gradient-background']} `}
          />
        )}
      </div>
      {/* Community Image */}
      <div className="relative mx-auto mt-[-40px]">
        <div className="flex h-90 w-90 items-center justify-center rounded-16 bg-white-default">
          <NextImage
            className="m-auto h-80 w-80 rounded-16"
            mobileImgProps={{
              src: profileImage,
              width: 80,
              height: 80,
              objectFit: 'cover',
              priority: true,
              quality: 100
            }}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center px-16">
        {/* Community Title and HostName */}
        <div className="mx-auto mt-16 text-center">
          <h1 className="text-heading-xl font-semibold">{title}</h1>
          <p className="mt-4 text-label-md text-npl-text-icon-on-light-surface-primary">
            {t('by')} {host?.name}
          </p>
        </div>
        {/* Community members, price, and chat */}
        <div
          className={` flex rounded-t-16 bg-npl-transparent-white-50 ${
            discountCodeFromQueryParams ? '' : 'rounded-b-16'
          } mt-16 border-1 border-npl-transparent-black-10 px-8 py-20`}>
          {!hideTotalMemberCount && (
            <div
              className="flex w-full max-w-[112px] flex-col items-center gap-2 px-8 cursor-pointer"
              onClick={routeToPeopleTab}>
              <div className="text-label-sm">
                {memberCount > 1 ? t('members') : t('member')}
              </div>
              <div className="text-heading-xs font-semibold">
                {formatNumber(memberCount)}
              </div>
            </div>
          )}
          <div
            className={classNames(
              `flex flex-1 flex-col items-center px-8 text-center`,
              {
                'border-l-1 border-npl-transparent-black-10':
                  !hideTotalMemberCount
              }
            )}>
            <div className="text-label-sm">{t('access')}</div>
            <div>
              {priceTagText === t('free') ? (
                <span className="text-heading-xs font-semibold">
                  {priceTagText}
                </span>
              ) : (
                priceTagText
              )}
            </div>
            {discountPercentageForHighestInterval && (
              <div className="mt-2 text-label-sm text-npl-yellow-light-solid-11">
                {discountPercentageForHighestInterval.text}
              </div>
            )}
          </div>
          {platformName && (
            <div
              onClick={routeToPeopleTab}
              className="flex w-full max-w-[112px] flex-1 flex-col border-l-1 border-npl-transparent-black-10 px-8 text-center cursor-pointer">
              <div className="text-label-sm">{t('chat-on')}</div>
              <div className="mx-auto">{getPlatform()}</div>
            </div>
          )}
        </div>
        {discountCodeFromQueryParams && (
          <>
            {discountedCodeData?.discountCategory === 'UPSELL' ? (
              <UpsellDiscountDisplay {...discountedCodeData} />
            ) : (
              <>
                {discountCodeFromQueryParams && (
                  <div className="z-[-1] w-full animate-moveDownLibrary rounded-b-8 border-b-1 border-l-1 border-r-1 border-npl-transparent-black-10 bg-npl-success-light-3 p-8  transition-all">
                    <div className="flex justify-center gap-4 text-label-sm text-npl-success-light-11">
                      <Icon
                        name="tag-01"
                        width={16}
                        height={16}
                        fill="#18774C"
                      />
                      <span className="font-medium">
                        {discountCodeFromQueryParams}
                      </span>
                      {t('applied-0')}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {/* Community CTA Button */}
        <div className="mt-16 w-full">
          <NPLButton
            size="xl"
            stretch
            {...joinCommunityButtonCTAProps}
            onClick={onJoinCommunityClick}
          />
        </div>
        {/* Approval required section */}
        {isPendingApproval ? (
          <div className="mt-16 text-center text-label-sm text-npl-text-icon-on-light-surface-secondary">
            {t('we-will-let-you-know-when-your-application-is-approved')}
          </div>
        ) : (
          requestApproval && (
            <div className="mx-auto mt-16 flex gap-8 ">
              <Icon
                name="lock-01"
                width={16}
                height={16}
                fill="#1B1B1880"
              />
              <p className="text-label-md text-npl-text-icon-on-light-surface-tertiary">
                {t('approval-required')}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CommunityDetailsSection;
