import React, { FC } from 'react';

import NplToolTip from '@/components/common/NplToolTip';
import NPLLinkButton, {
  NPLLinkButtonProps
} from '@/components/npl/NPLLinkButton';

import Icon from '../../Icon';

export type NPLInputTopSectionProps = {
  isRequired?: boolean;
  label?: string;
  labelDescription?: string;
  linkButtonProps?: Omit<NPLLinkButtonProps, 'hierarchy' | 'size'>;
  tooltipText?: string;
};

const NPLInputTopSection: FC<NPLInputTopSectionProps> = ({
  label,
  labelDescription,
  isRequired,
  tooltipText,
  linkButtonProps
}) => {
  return (
    <div className="flex justify-between mb-6">
      <div className="flex gap-4">
        {label && (
          <p className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
            {label}
          </p>
        )}
        {labelDescription && (
          <p className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
            {labelDescription}
          </p>
        )}
        {isRequired && (
          <p className="text-label-md font-medium text-npl-error-light-9">
            *
          </p>
        )}
        {tooltipText && (
          <NplToolTip text={tooltipText} title={label}>
            <Icon
              name="info-circle"
              width={16}
              height={16}
              fill="#1B1B1859"
            />
          </NplToolTip>
        )}
      </div>
      {linkButtonProps && (
        <NPLLinkButton hierarchy="accent" size="xs" {...linkButtonProps} />
      )}
    </div>
  );
};

export default NPLInputTopSection;
