import { FC } from 'react';

import { ItemProps } from '../types';

const NPLSearchDropdownItem: FC<ItemProps> = ({
  children,
  bottomDivider,
  onClick,
  index
}) => {
  return (
    <>
      <div
        key={index}
        onClick={onClick}
        className="w-full px-8 rounded-4 h-[56px] flex flex-row items-center justify-between hover:bg-npl-neutral-alpha-2 cursor-pointer">
        {children}
      </div>
      {bottomDivider && (
        <div className="w-full min-h-[1px] bg-npl-neutral-light-solid-4 my-4" />
      )}
    </>
  );
};

export default NPLSearchDropdownItem;
