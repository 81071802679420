const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV || 'development';

export const isSentryConfigAvailable = Boolean(
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
);

const sentryBasedConfigs = {
  enabled: isSentryConfigAvailable, //only enable Sentry on live websites
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: NEXT_PUBLIC_ENV,

  // Sentry Replay
  // Doc: https://docs.sentry.io/platforms/javascript/session-replay/configuration/
  // Setting both to 0 (replaysSessionSampleRate & replaysOnErrorSampleRate) because we will use replay.start() and replay.stop() programatically to record replays
  replaysSessionSampleRate: 0, // The sample rate for replays that begin recording immediately and last the entirety of the user's session. 1.0 collects all replays, and 0 collects none.
  replaysOnErrorSampleRate: 1, // The sample rate for replays that are recorded when an error happens. This type of replay will record up to a minute of events prior to the error and continue recording until the session ends. 1.0 captures all sessions with an error, and 0 captures none.
  //ignore errors to reduce alert noises
  ignoreErrors: [
    /Non-Error promise rejection captured with/i,
    /AbortError/i,
    /AbortError: The play\(\)/i,
    /Invariant: attempted to hard navigate to the same URL/i,
    /Failed to load static props/i,
    /Unexpected token '<'/i,
    /No space between hexadecimal literal and identifier/i,
    /Unexpected token \./i,
    /Failed to fetch/i,
    /NetworkError/i,
    /Component closed/i,
    /Error:Component closed/i,
    /Object captured as promise rejection with keys: category, code, data, handled, severity/i,
    /Java object is gone/i,
    /Checkout is currently unavailable for this community. Please contact the community admin for assistance./i,
    /Error in callInitialCheckout: Checkout is currently unavailable for this community. Please contact the community admin for assistance./i,
    /Error in updateCheckoutState: Sorry, the payment session has expired. Please refresh the page./i,
    /Community manager already owns the product and therefore cannot make additional purchases./i,
    /Error in callInitialCheckout: Community manager already owns the product and therefore cannot make additional purchases./i
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    //Uncontrollable packages
    /shaka-player\.ui/i,
    /nanoid/i,
    /webkit-masked-url/i
  ]
};

export default sentryBasedConfigs;
