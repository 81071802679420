import classNames from 'classnames';
import React from 'react';

import HeadlessSelect from '@/components/common/Form/HeadlessSelect/HeadlessSelect';

import Icon from '../../Icon';

const NPLInputLeadTailSection = ({ component }) => {
  const renderIcon = (iconProps) => (
    <div>
      <Icon
        name={iconProps.name}
        width={16}
        height={16}
        fill="#1B1B18A6"
      />
    </div>
  );

  const renderText = (text) => (
    <div className="flex items-center">
      <span className="max-w-[90px] flex-shrink-0 flex-grow overflow-hidden whitespace-nowrap text-body-md text-npl-text-icon-on-light-surface-tertiary">
        {text}
      </span>
    </div>
  );

  const renderSelect = (selectProps) => (
    <div className="hover:cursor-pointer">
      <HeadlessSelect
        {...selectProps}
        renderField={({ option, placeholder, isOpen }) => (
          <div className="flex flex-shrink-0 flex-grow items-center gap-8 py-12">
            <div className="whitespace-nowrap text-body-sm text-npl-text-icon-on-light-surface-primary">
              {option ? option?.label : placeholder}
            </div>
            <div
              className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`}>
              <Icon
                name="chevron-down"
                width="20"
                height="20"
                fill="#1B1B18A6"
              />
            </div>
          </div>
        )}
        renderOption={({ value, option, handleOptionClick }) => (
          <div
            role="button"
            tabIndex={0}
            onClick={() =>
              !option?.disabled ? handleOptionClick(option) : null
            }
            className={classNames(
              'mx-8 my-4 rounded-8 px-8 py-8 font-normal text-neutral-10 hover:bg-neutral-97',
              value === option?.value ? 'bg-neutral-95' : '',
              option?.disabled ? 'cursor-not-allowed text-neutral-70' : ''
            )}>
            {option?.optionsDisplay || option?.label}
          </div>
        )}
      />
    </div>
  );

  const renderComponent = () => {
    if (!component) return null;

    if (React.isValidElement(component)) {
      return component;
    }

    if (typeof component === 'string') {
      return renderText(component);
    }

    if (component.iconProps) {
      return renderIcon(component.iconProps);
    }

    if (component.selectProps) {
      return renderSelect(component.selectProps);
    }

    return null;
  };

  return (
    <div className="flex items-center gap-12 flex-row px-16">
      {renderComponent()}
    </div>
  );
};

export default NPLInputLeadTailSection;
