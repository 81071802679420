import config from '@/utility/config';
import {
  getChallengeDurationText,
  getFormattedStartEndDate,
  getLongTimeZoneName
} from '@/utility/dateHelper';
import { ENTITY_SLUGS_PREFIX, formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  getCommunityCheckoutRoute,
  getEntityPublicPageUrl
} from '@/utility/routesHelper';

import { PRODUCT_REQUESTOR } from '@/features/SignUpPopup/constants';

import type {
  MentionedCardEntityDataType,
  MentionedCardEntityType,
  MentionedCardLinkType,
  MentionedCardSizeType
} from '@/components/common/MentionedCard/types';

import { getSourceEntityInfo } from '@/hooks/utils/communityMembersUtils';

import { getMembershipIntervalText } from '@/pages/user/utils/membershipUtils';

import { EditorCommunityDataType } from '../../types';
import {
  entitySourceMap,
  getLinkTypeOptions,
  getSizeOptions
} from './constants';
import { MentionedEntityDataType } from './types';

export const getSelectedSizeOption = (sizeId: MentionedCardSizeType) => {
  const sizeOptions = getSizeOptions();
  return sizeOptions.find((option) => option.id === sizeId);
};

export const getSelectedLinkTypeOption = (
  linkTypeId: MentionedCardLinkType
) => {
  const linkTypeOptions = getLinkTypeOptions();
  return linkTypeOptions.find((option) => option.id === linkTypeId);
};

const getMentionedProductPriceText = (
  price: number,
  currency: string,
  interval?: string,
  intervalCount?: number
) => {
  if (!price) {
    return t('free');
  }

  if (interval && intervalCount) {
    return `${currency} ${formatNumber(price, true, true)}/${getMembershipIntervalText(interval, intervalCount)}`;
  }

  return `${currency} ${formatNumber(price, true, true)}`;
};

export const sanitizeMentionedProductData = (
  data: MentionedEntityDataType,
  hasFullInfo: boolean
) => {
  const {
    type,
    title,
    thumbnail,
    priceType,
    price,
    priceDetails,
    currency,
    baseCurrency,
    startTime,
    endTime,
    ownerName,
    hostName,
    durationIntervalInMinutes,
    profileImage
  } = data;

  const sanitizedData: MentionedCardEntityDataType = {
    type,
    title,
    coverImage: type === 'SUBSCRIPTION' ? profileImage : thumbnail
  };

  if (priceType === 'FIXED') {
    sanitizedData.badge =
      type === 'SUBSCRIPTION'
        ? getMentionedProductPriceText(
            priceDetails?.localAmount,
            priceDetails?.localCurrency
          )
        : getMentionedProductPriceText(price, currency || baseCurrency);
  } else {
    sanitizedData.badge = t('pay-what-you-want');
  }

  switch (type) {
    case 'SUBSCRIPTION':
      sanitizedData.subtitles = hasFullInfo
        ? [
            `${t('product-card-membership-subtitle', { ownerName: ownerName })}`
          ]
        : [];
      if (priceDetails?.discountPerc) {
        sanitizedData.discountText =
          priceDetails.discountInterval === 'year'
            ? t('or-save-math-trunc-biggestsaving-discount-annually', {
                percent: Math.trunc(priceDetails?.discountPerc)
              })
            : t(
                'or-save-math-trunc-biggestsaving-discount-per-biggestsaving-interval-months',
                {
                  percent: Math.trunc(priceDetails?.discountPerc),
                  interval: priceDetails?.discountIntervalCount
                }
              );
      }
      break;
    case 'EVENT':
      sanitizedData.subtitles = [
        `${getFormattedStartEndDate({ startDate: startTime, endDate: endTime, hasTime: true })}`
      ];
      if (hasFullInfo) {
        sanitizedData.subtitles.push(getLongTimeZoneName());
      }
      break;
    case 'FOLDER':
      sanitizedData.subtitles = [];
      break;
    case 'CHALLENGE':
      sanitizedData.subtitles = startTime
        ? [
            `${getFormattedStartEndDate({ startDate: startTime, endDate: endTime })}`
          ]
        : [];

      if (startTime && hasFullInfo) {
        sanitizedData.subtitles.push(
          getChallengeDurationText(startTime, endTime)
        );
      }
      break;
    case 'SESSION':
      sanitizedData.subtitles = [
        t('durationmins-min-with-hostname', {
          durationMins: durationIntervalInMinutes,
          hostName
        })
      ];
      break;
  }

  return sanitizedData;
};

export const getMentionedProductLink = ({
  communitySlug,
  entityType,
  slug
}: {
  communitySlug: string;
  entityType: MentionedCardEntityType;
  slug: string;
}) => {
  const entitySlugTypes: {
    [K in Exclude<MentionedCardEntityType, 'SUBSCRIPTION'>]: string;
  } = {
    CHALLENGE: ENTITY_SLUGS_PREFIX.CHALLENGES,
    EVENT: ENTITY_SLUGS_PREFIX.EVENTS,
    FOLDER: ENTITY_SLUGS_PREFIX.PRODUCTS,
    SESSION: ENTITY_SLUGS_PREFIX.PRODUCTS
  };

  if (entityType === 'SUBSCRIPTION') {
    const communitySlugWithoutSlash = communitySlug.replace(/\//g, '');
    return `/${communitySlugWithoutSlash}`;
  }

  return getEntityPublicPageUrl({
    communitySlug: communitySlug,
    entityType: entitySlugTypes[entityType],
    entitySlug: slug
  });
};

export const generateMentionedProductLinkByLinkType = ({
  linkType,
  communityData,
  entityData
}: {
  communityData: EditorCommunityDataType;
  entityData: MentionedEntityDataType;
  linkType: MentionedCardLinkType;
}) => {
  const { type, slug, entityObjectId } = entityData || {};

  const link =
    linkType === 'landingPage'
      ? getMentionedProductLink({
          communitySlug: communityData?.slug,
          entityType: type,
          slug
        })
      : getCommunityCheckoutRoute({
          communityCode: communityData?.code,
          communityId: communityData?._id,
          sourceInfo: getSourceEntityInfo({
            signUpOrigin: entitySourceMap[type],
            entityId: entityObjectId
          }),
          requestor: PRODUCT_REQUESTOR.EMAIL,
          hasNoLinkClicked: true
        });

  return `${config.siteBaseUrl}${link}`;
};
