import classNames from 'classnames';
import { LazyMotion, domMax, m } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { scrollToRef } from '@/utility/domHelpers';
import { t } from '@/utility/localization';
import withComponentClassName from '@/utility/withComponentClassName';

import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import usePostReaction from '@/hooks/usePostReaction';
import useRemoveScrollDuringOverlay from '@/hooks/useRemoveScrollDuringOverlay';

import CommentSection from '../CommentSection/CommentSection';
import { trackPostViewed } from '../Feed/analytics';
import PostActionBtnsRow from '../Feed/pages/PostPublicPage/PostActionBtnsRow';
import { ApprovalOptions } from './ApprovalCard/ApprovalOptions';
import PostCardContent from './PostCardContent/PostCardContent';
import { COMMUNITY_POSTS_STATUS } from './constants';

const AnnouncementPreviewModal = ({
  post,
  community,
  closeModal,
  user,
  showMenu = true,
  onLockButtonClick,
  isAdmin,
  onEdit,
  onPin,
  onUnpin,
  onDelete,
  onShareClick,
  handleApprove,
  handleReject,
  approvalLoading,
  handleReport,
  feedConfig,
  handleHideViewCount,
  handleHideLinkCount,
  updateAnnouncementClientSide,
  readyOnly,
  isLocked,
  onSelectedMemberDetails,
  isCommunityMember,
  componentClassName
}) => {
  // community data
  const communityId = community?._id;
  const [commentCount, setCommentCount] = useState(0);
  const actionBarRef = useRef(null);
  const commentInputRef = useRef(null);
  const [isClosed, setIsClosed] = useState(false);

  const pathname = usePathname();

  const isInCMPortal = useMemo(() => {
    return pathname.split('/')[1] === 'portal';
  }, []);

  // post data
  const { _id: postId, disabledComments, totalImpressions } = post || {};
  const isPending = post?.status === COMMUNITY_POSTS_STATUS.PENDING;
  // Legacy posts are always true because they are from CM
  const isApproved = post?.status
    ? post?.status === COMMUNITY_POSTS_STATUS.APPROVED
    : true;

  // Post Like Reaction data
  const { isPostLiked, postLikeCount, handleReactionButtonClick } =
    usePostReaction({
      post,
      postType: 'post',
      communityId,
      postId,
      updateAnnouncementClientSide
    });

  // get comment count from selectedComment (which runs on re-render), not the post props which is stale
  useEffect(
    () => setCommentCount(post?.commentCount ?? 0),
    [post?.commentCount]
  );

  const updateAnnouncementInfo = (props) => {
    updateAnnouncementClientSide?.(postId, {
      ...props?.updatedInfo
    });
    setCommentCount(props?.updatedInfo?.commentCount);
    scrollToRef(actionBarRef);
  };

  const handleCommentClick = () => {
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (postId) trackPostViewed(postId, communityId);
  }, [postId, communityId]);

  const handleCloseWithAnimation = () => {
    setIsClosed(true);
  };

  useRemoveScrollDuringOverlay(!isClosed);

  return createPortal(
    <LazyMotion features={domMax} strict>
      <m.div
        initial={{ opacity: 0.8 }}
        animate={{
          opacity: isClosed ? 0.8 : 100
        }}
        onAnimationComplete={(definition) => {
          const { opacity } = definition;
          if (opacity === 0.8) {
            closeModal?.();
          }
        }}
        transition={{
          type: 'tween',
          duration: 0.1
        }}
        className={classNames(
          componentClassName,
          'overflow-y-auto px-8 md:px-0 py-32 overscroll-contain fixed z-over-intercom pb-120',
          {
            'lg:w-[calc(100vw-240px)] w-full lg:left-[240px] top-64 bg-white-default h-[calc(100vh-64px)]':
              isInCMPortal,
            'w-full h-full top-0 left-0 bg-npl-neutral-light-solid-2':
              !isInCMPortal
          }
        )}>
        <div className="max-w-[560px] mx-auto mb-16 ">
          {isInCMPortal ? (
            <NPLButton
              size="sm"
              buttonText={t('back')}
              onClick={handleCloseWithAnimation}
              leadIcon="arrow-left"
              hierarchy="plain"
            />
          ) : (
            <NPLIconButton
              size="sm"
              hierarchy="neutral_secondary"
              icon="chevron-left"
              onClick={handleCloseWithAnimation}
            />
          )}
        </div>
        {/* Card */}
        <m.div
          initial={{ y: '16px', opacity: 0 }}
          animate={{
            y: isClosed ? '16px' : 0,
            opacity: isClosed ? 0 : 100
          }}
          transition={{
            type: 'tween',
            duration: 0.2
          }}
          className="rounded-12 bg-white-default overflow-hidden max-w-[560px] mx-auto border-1 border-npl-separator-alpha">
          <div className="p-16">
            <PostCardContent
              communityData={community}
              announcement={post}
              communityLink={community?.link}
              communityName={community?.title}
              onEdit={onEdit}
              isLocked={isLocked}
              showMenu={showMenu}
              onDelete={onDelete}
              fullView={true}
              singlePost={true}
              handleReport={handleReport}
              handleHideViewCount={handleHideViewCount}
              handleHideLinkCount={handleHideLinkCount}
              onPin={onPin}
              onUnpin={onUnpin}
              setAnnouncementToShare={onShareClick}
              isManager={isAdmin}
              showStatus={true}
              user={user}
              onSelectedMemberDetails={onSelectedMemberDetails}
            />
          </div>

          {isAdmin && isPending && (
            <div className="mb-8 w-full">
              <ApprovalOptions
                announcement={post}
                onReject={handleReject}
                onApprove={handleApprove}
                loading={approvalLoading}
                successCallBack={closeModal}
              />
            </div>
          )}
          {isApproved && (
            <>
              <div
                className=" w-full py-8 border-npl-separator-alpha"
                onClick={() => {
                  if (isLocked || !isCommunityMember) {
                    closeModal();
                    onLockButtonClick();
                  }
                }}
                ref={actionBarRef}>
                <PostActionBtnsRow
                  postCreationDateString={post?.createdAt}
                  isPostLiked={isPostLiked}
                  onLikeClick={() => {
                    if (isLocked) {
                      return;
                    }
                    handleReactionButtonClick();
                  }}
                  commentCount={commentCount}
                  onShareClick={onShareClick}
                  disabledComments={disabledComments}
                  impressionCount={totalImpressions}
                  isLocked={isLocked}
                  hideViewCount={feedConfig?.hideViewsOnPost}
                  likeCount={
                    feedConfig?.hideLikesOnPost ? 0 : postLikeCount
                  }
                  onCommentClick={handleCommentClick}
                  stretchButtons
                  isCommunityAdmin={true}
                />
              </div>
              {!isLocked && (
                <div className="border-t-1 border-npl-separator-alpha">
                  <CommentSection
                    post={post}
                    readOnly={readyOnly}
                    community={community}
                    commentCount={commentCount}
                    updateAnnouncementInfo={updateAnnouncementInfo}
                    commentInputRef={commentInputRef}
                  />
                </div>
              )}
            </>
          )}
        </m.div>
      </m.div>
    </LazyMotion>,
    document.body
  );
};

export default withComponentClassName(AnnouncementPreviewModal, false);
