import Auth from '@/modules/Auth';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';

import { AI_SUMMARY_CONTENT_TYPES } from '@/features/AIChatSummaryInfo/constants';

import config from './config';
import { getTimezoneId } from './dateHelper';
import { isLocalHost } from './serverHelpers';

const SHORT_MOBILE_TEXT_NUMBER_OF_CHARACTERS = 500;
const SHORT_DESKTOP_TEXT_NUMBER_OF_CHARACTERS = 650;

export const ratingType = {
  FULL: 'full',
  PARTIAL: 'partial',
  EMPTY: 'empty'
};

export const ENTITY_SLUGS_PREFIX = {
  EVENTS: 'events',
  PRODUCTS: 'products',
  SESSIONS: 'sessions',
  CHALLENGES: 'challenges',
  POSTS: 'feed'
};

export const importNanoid = () => {
  return { nanoid };
};

export const createRatingArray = ({ total, rating }) => {
  try {
    let full = Math.floor(rating);
    let partial = Math.ceil(rating % 1);
    let remaining = Math.floor(total - rating);

    let fullRatingArray = Array(full).fill(ratingType.FULL);
    let partialRatingArray = Array(partial).fill(ratingType.PARTIAL);
    let remainingRatingArray = Array(remaining).fill(ratingType.EMPTY);

    return [
      ...fullRatingArray,
      ...partialRatingArray,
      ...remainingRatingArray
    ];
  } catch (e) {
    //Rating should be less than or equal to total & Can only be numbers
    throw new Error('StarRatings: Invalid Rating params');
  }
};

// max 2 decimals
export const convertCentsToDollars = (cents) => {
  if (isNaN(cents)) {
    return '';
  }
  try {
    cents = String(cents).replace(/[^\d.-]/g, '');
    cents = parseFloat(cents);
    return Number((cents / 100).toFixed(2));
  } catch {
    return '';
  }
};

// https://timleland.com/money-in-javascript/
// This handles the conversion with the floating point precision
// 32.8 => 3280
// 0 => 0
// 3,238.9 => 323890
export const convertDollarsToCents = (value) => {
  if (isNaN(value)) {
    return '';
  }
  try {
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : '';
  } catch {
    return '';
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const courseScheduleExists = (countdown) => {
  const { years, days, hrs, mins } = countdown;
  const noSchedule = !years && !days && !hrs && !mins;

  return !noSchedule;
};

// 100000 -> 100,000
// 100000.0322 -> 100,000.03
export const formatNumber = (value, isCentFormat, withDecimal) => {
  try {
    if (!value || isNaN(value)) return '0';
    let formattedValue;
    if (isCentFormat) {
      // Convert cents to dollars and ensure 2 decimal places
      formattedValue = (Number(value) / 100).toFixed(2);
    } else {
      formattedValue = withDecimal
        ? Number(value).toFixed(2)
        : Math.floor(Number(value));
    }

    const parts = String(formattedValue).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If inCentFormat, we should ensure that decimal has 2 charcters
    // eg. 50.5 = 50.50
    if (isCentFormat && (!parts[1] || parts[1].length < 2)) {
      parts[1] = parts[1] ? parts[1].padEnd(2, '0') : '00';
    }
    // Pop '00' out if it exists
    else if (parts[1] && parts[1] === '00') {
      parts.pop();
    }

    return parts.join('.');
  } catch {
    return value;
  }
};

// USD 1,000
export const convertCurrencyAndFormat = ({
  currency,
  conversionMultiplier,
  price
}) => {
  try {
    const convertedPrice = (price * conversionMultiplier)?.toFixed();
    const formattedPrice = formatNumber(convertedPrice);

    if (currency) return `${currency} ${formattedPrice}`;
    else return formattedPrice;
  } catch {
    return `USD ${price}`;
  }
};
export const getFormattedPrice = ({ price, currency }) => {
  if (!currency || price === undefined || price === null) return null;

  let formattedPrice = price;

  if (typeof price === 'number') {
    formattedPrice = price.toFixed(2);
    if (formattedPrice.endsWith('.00')) {
      formattedPrice = formattedPrice.slice(0, -3);
    }
    formattedPrice = parseFloat(formattedPrice).toLocaleString();
  }

  return `${currency} ${formattedPrice}`;
};

export const isTextDescriptionShort = (description, isDesktopView) => {
  if (!description) return false;

  const lengthBoundary = isDesktopView
    ? SHORT_DESKTOP_TEXT_NUMBER_OF_CHARACTERS
    : SHORT_MOBILE_TEXT_NUMBER_OF_CHARACTERS;

  const descriptionLength = description.reduce(
    (acc, curr) => (acc += curr?.text.length),
    0
  );

  return descriptionLength <= lengthBoundary;
};

export const downloadFile = ({ url, fileName }) => {
  try {
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));
    document.body.removeChild(link);
  } catch (e) {
    console.error(e);
  }
};

export const isEvenNumber = (num) => num % 2 === 0;

export const doesValueExist = (value) => {
  return value !== undefined && value !== null;
};

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export function downloadCSV(downloadPath) {
  var hiddenElement = document.createElement('a');
  hiddenElement.href = downloadPath;
  hiddenElement.click();
  hiddenElement.remove();
}

export function isLiteralObject(data) {
  return !!data && data.constructor === Object;
}

export const generateAISummaryNote = ({
  messageCount,
  memberCount,
  date
}) => {
  // escapes function if no date is provided
  if (!date) {
    return;
  }

  return {
    type: AI_SUMMARY_CONTENT_TYPES.NOTE,
    text: `This summary is AI-generated on Nas.io for ${messageCount} messages${
      memberCount ? ` by ${memberCount} members` : ''
    } (${format(new Date(date), 'dd MMM')}).`
  };
};

export const getImgDataObject = (src, alt) => ({
  alt: alt,
  mobileImgData: {
    src
  }
});

export const getNumberWithoutNegativeSign = (num) => {
  const numString = String(num);
  if (numString[0] === '-') {
    const numWithoutNegativeSign = numString.slice(1);
    return parseInt(numWithoutNegativeSign);
  }
  return formatNumber(num);
};

export const checkIfIndexable = (data) => {
  // Check if data is null or undefined
  if (data == null) {
    // Handle null or undefined data
    return true;
  }
  //if data does not have indexable property, we assume it is indexable
  if (!Object.prototype.hasOwnProperty.call(data, 'indexable')) {
    return true;
  }

  //if data has indexable property and it is null or undefined, we assume it is indexable
  if (data.indexable === undefined || data.indexable === null) {
    return true;
  }

  return data.indexable;
};

export const openInNewTab = (url) => {
  //this change is to fix tab opening issue in Safari
  //the related ticket is https://nasacademy.atlassian.net/browse/DEV-105
  const linkElement = document.createElement('a');
  linkElement.href = url;
  linkElement.target = '_blank';
  linkElement.rel = 'noopener noreferrer';
  linkElement.click();
};

export const getThumbnailProps = (thumbnail) => {
  return {
    alt: 'thumbnail',
    mobileImgProps: {
      src: thumbnail,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    desktopImgProps: {
      src: thumbnail,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };
};

// cmsSlug: [ 'community', 'entityType', 'entity' ]
export const getSlugs = (slugs) => {
  // add more here as we add them in the URL
  const supportedEntityTypes = [
    ENTITY_SLUGS_PREFIX.CHALLENGES,
    ENTITY_SLUGS_PREFIX.EVENTS,
    ENTITY_SLUGS_PREFIX.PRODUCTS,
    ENTITY_SLUGS_PREFIX.POSTS
  ];
  const entityType = slugs[slugs.length - 2];

  if (!slugs) return {};

  // if entityType is supported, return entityType
  if (slugs.length === 3 && supportedEntityTypes.includes(entityType)) {
    return {
      communitySlug: slugs[slugs.length - 3],
      entityType: entityType,
      entitySlug: slugs[slugs.length - 1]
    };
    // if entityType is not supported, return communitySlug and entitySlug
  } else {
    return {
      communitySlug: slugs[slugs.length - 2],
      entitySlug: slugs[slugs.length - 1]
    };
  }
};

export const downloadFileWithBlob = async ({ url, fileName }) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';

  const userToken = Auth.getUserToken();
  const headers = {
    'Content-type': 'application/octet-stream'
  };
  if (userToken) {
    headers['Authorization'] = `Bearer ${userToken}`;
  }

  const response = await fetch(url, {
    headers
  });
  const blob = await response.blob();
  const objectURL = URL.createObjectURL(blob);

  a.href = objectURL;
  a.download = fileName;
  a.click();

  window.URL.revokeObjectURL(objectURL);
};

export const getPlatformParamsForApp = (fallbackUrl) => {
  if (config.isProdEnvironment) {
    return {
      apn: 'com.nas.academy', // Android Package Name
      ibi: 'com.nasAcademy', // IOS Bundle ID
      isi: config.appleStoreId, // App Store ID : to redirect user to our app listing
      efr: '1', // skip the app preview page when the Dynamic Link is opened
      ofl: fallbackUrl || 'https://nas.io/install' // if link opened on desktop, redirect to nas.io/install
    };
  }
  return {
    apn: 'com.dev.nas.academy', // Android Package Name
    ibi: 'com.dev.nasAcademy', // IOS Bundle ID
    ifl: 'https://nas.io/install', // The link to open when the app isn't installed. (Nas Dev app has no store listing)
    efr: '1', // skip the app preview page when the Dynamic Link is opened
    ofl: fallbackUrl || 'https://nas.io/install' // if link opened on desktop, redirect to nas.io/install
  };
};

export function getAppLinkForChallenge(community, challenge, queryParams) {
  let redirectionLink = `${config.siteBaseUrl}/mb/communities/${String(
    community._id
  )}/challenges/${String(challenge._id)}`;

  if (
    queryParams &&
    typeof queryParams === 'object' &&
    Object.keys(queryParams).length > 0
  ) {
    const queryString = new URLSearchParams(queryParams).toString();
    redirectionLink = `${redirectionLink}?${queryString}`;
  }

  const params = {
    link: redirectionLink, // link can be anything to redirect in app
    ...getPlatformParamsForApp()
  };
  const appLink =
    `${config.nasIoAppDynamicLink}` +
    '?' +
    new URLSearchParams(params).toString();
  return appLink;
}

export function getAppLinkForChallenges(
  community,
  redirectLink,
  queryParams
) {
  let link = `${config.siteBaseUrl}/mb/communities/${String(
    community._id
  )}/challenges`;

  if (
    queryParams &&
    typeof queryParams === 'object' &&
    Object.keys(queryParams).length > 0
  ) {
    const queryString = new URLSearchParams(queryParams).toString();
    link = `${link}?${queryString}`;
  }

  const params = {
    link: link, // link can be anything to redirect in app
    ...getPlatformParamsForApp(redirectLink)
  };

  const appLink =
    `${config.nasIoAppDynamicLink}` +
    '?' +
    new URLSearchParams(params).toString();

  return appLink;
}

export const checkIsUserFromIndia = () => {
  const indiaTimezones = ['Asia/Kolkata', 'Asia/Calcutta'];
  const currentTimeZone = getTimezoneId();
  return indiaTimezones.includes(currentTimeZone);
};

export const checkIsNegativeNumber = (number) => {
  const numString = String(number);

  return numString[0] === '-';
};

export const getAbsoluteNumber = (number) => {
  return Math.abs(number);
};

export const getAmountWithCurrencyWithMathSigns = ({
  currency,
  amount
}) => {
  const absoluteAmount = getAbsoluteNumber(amount);
  const mathSign = checkIsNegativeNumber(amount) ? '-' : '';

  const formattedAbsoluteAmount = formatNumber(absoluteAmount, true, true);

  return `${mathSign} ${currency} ${formattedAbsoluteAmount}`;
};

export const openFileSelector = (accept, handleSelectingFile) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', accept);
  fileSelector.addEventListener('change', handleSelectingFile);
  fileSelector.click();
};

const checkProductDemoPath = () => {
  try {
    //window.parent.location.path will throw an error if the frame has a different domain
    return (
      typeof window !== 'undefined' &&
      window.self !== window.top &&
      window.parent.location.pathname !== '/product-demo'
    );
  } catch {
    return true; //assume that it's in iframe
  }
};

export const checkIfWebIsInFrame = () => {
  try {
    //if it's with SSR, we can assume it's not in iframe
    //if the window is not on top, it's in iframe
    //exclude /product-demo because it's our page using an iframe
    return (
      typeof window !== 'undefined' &&
      window.self !== window.top &&
      checkProductDemoPath()
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

//this function is particularly used by external websites to get our content height for iframe renderings
export const sendContentHeightToTheFrameParent = () => {
  try {
    console.info('sendContentHeightToTheFrameParent - called');
    if (checkIfWebIsInFrame()) {
      const frameDomain = isLocalHost()
        ? window.top.origin
        : config.parentFrameDomain;
      console.info(
        `sendContentHeightToTheFrameParent - sending message to ${frameDomain} with the height of ${document.body.scrollHeight}`
      );
      window.top.postMessage(document.body.scrollHeight, frameDomain);
      console.info(`sendContentHeightToTheFrameParent - completed`);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getNavbarOpacityByTransitionDistance = (transition) => {
  if (typeof window === 'undefined') {
    return 0;
  }

  if (!transition) {
    return 1;
  }

  const { start, end } = transition || {};
  const scrollPosition = window.scrollY;
  const opacityStartPoint = start;

  let opacity = 0;
  if (scrollPosition > opacityStartPoint) {
    opacity = Math.min(
      (scrollPosition - opacityStartPoint) / (end - start),
      1
    );
  }

  return opacity;
};
