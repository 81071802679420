import classNames from 'classnames';
import { LazyMotion, domMax, m } from 'framer-motion';
import { FC } from 'react';

import { useNPLModal } from '../context/NPLModalContext';
import { NPLModalBackdropProps } from '../types';

const NPLModalBackdrop: FC<NPLModalBackdropProps> = ({
  isCloseOnBackdropClickAllowed,
  blurBackground
}) => {
  const { closeWithAnimation, isClosed } = useNPLModal();
  return (
    <div
      onClick={() => {
        if (isCloseOnBackdropClickAllowed) {
          closeWithAnimation();
        }
      }}>
      <LazyMotion features={domMax} strict>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isClosed ? 0 : 1 }}
          onAnimationComplete={(definition) => {
            const { opacity } = definition as { opacity: number };
            if (opacity === 0) {
              closeWithAnimation();
            }
          }}
          transition={{ type: 'tween', duration: 0.25 }}
          className={classNames(
            'fixed inset-0 bg-npl-transparent-black-50 !z-over-intercom',
            { 'backdrop-blur-md': blurBackground }
          )}
        />
      </LazyMotion>
    </div>
  );
};

export default NPLModalBackdrop;
