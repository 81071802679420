import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LexicalEditor } from 'lexical';
import { useEffect, useRef, useState } from 'react';

import { isSafari } from '@/utility/browserUtils';

import { VariableTypes } from '@/pages/portal/utils/common';

import EditorComponent, { EditorProps } from './EditorComponent';
import { EditorPlugin } from './constants';
import NodeList from './nodes/NodeList';
import type { VariableOption } from './plugins/VariablePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { sanitizeContentNodes } from './utils/common';

export type { VariableOption };
export { VariableTypes, EditorPlugin };

const Editor = ({
  existingContent,
  onChange,
  placeholderText = '',
  mentionedProducts,
  customContentClasses,
  imageIdentity,
  communityData,
  variables = [],
  isAutoFocus = true,
  editable = true,
  hideButtonPlugin = false,
  contentHTML = null,
  customBorderClass = '',
  showToolBarOnHover = false,
  customFocusClass = '',
  plugins = [],
  isLiveDataApplicable = false
}: EditorProps) => {
  const previousContentRef = useRef(existingContent);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (existingContent) {
      setIsInitialized(true);
    }
    if (!previousContentRef.current && existingContent) {
      previousContentRef.current = existingContent;
    }
  }, [existingContent]);

  // When !isInitialized, render pure HTML version of content
  if (!editable && contentHTML && !isInitialized) {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
      </>
    );
  }

  //if the editor component is mounted on the server, we should stop the rendering
  if (typeof window === 'undefined') {
    return null;
  }

  if (!isInitialized) {
    return null;
  }

  const getInitialEditorState = (editor: LexicalEditor) => {
    try {
      if (existingContent && editor) {
        const sanitizedContent = sanitizeContentNodes({
          content: existingContent,
          variables,
          isEditable: editable,
          mentionedProducts,
          isLiveDataApplicable,
          communityData
        });

        const editorState = editor.parseEditorState(sanitizedContent);
        editor.setEditorState(editorState);
        return editorState;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialConfig = {
    editorState: getInitialEditorState,
    namespace: 'primary-editor',
    editable: editable,
    nodes: [...NodeList],
    onError: (error: Error) => {
      console.error('Editor error:', error);
    },
    theme: PlaygroundEditorTheme,
    readOnly: !editable
  };

  const shouldShowToolBarOnHover = isSafari() ? false : showToolBarOnHover;

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorComponent
        onChange={onChange}
        placeholderText={placeholderText}
        customContentClasses={customContentClasses}
        imageIdentity={imageIdentity}
        hideButtonPlugin={hideButtonPlugin}
        variables={variables}
        isAutoFocus={isAutoFocus}
        customBorderClass={customBorderClass}
        showToolBarOnHover={shouldShowToolBarOnHover}
        customFocusClass={customFocusClass}
        communityData={communityData}
        plugins={plugins}
      />
    </LexicalComposer>
  );
};

export default Editor;
