import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode
} from '@lexical/react/LexicalDecoratorBlockNode';
import {
  DOMConversionMap,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey
} from 'lexical';

import type {
  MentionedCardLinkType,
  MentionedCardSizeType
} from '@/components/common/MentionedCard/types';

import { EditorCommunityDataType } from '../../types';
import MentionedProductNodeComponent from './components/MentionedProductNodeComponent';
import type {
  MentionedEntityDataType,
  MentionedProductNodeType
} from './types';
import { generateMentionedProductLinkByLinkType } from './utils';

export class MentionedProductNode extends DecoratorBlockNode {
  static getType() {
    return 'product';
  }

  __linkType: MentionedCardLinkType;
  __id: string;
  __size: MentionedCardSizeType;
  __metadata: MentionedEntityDataType;
  __link: string;
  __communityData: EditorCommunityDataType;

  static clone(node: MentionedProductNode): MentionedProductNode {
    return new MentionedProductNode(
      node.__linkType,
      node.__size,
      node.__metadata,
      node.__communityData,
      node.__link,
      node.__key
    );
  }

  constructor(
    linkType: MentionedCardLinkType,
    size: MentionedCardSizeType,
    metadata: MentionedEntityDataType,
    communityData: EditorCommunityDataType,
    link?: string,
    key?: NodeKey
  ) {
    super('center', key);
    this.__size = size;
    this.__linkType = linkType;
    this.__metadata = metadata;
    this.__link = communityData
      ? generateMentionedProductLinkByLinkType({
          linkType,
          communityData,
          entityData: metadata
        })
      : link;
    this.__communityData = communityData;
  }

  static importJSON(serializedNode: unknown) {
    const node = $createMentionedProductNode(
      serializedNode as MentionedProductNodeType
    );
    return node;
  }

  exportJSON(): SerializedDecoratorBlockNode & {
    link: string;
    linkType: MentionedCardLinkType;
    metadata: unknown;
    size: MentionedCardSizeType;
  } {
    return {
      ...super.exportJSON(),
      linkType: this.__linkType,
      size: this.__size,
      metadata: this.__metadata,
      link: this.__link,
      type: 'product',
      version: 1
    };
  }

  static importDOM(): DOMConversionMap<HTMLElement> {
    return {
      div: () => {
        return null;
      }
    };
  }

  exportDOM() {
    const element = document.createElement('div');
    element.setAttribute('data-lexical-product-id', this.__id);
    return { element };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    return dom;
  }

  updateDOM(): false {
    return false;
  }

  setSize(size: MentionedCardSizeType): void {
    const writable = this.getWritable();
    writable.__size = size;
  }

  setLinkType(linkType: MentionedCardLinkType): void {
    const writable = this.getWritable();
    writable.__linkType = linkType;
    writable.__link = generateMentionedProductLinkByLinkType({
      linkType,
      communityData: this.__communityData,
      entityData: this.__metadata
    });
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: _editor.isEditable() ? embedBlockTheme.focus || '' : ''
    };
    return (
      <MentionedProductNodeComponent
        linkType={this.__linkType}
        size={this.__size}
        entityData={this.__metadata}
        nodeKey={this.__key}
        className={className}
        link={this.__link}
      />
    );
  }

  isTopLevel() {
    return true;
  }
}

export function $createMentionedProductNode(
  params: MentionedProductNodeType
) {
  const { linkType, size, metadata, communityData, link } = params || {};

  return new MentionedProductNode(
    linkType ?? 'landingPage',
    size ?? 'lg',
    metadata,
    communityData,
    link
  );
}

export function $isMentionedProductNode(
  node: LexicalNode | null | undefined
) {
  return node instanceof MentionedProductNode;
}
