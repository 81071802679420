import type { LexicalEditor } from 'lexical';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import NPLButton from '@/components/npl/NPLButton';
import NPLInput from '@/components/npl/NPLInput/NPLInput';
import NPLModalNew from '@/components/npl/NPLModalNew';

import { checkIfUrlIsValid } from '@/pages/portal/utils/common';

import { INSERT_YOUTUBE_COMMAND } from '.';

type Props = {
  activeEditor: LexicalEditor;
  onClose: () => void;
};

const YoutubeEmbedModal: FC<Props> = ({ activeEditor, onClose }) => {
  const [link, setLink] = useState('');
  const [linkErrorMessage, setLinkErrorMessage] = useState('');

  useEffect(() => {
    if (link !== '' && linkErrorMessage !== '') {
      setLinkErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const onSubmit = () => {
    if (!link) {
      setLinkErrorMessage(t('link-is-required'));
      return;
    }

    if (link && !checkIfUrlIsValid(link)) {
      setLinkErrorMessage(t('invalid-url'));
      return;
    }

    if (link && link.includes('youtube.com/shorts/')) {
      setLinkErrorMessage(t('youtube-shorts-not-supported'));
      return;
    }

    let formattedLink = link;

    if (link.indexOf('https') === -1 && link.indexOf('http') === -1) {
      formattedLink = `https://${link}`;
    }

    activeEditor.dispatchCommand(INSERT_YOUTUBE_COMMAND, formattedLink);
    setLink('');
    onClose();
  };

  return (
    <NPLModalNew onClose={onClose}>
      {(onClose) => (
        <NPLModalNew.Container size={500}>
          <NPLModalNew.Header title={t('embed-youtube-link')} />
          <NPLModalNew.Content className="flex flex-col gap-16 items-stretch font-poppins">
            <NPLInput
              placeholder="https://www.youtube.com/watch?v=yIkguQzdVfQ&ab"
              testId="button-link"
              value={link}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setLink(e.target.value)
              }
              error={linkErrorMessage}
            />
          </NPLModalNew.Content>
          <NPLModalNew.Footer>
            <div className="flex flex-row justify-end gap-16">
              <NPLButton
                size="lg"
                onClick={onClose}
                hierarchy="outline"
                buttonText={t('cancel')}
              />
              <NPLButton
                size="lg"
                onClick={onSubmit}
                hierarchy="accent_primary"
                buttonText={t('embed-video')}
              />
            </div>
          </NPLModalNew.Footer>
        </NPLModalNew.Container>
      )}
    </NPLModalNew>
  );
};

export default YoutubeEmbedModal;
