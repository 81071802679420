import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@radix-ui/react-hover-card';
import classNames from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import PopOverModal from '../PopOverModal';

type Props = {
  contentElement: JSX.Element;
  triggerElement: JSX.Element;
  align?: 'start' | 'center' | 'end';
  closeDelay?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
};

const HoverCardComponent: FC<Props> = ({
  contentElement,
  triggerElement,
  align = 'start',
  side = 'bottom',
  sideOffset = 15,
  closeDelay = 300
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { isGtEqMd } = useWindowWidthContext();
  const [isCardHovered, setIsCardHovered] = useState(false);
  const hoverCardContentRef = useRef<HTMLDivElement>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout>();

  const closeContent = () => {
    setIsOpen(false);
    setTimeout(() => setIsMounted(false), 200);
  };

  const handleClose = () => {
    if (isCardHovered) {
      return;
    }
    // Add timeout for closing
    closeTimeoutRef.current = setTimeout(() => {
      closeContent();
    }, 100); // Small delay to allow moving to HoverCardContent
  };

  const handleOpen = () => {
    if (isMounted) {
      return;
    }
    // Clear any pending close timeout
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsMounted(true);
    setTimeout(() => setIsOpen(true), 0);
  };

  const handleContentEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsCardHovered(true);
  };

  const handleContentLeave = () => {
    setIsCardHovered(false);
    closeContent();
  };

  //this component is not allowed to be rendered on the server
  if (typeof window === 'undefined') {
    return null;
  }

  // Render for large screens
  if (isGtEqMd) {
    return (
      <HoverCard openDelay={0} closeDelay={closeDelay}>
        <HoverCardTrigger
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          asChild>
          {triggerElement}
        </HoverCardTrigger>
        {isMounted &&
          createPortal(
            <div
              className={classNames(
                'transition-all duration-300 ease-in-out z-over-intercom fixed',
                {
                  'opacity-100 translate-y-0': isOpen,
                  'opacity-0 translate-y-10': !isOpen
                }
              )}>
              <HoverCardContent
                className="min-w-[336px] bg-white-default rounded-12 shadow-npl-styles-shadow-01 p-8 mt-4"
                ref={hoverCardContentRef}
                sideOffset={sideOffset}
                align={align}
                side={side}
                onMouseEnter={handleContentEnter}
                onMouseLeave={handleContentLeave}
                collisionBoundary={document.body}
                collisionPadding={8}>
                {contentElement}
              </HoverCardContent>
            </div>,
            document.body
          )}
      </HoverCard>
    );
  }

  // Render for small screens
  return (
    <>
      <div onClick={() => setIsOpen(true)} className="w-full">
        {triggerElement}
      </div>
      {isOpen && (
        <PopOverModal
          customInnerClass="mt-40"
          onClose={() => setIsOpen(false)}
          open={isOpen}>
          {contentElement}
        </PopOverModal>
      )}
    </>
  );
};

export default HoverCardComponent;
