import config from '@/utility/config';

import { getAxiosErrorMsg } from './helpers';
import publicAxiosRequest from './helpers/publicAxiosRequest';
import unAuthorizedRequest from './helpers/unAuthorizedRequest';

const { communityApiBasePath } = config;

export const getCountries = async () => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/countries`;
    const { data, error } = await unAuthorizedRequest(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { data: data?.data, error: false };
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};

export const updateAccessedTranslationKeys = async (keys) => {
  const { data, error } = await publicAxiosRequest.post(
    `${communityApiBasePath}/api/v1/platform/localization/keys-accessed`,
    {
      keys
    }
  );
  return { data, error };
};
