import ServerHead from 'next/head';
import { useRouter } from 'next/router';
import ClientHead from 'react-helmet';

const Head = ({ children }) => {
  const router = useRouter();

  //this condition is to recognize the external layout calls
  const HeadComponent = router?.pathname?.startsWith('/external')
    ? ClientHead
    : ServerHead;

  return <HeadComponent>{children}</HeadComponent>;
};

export default Head;
