import { formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';

export const getMembershipIntervalText = (interval, intervalCount) => {
  return `${intervalCount > 1 ? `${intervalCount} ` : ''}${
    interval === 'year'
      ? t('year-0')
      : intervalCount > 1
        ? t('months-0')
        : t('month-0')
  }`;
};

export const getMembershipPriceString = ({
  discountDetails = null,
  amount,
  interval,
  currency = '',
  intervalCount
}) => {
  if (!(amount > 0)) {
    return t('free');
  }

  const intervalText = getMembershipIntervalText(interval, intervalCount);

  const intervalSeperator =
    intervalCount > 1 && interval !== 'year' ? ` ${t('every')} ` : '/';

  if (discountDetails) {
    switch (discountDetails?.discountType) {
      case 'FREE_TRIAL':
        return `${currency} ${formatNumber(
          amount,
          true,
          true
        )} /${intervalText}`;
      case 'INTERVAL':
      case 'FOREVER':
        return (
          <div>
            <span className="uppercase">{currency}</span>
            <span className="mx-4">
              {formatNumber(discountDetails?.discountedAmount, true, true)}
            </span>
            <span className=" text-npl-text-icon-on-light-surface-tertiary line-through">
              {formatNumber(discountDetails?.fullAmount, true, true)}
            </span>
            {intervalSeperator}
            {intervalText}
          </div>
        );
    }
  }
  return `${currency?.toUpperCase()} ${formatNumber(
    amount,
    true,
    true
  )}${intervalSeperator}${intervalText}`;
};

export const getDiscountedPrice = ({
  discountedCodeData,
  originalAmount
}) => {
  if (discountedCodeData && discountedCodeData?.type === 'percentage') {
    const { value } = discountedCodeData;

    const calculatedDiscountedPrice = (
      originalAmount *
      (1 - value / 100)
    ).toFixed(2);

    return calculatedDiscountedPrice;
  }
};

export const getDiscountPrice = ({
  discountedCodeData,
  originalAmount
}) => {
  if (
    (discountedCodeData && discountedCodeData?.type === 'percentage') ||
    discountedCodeData?.percentage
  ) {
    const { value, percentage } = discountedCodeData;
    const discountPercentage = value || percentage;

    const calculatedDiscountedPrice = (
      originalAmount *
      (discountPercentage / 100)
    ).toFixed(2);

    return calculatedDiscountedPrice;
  }
};
