import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { t } from '@/utility/localization';

import { prefixWithHttps } from '@/features/Announcements/Reaction/utils';

import { useAuthContext } from '@/contexts/AuthContext';

import CloseIcon from '@/components/common/Icons/CloseIcon';
import Icon from '@/components/npl/Icon';

import useReactionList from '@/hooks/useReactionList.js';

export const ReactionModal = ({
  type,
  onClose,
  communityId,
  postId,
  commentId,
  isLiked
}) => {
  const { loaderRef, userList, isLoading } = useReactionList({
    type,
    communityId,
    postId,
    commentId
  });

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderLoader = () => (
    <div className="mx-8 mb-8">
      {Array(3)
        .fill()
        .map((_, index) => (
          <div
            className="flex items-center gap-12 px-16 py-10"
            key={index}>
            <div className="h-[34px] w-[34px] animate-pulse rounded-full bg-npl-neutral-light-solid-6" />
            <div className="flex-grow space-y-6">
              <div className="h-14 w-full max-w-[200px] animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6" />
              <div className="h-14 w-full max-w-[70px] animate-pulse rounded-[6px] bg-npl-neutral-light-solid-6" />
            </div>
          </div>
        ))}
    </div>
  );

  // Prevent background scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const { user } = useAuthContext();
  const currentUser = user?.learner;

  return createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="fixed left-0 top-0 z-over-intercom flex h-full w-full items-end justify-center bg-black bg-opacity-50 font-poppins backdrop-blur-md md:items-center"
        onClick={handleBackdropClick}>
        <div className="shadow-npl-2 relative w-full rounded-t-28 bg-[#F5F5F5] md:w-[500px] md:rounded-12 md:bg-white-default">
          <div className="flex items-center px-24 py-20 md:border-b-1 md:border-npl-neutral-light-solid-6 md:px-16 md:py-16">
            <div className="flex flex-shrink-0 flex-grow items-center space-x-12">
              <div className="rounded-full bg-npl-error-light-4 p-4">
                <Icon
                  name="heart-filled"
                  fill="#E5484D"
                  width={12}
                  height={12}
                />
              </div>
              <p className="text-label-lg font-medium text-npl-text-icon-on-light-surface-primary">
                {t('liked-by')}
              </p>
            </div>
            <button
              className="flex cursor-pointer items-center justify-center rounded-full px-8 py-6 hover:bg-npl-neutral-light-solid-3"
              onClick={onClose}>
              <CloseIcon strokeColor="#1B1B18" width={18} height={18} />
            </button>
          </div>

          <div className="mx-8 mb-8 max-h-[350px] overflow-y-auto rounded-12 bg-white-default md:m-0  md:bg-transparent md:p-8">
            {!isLoading && isLiked && currentUser && (
              <div className="flex items-center gap-12 px-16 py-10">
                <img
                  src={currentUser?.profileImage}
                  alt="User profile"
                  className="h-[34px] w-[34px] rounded-full"
                />

                <div className="flex-shrink flex-grow">
                  <h5 className="text-npl-light-primary text-label-md font-medium">
                    {`${currentUser?.firstName ?? ''} ${
                      currentUser?.lastName ?? ''
                    }`}
                  </h5>
                  <h6 className="text-label-sm font-medium text-npl-text-icon-on-light-surface-tertiary text-opacity-50">
                    {currentUser?.country}
                  </h6>
                </div>
                <div className="flex flex-shrink-0 flex-grow-0 items-center gap-8">
                  {currentUser?.socialMedia
                    ?.filter((social) => social?.iconLink && social?.link)
                    ?.map((social) => (
                      <a
                        key={social?.link}
                        href={prefixWithHttps(social?.link)}
                        target="_blank"
                        rel="noreferrer noopener">
                        <img
                          src={social?.iconLink}
                          alt={social?.type}
                          className="h-16 w-16 opacity-50 hover:opacity-80"
                        />
                      </a>
                    ))}
                </div>
              </div>
            )}

            {userList.map((user, index) => (
              <div
                className="flex items-center gap-12 px-16 py-10"
                key={index}>
                <img
                  src={user.profileImage}
                  alt="User profile"
                  className="h-[34px] w-[34px] rounded-full"
                />

                <div className="flex-shrink flex-grow">
                  <h5 className="text-npl-light-primary text-label-md font-medium">
                    {`${user.firstName} ${user.lastName}`}
                  </h5>
                  <h6 className="text-label-sm font-medium text-npl-text-icon-on-light-surface-tertiary text-opacity-50">
                    {user.country}
                  </h6>
                </div>
                <div className="flex flex-shrink-0 flex-grow-0 items-center gap-8">
                  {user.socialMedia
                    ?.filter((social) => social.iconLink && social.link)
                    ?.map((social) => (
                      <a
                        key={social.link}
                        href={prefixWithHttps(social.link)}
                        target="_blank"
                        rel="noreferrer noopener">
                        <img
                          src={social.iconLink}
                          alt={social.type}
                          className="h-16 w-16 opacity-50 hover:opacity-80"
                        />
                      </a>
                    ))}
                </div>
              </div>
            ))}
            {userList?.length > 0 && <div ref={loaderRef} />}

            {isLoading && renderLoader()}
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};
