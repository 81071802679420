import React from 'react';

import { t } from '@/utility/localization';

import useFeedAnalytics from '@/features/Feed/hooks/useFeedAnalytics';
import PostActionBtnsRow from '@/features/Feed/pages/PostPublicPage/PostActionBtnsRow';

import NPLButton from '@/components/npl/NPLButton';

import usePostReaction from '@/hooks/usePostReaction';

import PostCardContent from '../PostCardContent/PostCardContent';
import { COMMUNITY_POSTS_STATUS } from '../constants';

const MemberCard = (props) => {
  const {
    communityId,
    goToSinglePostPage,
    feedConfig,
    communityLink,
    isLocked,
    isManager,
    isGtEqMd,
    user,
    onLockButtonClick,
    updateAnnouncementClientSide,
    communityData
  } = props || {};
  const announcement = props?.announcement || {};
  const {
    _id: postId,
    commentCount,
    totalImpressions,
    isPinned
  } = announcement || {};

  // check if announcement?.visibilityType is public or members
  const locked = isLocked && announcement?.visibilityType !== 'public';

  const { feedCardRef } = useFeedAnalytics({
    postId,
    canUserViewPost: true
  });

  const { isPostLiked, postLikeCount, handleReactionButtonClick } =
    usePostReaction({
      post: announcement,
      postType: 'post',
      communityId,
      postId,
      commentId: null,
      updateAnnouncementClientSide
    });

  return (
    <div className="group c-MemberCard">
      <div
        ref={feedCardRef}
        onClick={() => {
          if (locked) {
            onLockButtonClick?.();
          } else {
            goToSinglePostPage?.(announcement);
          }
        }}
        className="flex flex-col gap-16 p-16 rounded-16 overflow-hidden border-1 border-npl-neutral-light-alpha-4 group-hover:cursor-pointer group-hover:border-npl-neutral-light-solid-4 group-hover:bg-npl-neutral-light-solid-2 shadow-npl-styles-button-shadow bg-white-default">
        <PostCardContent
          {...props}
          communityData={communityData}
          communityLink={communityLink}
          showPostVisibilityType={false}
          showStatus={true}
        />

        {(announcement?.status === COMMUNITY_POSTS_STATUS.APPROVED ||
          isManager) && (
          <div className="flex items-center gap-2">
            <PostActionBtnsRow
              postCreationDateString={announcement?.createdAt}
              isPostLiked={isPostLiked}
              impressionCount={totalImpressions}
              hideViewCount={feedConfig?.hideViewsOnPost}
              commentCount={commentCount}
              likeCount={feedConfig?.hideLikesOnPost ? 0 : postLikeCount}
              onLikeClick={(e) => {
                e.stopPropagation();
                if (locked || !user) {
                  onLockButtonClick();
                  return;
                }
                handleReactionButtonClick();
              }}
              onCommentClick={(e) => {
                e.stopPropagation();

                if (locked || !user) {
                  onLockButtonClick();
                  return;
                }
                goToSinglePostPage(announcement);
              }}
              isCommunityAdmin={true}
              stretchButtons={false}
            />
          </div>
        )}
        {locked && !isPinned && (
          <div className="w-fit">
            <NPLButton
              hierarchy="neutral_secondary"
              size="md"
              stretch={!isGtEqMd}
              leadIcon="lock-01"
              buttonText={t('join-to-unlock')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onLockButtonClick();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberCard;
