import classNames from 'classnames';
import React, { useState } from 'react';

import { t } from '@/utility/localization';

import PostActionButton from '@/features/Feed/pages/PostPublicPage/PostActionButton';
import ProfilePopUpWrapper from '@/features/ProfilePopUp/ProfilePopUpWrapper';

import LinkAwareText from '@/components/common/LinkAwareText';
import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import usePostReaction from '@/hooks/usePostReaction';

import CommentCardLoading from '../CommentCardLoading';
import CommentDeleteModal from '../CommentDeleteModal';
import { ReactionModal } from '../Reaction/ReactionModal';
import ReplyCard from '../ReplyCard';
import CommentHeaderSection from './CommentHeaderSection';

const CommentCard = ({
  comment = {},
  post = {},
  user,
  communityId,
  postId,
  onCommentDelete,
  deleteLoadingMap,
  fetchReplies,
  getReplyInfo,
  fetchMoreReplies,
  onReplyDelete,
  updateAnnouncementInfo,
  allowReaction,
  allowReply,
  allowDelete,
  isCommunityAdmin,
  setReplyingToCommentData
}) => {
  const {
    _id: commentId,
    profileImage,
    firstName,
    lastName,
    createdAt,
    content
  } = comment;

  const {
    isPostLiked: isCommentLiked,
    postLikeCount: commentLikeCount,
    handleReactionButtonClick
  } = usePostReaction({
    post: comment,
    postType: 'comment',
    communityId,
    postId,
    commentId
  });
  const [isReactionModalVisible, setIsReactionModalVisible] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const selectedReply = getReplyInfo({ commentId });
  const replyData = selectedReply?.replyInfo;
  const replyList = selectedReply?.replyList ?? [];
  const isReplyLoading = selectedReply?.isReplyLoading;
  const commentCount = post?.commentCount ?? 0;
  const replyCount = comment?.replyCount ?? 0;

  const openReplyInput = ({ parentComment }) => {
    setReplyingToCommentData(parentComment || {});
    if (replyCount > 0 && replyList?.length === 0)
      fetchReplies({ commentId, postId });
  };

  const renderReplyList = () => {
    return replyList?.map((reply) => {
      const isReplyOwner = reply?.learnerObjectId === user?.learner?._id;
      return (
        <ReplyCard
          key={reply?._id}
          reply={reply}
          communityId={communityId}
          postId={postId}
          commentId={commentId}
          deleteLoadingMap={deleteLoadingMap}
          openReplyInput={openReplyInput}
          onReplyDelete={onReplyDelete}
          updateAnnouncementInfo={updateAnnouncementInfo}
          post={post}
          allowReaction={allowReaction}
          allowReply={allowReply}
          allowDelete={isReplyOwner || isCommunityAdmin}
        />
      );
    });
  };

  return (
    <div className="flex space-x-8 py-8">
      <div className="h-32 w-32 rounded-full">
        <ProfilePopUpWrapper
          communityId={communityId}
          learnerId={comment?.learnerObjectId}>
          <div className="h-32 w-32 rounded-full">
            <NextImage
              alt="user-avatar"
              mobileImgProps={{
                src: profileImage,
                width: 32,
                height: 32
              }}
              className="rounded-full h-32 w-32"
            />
          </div>
        </ProfilePopUpWrapper>
      </div>
      <div className="flex-1 w-full overflow-hidden">
        <div className="rounded-12 bg-npl-neutral-light-solid-2 ">
          <CommentHeaderSection
            firstName={firstName}
            lastName={lastName}
            createdAt={createdAt}
            allowDelete={allowDelete}
            onDeleteClick={() => setIsDeleteModalOpen(true)}
            communityId={communityId}
            learnerObjectId={comment?.learnerObjectId}
          />
          <div className="px-12 pb-8 break-words whitespace-pre-line text-body-sm text-npl-text-icon-on-light-surface-primary">
            <LinkAwareText text={content} />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            {allowReaction && (
              <>
                {isCommentLiked && (
                  <PostActionButton
                    icon="heart-filled"
                    iconColor={'#E5484D'}
                    onClick={handleReactionButtonClick}
                    size={16}
                    title={commentLikeCount > 0 ? commentLikeCount : ''}
                  />
                )}
                {!isCommentLiked && (
                  <PostActionButton
                    icon={'heart'}
                    size={16}
                    iconColor={'#1B1B18A6'}
                    onClick={handleReactionButtonClick}
                    title={commentLikeCount > 0 ? commentLikeCount : ''}
                  />
                )}
              </>
            )}
          </div>
          <div className="flex items-center">
            {allowReply && (
              <PostActionButton
                icon="message-text-square-02"
                size={16}
                iconColor="#1B1B18A6"
                onClick={() => openReplyInput({ parentComment: comment })}
              />
            )}
          </div>
        </div>
        {comment.replyCount > 0 && (
          <div className="flex gap-4 items-center pb-8">
            <div className="h-[1px] bg-npl-neutral-light-solid-6 w-32"></div>
            <NPLButton
              size="xs"
              hierarchy="plain"
              buttonText={`${t('view')} ${replyCount} ${
                replyCount > 1 ? t('replies') : t('reply1')
              }`}
              onClick={() => fetchReplies({ commentId, postId })}
            />
          </div>
        )}
        <div
          className={classNames(
            replyData?.areRepliesExpanded || !replyList?.length
              ? ''
              : 'h-0 overflow-hidden'
          )}>
          <div className="space-y-[4px]">
            {renderReplyList()}
            {isReplyLoading && <CommentCardLoading />}
          </div>

          {replyData?.hasNextPage && !isReplyLoading && (
            <div className="flex px-16">
              <div
                role="button"
                tabIndex={0}
                className="mb-12 flex cursor-pointer items-center space-x-8 rounded-full px-12 py-6 hover:bg-npl-neutral-light-solid-3"
                onClick={() => {
                  if (!isReplyLoading)
                    fetchMoreReplies({ postId, commentId });
                }}>
                <p className="text-button-md font-medium text-npl-text-icon-on-light-surface-primary">
                  {t('show-more-replies')}
                </p>
                <Icon name="chevron-down" />
              </div>
            </div>
          )}
        </div>
      </div>

      {isReactionModalVisible && (
        <ReactionModal
          type="comment"
          isLiked={isCommentLiked}
          communityId={communityId}
          postId={postId}
          commentId={commentId}
          onClose={() => setIsReactionModalVisible(false)}
        />
      )}

      <CommentDeleteModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        isLoading={deleteLoadingMap?.[commentId]}
        onSubmit={() =>
          onCommentDelete({
            postId,
            commentId,
            onSuccess: () => {
              updateAnnouncementInfo({
                post,
                updatedInfo: {
                  commentCount: commentCount - 1
                }
              });
              setIsDeleteModalOpen(false);
            }
          })
        }
      />
    </div>
  );
};

export default CommentCard;
