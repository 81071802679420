import { communityApplicationStatus } from '@/data/communities';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Children, useCallback, useState } from 'react';

import { IS_DEV_ENV } from '@/utility/constants';
import { t } from '@/utility/localization';

import ClampText from '@/components/common/ClampText';
import NextImage from '@/components/common/NextImage';
import NPLBadge from '@/components/npl/NPLBadge';

import SearchBox from '../SearchBox/SearchBox';

const className = 'c-CommunityDropdown';

const CommunityDropdown = ({
  communities = [],
  showCommunityDropdown = false,
  setShowCommunityDropdown,
  setActiveCommunity,
  // for new use to identify current selected community
  activeCommunityId
}) => {
  const [search, setSearch] = useState('');
  const hideList =
    !communities.length ||
    (communities.length === 1 &&
      activeCommunityId === communities[0]?._id); // hide list if user has only joined 1 community and is currently viewing that community.

  const renderCommunityDropdownItem = useCallback(
    (community, index) => {
      const { _id, title, thumbnailImgData, isManager, subscription } =
        community;

      // const isActive = activeCommunity?.code === code;
      const isActive = activeCommunityId === _id;
      if (isActive) return null;

      const navigateToAnotherCommunity = (e) => {
        e.preventDefault();

        setShowCommunityDropdown(false);

        setActiveCommunity(community);
      };

      return (
        <div key={index} className="relative px-8">
          <div
            role="button"
            tabIndex={0}
            className={classNames(
              'flex cursor-pointer transition-all items-center space-x-12 rounded-8 p-8 mix-blend-multiply hover:bg-npl-neutral-light-alpha-3',
              { 'bg-npl-yellow-light-solid-3': isActive }
            )}
            onClick={navigateToAnotherCommunity}>
            <div className="h-40 w-40 flex-shrink-0 rounded-8">
              {thumbnailImgData?.mobileImgData?.src && (
                <NextImage
                  {...{
                    mobileImgProps: {
                      ...thumbnailImgData?.mobileImgData,
                      width: 40,
                      height: 40,
                      loading: 'lazy',
                      objectFit: 'cover'
                    },
                    desktopImgProps: {
                      ...thumbnailImgData?.desktopImgData,
                      width: 40,
                      height: 40,
                      loading: 'lazy',
                      objectFit: 'cover'
                    }
                  }}
                  className={
                    'aspect-square w-40 rounded-8 bg-cover bg-center object-cover'
                  }
                />
              )}
            </div>
            <div className="flex flex-col flex-wrap items-start">
              <ClampText
                text={title}
                line={2}
                hideCTA={true}
                customClassNames={
                  'font-semibold text-neutral-10 text-label-md'
                }
                disableLinkAware={true}
              />
              <div className="mt-4 flex space-x-8 items-center">
                <span
                  className={classNames(
                    `text-label-sm text-npl-text-icon-on-light-surface-secondary`,
                    {
                      'rounded-4 bg-npl-neutral-light-solid-5 px-4 py-2':
                        isManager
                    }
                  )}>
                  {!isManager ? t('member') : t('manager1')}
                </span>
                {subscription?.onGracePeriod && (
                  <NPLBadge
                    leadIcon="alert-circle"
                    text={t('renew')}
                    type="accent"
                    size="sm"
                  />
                )}
                {subscription?.status ===
                  communityApplicationStatus.pending && (
                  <NPLBadge
                    text={t('pending-approval')}
                    type="orange"
                    size="sm"
                  />
                )}
              </div>
            </div>
          </div>
          {isActive && (
            <div className="absolute left-0 top-0 h-full w-[3px] rounded-b-4 rounded-r-4 bg-npl-yellow-light-solid-9"></div>
          )}
        </div>
      );
    },
    [activeCommunityId, setActiveCommunity, setShowCommunityDropdown]
  );

  const renderCommunityDropdown = useCallback(() => {
    const itemsToRender = Array.isArray(communities)
      ? Children.toArray(
          communities
            ?.filter((f) => {
              // Regex to match title
              const regex = new RegExp(search, 'gi');
              return f?.title?.match(regex);
            })
            .map(renderCommunityDropdownItem)
        )
      : null;

    if (!showCommunityDropdown || !Array.isArray(communities)) return null;

    return (
      <div className="flex flex-col items-stretch rounded-12">
        {communities.length > 5 && IS_DEV_ENV && (
          <div className="mx-12 my-4">
            <SearchBox
              initialValue=""
              onChange={setSearch}
              placeholder="Search for communities"
            />
          </div>
        )}

        {itemsToRender}
      </div>
    );
  }, [
    communities,
    renderCommunityDropdownItem,
    search,
    showCommunityDropdown
  ]);

  return (
    <div
      className={classNames(
        className,
        'flex flex-col rounded-12 bg-npl-base-white'
      )}>
      {!hideList && (
        <div className="mx-16 my-8 border-t-1 border-npl-neutral-light-solid-6" />
      )}

      {!hideList && renderCommunityDropdown()}
    </div>
  );
};

CommunityDropdown.propTypes = {
  communities: PropTypes.arrayOf(PropTypes.object),
  showCommunityDropdown: PropTypes.bool,
  setShowCommunityDropdown: PropTypes.func,
  onDiscoveryMoreClick: PropTypes.func
};

export default CommunityDropdown;
