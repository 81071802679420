import config from '@/utility/config';
import { ENTITY_SLUGS_PREFIX, openInNewTab } from '@/utility/helpers';

import { CHECKOUT_ORIGIN_TYPE } from '@/features/CommunitySignupModal/constants';
import { SIGNUP_REQUESTOR } from '@/features/SignUpPopup/constants';

import { revalidateLandingPage } from '@/services/community/communitiesSettingsService';
import { convertObjectToQueryString } from '@/services/helpers/queryString';

import { TABS_ID } from '@/components/features/CommunityPage/constants';

import {
  ACTION_CREATE_BUNDLE,
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY,
  CHECKOUT_TYPE_COURSE
} from './checkoutConstants';
import { COMMUNITY_ID_KEY } from './constants';

export const getCheckoutRoute = ({
  type,
  courseOfferingId,
  step = 'enroll',
  communitySlug,
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  bundleCourses,
  autoCreateBundle,
  interval = 'month',
  intervalCount = 1,
  actionType,
  sourceInfo,
  requestor = SIGNUP_REQUESTOR,
  discountCode,
  entityDiscountCode
}) => {
  switch (type) {
    case CHECKOUT_TYPE_COURSE:
      if (!courseOfferingId) return '/';

      return `/checkout?type=${type}&courseOfferingId=${courseOfferingId}&step=${step}`;
    case CHECKOUT_TYPE_COMMUNITY: {
      if (!courseOfferingId) return '/';
      let query = {
        type,
        communityCode: courseOfferingId, // Course offering Id is community Code
        step,
        interval,
        intervalCount,
        slug: communitySlug,
        requestor
      };

      if (actionType) query.actionType = actionType;
      if (sourceInfo?.type) query.sourceInfoType = sourceInfo.type;
      if (sourceInfo?.origin) query.sourceInfoOrigin = sourceInfo.origin;
      if (discountCode) query.discountCode = discountCode;
      if (entityDiscountCode)
        query.entityDiscountCode = entityDiscountCode;

      const queryStr = convertObjectToQueryString(query);
      return `/checkout-global?${queryStr}`;
    }

    case CHECKOUT_TYPE_BUNDLE:
      if (!bundleSlug) return '/';

      if (autoCreateBundle) {
        const coursesList = bundleCourses?.join(',');

        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=checkout&step=${step}&autoCreateBundle=1&bundleCourses=${coursesList}`;
      }

      if (bundleCourses) {
        const coursesList = bundleCourses?.join(',');
        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=${bundleAction}&step=${step}&bundleCourses=${coursesList}`;
      } else {
        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=${bundleAction}&step=${step}`;
      }

    default:
      return '/';
  }
};

export const getCourseSignupRoute = (
  courseOfferingId,
  step = 'enroll'
) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_COURSE,
    courseOfferingId,
    step
  });
};

export const getCommunityCheckoutRoute = ({
  communityId,
  communityCode,
  // step = 'enroll',
  // communitySlug,
  actionType = '', // TODO - @Aman - Deprecate this. Remove useage on global-checkout
  sourceInfo = null,
  requestor = SIGNUP_REQUESTOR,
  discountCode = null,
  entityDiscountCode = null,
  fromWidget = null,
  renewal = null,
  upsellIdentityCode = null,
  isFullUrl = false,
  affiliateCode = '',
  withJoinAsAffiliateCb = false,
  email = '',
  cancelExistingSubscription = '',
  recipientObjectId = '',
  hasNoLinkClicked = false
}) => {
  if (!communityCode || !communityId) return '/';
  let query = {
    communityId,
    communityCode,
    requestor
  };

  if (recipientObjectId) query.recipientObjectId = recipientObjectId;

  if (!hasNoLinkClicked) {
    query.linkClicked = window.location.href;
  }

  if (actionType) query.actionType = actionType;
  if (sourceInfo?.type) query.sourceInfoType = sourceInfo.type;
  if (sourceInfo?.origin) query.sourceInfoOrigin = sourceInfo.origin;

  if (sourceInfo?.type === CHECKOUT_ORIGIN_TYPE.EVENT) {
    if (sourceInfo?.metadata?.ticketCount) {
      query.ticketCount = sourceInfo.metadata.ticketCount;
    }
  }

  if (sourceInfo?.type === CHECKOUT_ORIGIN_TYPE.ONE_ON_ONE_SESSION) {
    if (sourceInfo?.metadata?.sessionStartTime)
      query.sessionStartTime = sourceInfo.metadata.sessionStartTime;
    if (sourceInfo?.metadata?.sessionEndTime)
      query.sessionEndTime = sourceInfo.metadata.sessionEndTime;
    if (sourceInfo?.metadata?.sessionTz)
      query.sessionTz = sourceInfo.metadata.sessionTz;
  }
  if (discountCode) query.discountCode = discountCode;
  if (entityDiscountCode) query.entityDiscountCode = entityDiscountCode;
  if (upsellIdentityCode) query.upsellIdentityCode = upsellIdentityCode;
  if (fromWidget) query.fromWidget = 1;
  if (renewal) query.renewal = true;

  if (affiliateCode) query.affiliateCode = affiliateCode;

  if (withJoinAsAffiliateCb) query.withJoinAsAffiliateIntent = 1;
  if (email) query.email = email;
  if (cancelExistingSubscription) query.cancelExistingSubscription = 1;

  const queryStr = convertObjectToQueryString(query);

  const pathname = `/checkout-global?${queryStr}`;

  if (isFullUrl) {
    const fullUrl = `${
      typeof window !== 'undefined'
        ? window.location.origin
        : config.siteBaseUrl
    }${pathname}`;
    return fullUrl;
  }

  return pathname;
};

export const getBundleCheckoutRoute = ({
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  step = 'enroll',
  bundleCourses,
  autoCreateBundle
}) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_BUNDLE,
    bundleSlug,
    bundleAction,
    step,
    bundleCourses,
    autoCreateBundle
  });
};

//converting query object to query string
export const getQueryStringFromObject = ({ queryParams = {} }) => {
  try {
    const paramKeys = Object.keys(queryParams);
    if (paramKeys.length === 0) return '';
    return paramKeys.map((key) => `${key}=${queryParams[key]}`).join('&');
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const MEMBER_PORTAL_BASED_PATH = '/member';
export const USER_BASED_PATH = '/user';
export const PAYMENT_METHOD = '/payment-method';

export const MOBILE_WEB_BASED_PATH = '/mb/';

export const MEMBER_PORTAL_DEFAULT_PAGE_ROUTE = MEMBER_PORTAL_BASED_PATH;

export const MEMBER_ROUTES = {
  MEMBERS: `${MEMBER_PORTAL_BASED_PATH}/members`,
  EVENTS: `${MEMBER_PORTAL_BASED_PATH}/events`,
  CONTENT: `${MEMBER_PORTAL_BASED_PATH}/products`,
  FEED: `${MEMBER_PORTAL_BASED_PATH}/feed`
};

export const REROUTE_USER_ROUTE = 'reroute-user';
export const CM_PORTAL_HOMEPAGE_ROUTE = '/portal';

export const START_COMMUNITY_ONBOARDING_ROUTE = '/start-community';
export const LOGIN_ROUTE = '/login';
export const FORGET_PASSWORD_ROUTE = '/forget-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const CMP_EMAIL_PREFERENCE_PAGE =
  '/portal/settings?tab=email_preferences';
export const PRODUCT_DEMO_PAGE_ROUTE = '/product-demo';
export const startACommunityRoute = '/start-community';
export const homepageJoinACommunityHashRoute = 'join-a-community';
export const PAYOUT_FORM_ROUTE = '/portal/money/payout-form';
export const settingsAddDiscountCodePageRoute =
  '/portal/settings/discounts/add';
export const CREATE_MAGIC_REACH_ROUTE = '/portal/magic-reach/new';
export const CM_PORTAL_GETTING_STARTED_ROUTE = '/portal/get-started';
export const CM_PORTAL_HOME_ROUTE = '/portal/home';
export const CM_PORTAL_EVENTS_ROUTE = '/portal/events';
export const CM_PORTAL_ADD_NEW_EVENT_ROUTE = '/portal/events/add';
export const CM_PORTAL_MONEY_PAGE_ROUTE = '/portal/money';
export const CM_PORTAL_MAGIC_REACH_PAGE_ROUTE = '/portal/magic-reach';
export const CM_PORTAL_LIBRARY_PAGE_ROUTE = '/portal/products';
export const CM_PORTAL_SETTINGS_PAGE_ROUTE = '/portal/settings';
export const CM_PORTAL_ANALYTICS_PAGE_ROUTE = '/portal/analytics';
export const CM_PORTAL_PUBLIC_PAGE_ROUTE = '/portal/settings'; //the public page is the first tab of the setting page
export const CM_PORTAL_MEMBERS_PAGE_ROUTE = '/portal/members';
export const CM_PORTAL_CHALLENGES_PAGE_ROUTE = '/portal/challenges';
export const CM_PORTAL_CHALLENGES_NEW_PAGE_ROUTE =
  '/portal/challenges/new';
export const CM_PORTAL_FEED_PAGE_ROUTE = '/portal/feed';
export const CM_PORTAL_PAID_ADS_PAGE_ROUTE = '/portal/paid-ads';
export const CM_PORTAL_AFFILIATES_PAGE_ROUTE = '/portal/affiliates';
export const CM_PORTAL_PROMOTIONS_PAGE_ROUTE = '/portal/promotions';
export const CM_PORTAL_REFERRAL_PAGE_ROUTE = '/portal/referral';
export const CM_PORTAL_ADD_NEW_SESSION =
  '/portal/products/1on1session/add';
export const CM_PORTAL_EDIT_NEW_SESSION =
  '/portal/products/1on1session/edit';

export const EVENTS_FEATURE_PAGE_ROUTE = '/features/live-events';
export const MONETIZATION_FEATURE_PAGE_ROUTE = '/features/monetization';
export const MAGIC_REACH_FEATURE_PAGE_ROUTE = '/features/magic-reach';
export const CONTENT_LIB_FEATURE_PAGE_ROUTE = '/features/content-library';
export const CHAT_INTEGRATION_FEATURE_PAGE_ROUTE =
  '/features/chat-integrations';
export const PUBLIC_PAGE_FEATURE_PAGE_ROUTE = '/features/public-page';

export const MEMBER_AFFILIATE_PRODUCTS_ROUTE = '/user/affiliate/products';
export const MEMBER_AFFILIATE_PAYOUT_ROUTE = '/user/affiliate/payout';

export function getDuplicateEventPageRoute(duplicateFromEventId) {
  return `${CM_PORTAL_ADD_NEW_EVENT_ROUTE}?duplicateFromEventId=${duplicateFromEventId}`;
}

export function getDuplicateSessionPageRoute(duplicateFromSessionId) {
  return `${CM_PORTAL_ADD_NEW_SESSION}?duplicateFromSessionId=${duplicateFromSessionId}`;
}

export const getDiscountCodeFromUrlParams = (currentRouterQuery) => {
  return currentRouterQuery?.discountCode ?? '';
};

export const getCreatePaidEventRoute = () => {
  return `${CM_PORTAL_ADD_NEW_EVENT_ROUTE}?access=paid`;
};

export const getSettingsPageChatTabRoute = () => {
  return `${CM_PORTAL_SETTINGS_PAGE_ROUTE}?tab=chat`;
};

export const getMembershipSettingsRoute = (subscriptionId) => {
  return `${USER_BASED_PATH}/membership/${subscriptionId}`;
};

export const getChangePaymentMethodRoute = (
  subscriptionId,
  isDirectCharge
) => {
  return `/payment-method/${subscriptionId}${
    isDirectCharge ? '?directCharge=true' : ''
  }`;
};

export const getMemberPortalSinglePostPageLink = (
  postId,
  communityId,
  queryParams = {}
) => {
  try {
    const memberPortalRoute = MEMBER_PORTAL_BASED_PATH;
    const extendedQueryParams = {
      ...queryParams,
      activeCommunityId: communityId
    };
    const queryStr = extendedQueryParams
      ? convertObjectToQueryString(extendedQueryParams)
      : '';
    if (!postId || !communityId) return `${memberPortalRoute}?${queryStr}`;

    return `${MEMBER_ROUTES.FEED}/${postId}?${queryStr}`;
  } catch (e) {
    return '';
  }
};

export const getCmpFolderPageRoute = ({ folderId, communityId }) => {
  const queryStr = communityId ? `?activeCommunityId=${communityId}` : '';
  return `${CM_PORTAL_LIBRARY_PAGE_ROUTE}/${folderId}${queryStr}`;
};

export const openAppDownloadPage = () => {
  const link = `${config.siteBaseUrl}/install`;
  openInNewTab(link);
};

export const checkIsCtaToStartCommunity = (ctaUrl) => {
  return ctaUrl === '/start-community';
};

export const goToCmpHomeWithNewActiveCommunityId = (newCommunityId) => {
  try {
    // Create query string with newCommunityId as activeCommunityId
    const searchString = convertObjectToQueryString({
      [COMMUNITY_ID_KEY]: newCommunityId
    });

    const cmpHomePageFullPath = `${window.location.origin}${CM_PORTAL_HOMEPAGE_ROUTE}`;

    window.location.href = `${cmpHomePageFullPath}?${searchString}`; // Output Eg: nas.io/porta?activeCommunityId=123
  } catch (e) {
    window.location.reload();
  }
};

export function getFolderAudiencePageRoute(folderId) {
  return `${CM_PORTAL_LIBRARY_PAGE_ROUTE}/${folderId}?tab=audience`;
}

export const getEntityPublicPageUrl = ({
  communitySlug = '',
  entityType = '',
  entitySlug = '',
  fullUrl = false
}) => {
  const entityTypeWithoutSlash = entityType
    .replace(/\//g, '')
    .toLocaleLowerCase();
  const communitySlugWithoutSlash = communitySlug
    .replace(/\//g, '')
    .toLocaleLowerCase();
  const entitySlugWithoutSlash = entitySlug
    .replace(/\//g, '')
    .toLocaleLowerCase();

  const relativeRoute = `/${communitySlugWithoutSlash}/${entityTypeWithoutSlash}/${entitySlugWithoutSlash}`;

  if (fullUrl) {
    return `${typeof window !== 'undefined' ? window.location.origin : config.siteBaseUrl}${relativeRoute}`;
  }

  return relativeRoute;
};

export const getDigitalProductAccessPageLink = ({
  communitySlug,
  productSlug,
  fullUrl = false,
  hasAccess = true
}) => {
  const communitySlugWithoutSlash = communitySlug.replace(/\//g, '');
  const productSlugWithoutSlash = productSlug.replace(/\//g, '');

  const relativeLink = `/${communitySlugWithoutSlash}/${
    ENTITY_SLUGS_PREFIX.PRODUCTS
  }/${productSlugWithoutSlash}${hasAccess ? '/view' : ''}`;
  if (fullUrl) {
    return `${config.siteBaseUrl}${relativeLink}`;
  }

  return relativeLink;
};

export const getFolderItemPreviewLink = ({
  communitySlug,
  productSlug,
  folderItemId,
  sectionItemId,
  fullUrl = false
}) => {
  let path = getDigitalProductAccessPageLink({
    communitySlug,
    productSlug,
    fullUrl
  });

  path = `${path}?preview=${folderItemId}${
    sectionItemId ? `&section=${sectionItemId}` : ''
  }`;
  return path;
};

export const resolvePublicPageSlug = (
  communityLink,
  slug,
  entityTypeSlug
) => {
  const communityLinkWithoutSlash = communityLink.replace(/\//g, '');
  const slugWithoutSlash = slug.replace(/\//g, '');
  return getEntityPublicPageUrl({
    communitySlug: communityLinkWithoutSlash,
    entityType: entityTypeSlug,
    entitySlug: slugWithoutSlash,
    fullUrl: true
  });
};

export const get1on1SessionLandingPageRoute = ({
  communityData,
  oneOnOneSessionData
}) => {
  const communityLink = communityData?.link;
  const oneOnOneSessionSlug = oneOnOneSessionData?.resourceSlug;
  return getEntityPublicPageUrl({
    communitySlug: communityLink,
    entityType: ENTITY_SLUGS_PREFIX.PRODUCTS,
    entitySlug: oneOnOneSessionSlug,
    fullUrl: false
  });
};

export const get1on1SessionLandingPageFullRoute = ({
  communityData,
  oneOnOneSessionData
}) => {
  const communityLink = communityData?.link;
  const oneOnOneSessionSlug = oneOnOneSessionData?.resourceSlug;
  return getEntityPublicPageUrl({
    communitySlug: communityLink,
    entityType: ENTITY_SLUGS_PREFIX.PRODUCTS,
    entitySlug: oneOnOneSessionSlug,
    fullUrl: true
  });
};

export const getChallengeDetailRoute = (challengeId) => {
  return `${CM_PORTAL_CHALLENGES_PAGE_ROUTE}/${challengeId}`;
};

export const getChallengeListPublicPage = (communitySlug) => {
  return `${
    typeof window === 'undefined'
      ? config.siteBaseUrl
      : window.location.origin
  }${communitySlug}/challenges`;
};

export const getChallengePublicPageRoute = (
  communitySlug,
  challengeSlug
) => {
  return getEntityPublicPageUrl({
    communitySlug,
    entityType: ENTITY_SLUGS_PREFIX.CHALLENGES,
    entitySlug: challengeSlug,
    fullUrl: true
  });
};

export const getChallengeParticipantPageRoute = ({
  communitySlug,
  challengeSlug
}) => {
  let route = getChallengePublicPageRoute(communitySlug, challengeSlug);

  return `${route}/home`;
};

export const getCommunityPageTabRoute = ({
  communitySlug,
  tabId,
  fullUrl = false
}) => {
  const communityLinkWithoutSlash = communitySlug.replace(/\//g, '');

  let relativeLink = '';
  switch (tabId) {
    case TABS_ID.HOME: {
      relativeLink = `/${communityLinkWithoutSlash}/home`;
      break;
    }
    case TABS_ID.FEED: {
      relativeLink = `/${communityLinkWithoutSlash}/feed`;
      break;
    }
    case TABS_ID.PRODUCTS: {
      relativeLink = `/${communityLinkWithoutSlash}/products`;
      break;
    }
    case TABS_ID.CHALLENGES: {
      relativeLink = `/${communityLinkWithoutSlash}/challenges`;
      break;
    }
    case TABS_ID.EVENTS: {
      relativeLink = `/${communityLinkWithoutSlash}/events`;
      break;
    }
    case TABS_ID.COMMUNITY: {
      relativeLink = `/${communityLinkWithoutSlash}/community`;
      break;
    }
    case TABS_ID.ABOUT: {
      relativeLink = `/${communityLinkWithoutSlash}/about`;
      break;
    }
    default: {
      relativeLink = `/${communityLinkWithoutSlash}`;
    }
  }

  if (fullUrl) {
    return `${config.siteBaseUrl}${relativeLink}`;
  }

  return relativeLink;
};

export const getMemberCommunityPageRoute = (
  communitySlug,
  tab = TABS_ID.HOME
) => {
  return getCommunityPageTabRoute({
    communitySlug,
    tabId: tab
  });
};

export const ENTITY_TYPE_SLUG = {
  FOLDER: '/products',
  EVENT: '/events',
  SESSION: '/products',
  CHALLENGE: '/challenges'
};

export const getRedirectToLink = ({
  routeTo,
  redirectTo,
  activeCommunityId = ''
}) => {
  if (redirectTo.includes('activeCommunity') || !activeCommunityId) {
    return `/${routeTo}?redirectTo=${redirectTo}`;
  }
  return `/${routeTo}?redirectTo=${redirectTo}&activeCommunity=${activeCommunityId}`;
};

export const revalidateEntityLandingPage = async ({
  communitySlug,
  entitySlug,
  entityType
}) => {
  const pathToRevalidate = getEntityPublicPageUrl({
    communitySlug,
    entityType,
    entitySlug,
    fullUrl: false
  });

  return await revalidateLandingPage({
    payload: {
      slug: pathToRevalidate
    }
  });
};

export const redirectSafelyOnAllBrowsers = (link) => {
  setTimeout(() => {
    window.location.href = link;
  }, 250);
};

export const addQueryParamsToPath = (path, queryObject) => {
  const queryParams = new URLSearchParams(queryObject);
  const queryString = queryParams.toString();
  return queryString ? `${path}?${queryString}` : path;
};
