import * as yup from 'yup';

import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';

export const CheckoutStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const CheckoutTypes = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  EVENT: 'EVENT',
  FOLDER: 'FOLDER',
  SESSION: 'SESSION',
  CHALLENGE: 'CHALLENGE'
};

export const CheckoutSteps = {
  AUTH: 'AUTH',
  MEMBERSHIP: 'MEMBERSHIP',
  ENTITY: 'ENTITY',
  // TODO - @AmanMinhas Check need for following
  INFORMATION: 'INFORMATION',
  COMPLETE: 'COMPLETE'
};

export const ENTITY_TYPE = {
  EVENT: 'event',
  FOLDER: 'folder',
  SESSION: 'session',
  CHALLENGE: 'challenge',
  POST: 'post',
  SUBSCRIPTION: 'subscription',
  COMMUNITY: 'community'
};

export const stripeElementsOptions = {
  paymentMethodOrder: ['upi', 'card', 'apple_pay', 'google_pay'],
  terms: { card: 'always' },
  layout: {
    type: 'accordion',
    radios: true,
    spacedAccordionItems: true
  }
};

export const PAYMENT_METHOD_TYPES = {
  PAYPAL: 'paypal',
  SAVED_METHOD: 'saved_method',
  STRIPE: 'stripe',
  STRIPE_INDIA: 'stripe-india',
  XENDIT: 'xendit',
  RAZORPAY: 'razorpay',
  EBANX: 'ebanx'
};

export const getPaymentProvidersDefaultId = (
  method,
  isMembershipCheckout
) => {
  switch (method?.paymentProvider) {
    case PAYMENT_METHOD_TYPES.RAZORPAY:
      return isMembershipCheckout ? 'razorpay-card' : 'razorpay';
    case PAYMENT_METHOD_TYPES.EBANX:
      return 'ebanx-card';
    default:
      return method?.id;
  }
};

export const flexibleAmountSchema = ({ minAmount, currency }) => {
  return yup.object().shape({
    amount: yup.number().min(
      minAmount / 100,
      t('minimum-amount', {
        amount: formatNumber(minAmount, true, true),
        currency
      })
    )
  });
};

// if any of these errors occur we will switch to other card provider if available
export const StripeSwitchErrorCodes = [
  'PAYMENT_FAILURE_BANK_DECLINE',
  'PAYMENT_FAILURE_GENERIC_DECLINE',
  'PAYMENT_FAILURE_HIGH_RISK_TRANSACTION'
];

export const StripeSDKSwitchErrorCodes = [
  'call_issuer',
  'card_not_supported',
  'currency_not_supported',
  'do_not_honor',
  'do_not_try_again',
  'fraudulent',
  'generic_decline',
  'issuer_not_available',
  'no_action_taken',
  'not_permitted',
  'try_again_later',
  'stop_payment_order'
];

export const PRICE_TYPE_FIXED = 'FIXED';
export const PRICE_TYPE_FLEXIBLE = 'FLEXIBLE';

// if any of these errors occur we will switch to other card provider if available
export const EbanxSwitchErrorCodes = [
  'PAYMENT_FAILURE_CANNOT_PROCESS_TRANSACTION_AT_THIS_MOMENT',
  'PAYMENT_FAILURE_UNKNOWN_ACQUIRER_ERROR',
  'PAYMENT_FAILURE_COMMUNICATION_MISMATCH',
  'PAYMENT_FAILURE_BROKEN_COMMUNICATION',
  'PAYMENT_FAILURE_ACQUIRER_RESPONSE_ERROR',
  'PAYMENT_FAILURE_NO_RESPONSE_FROM_ACQUIRER',
  'PAYMENT_FAILURE_TIMEOUT',
  'PAYMENT_FAILURE_HIGH_RISK_TRANSACTION',
  'PAYMENT_FAILURE_BANK_DECLINE',
  'PAYMENT_FAILURE_NOT_ACCEPTED'
];

export const getCustomStripeErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'call_issuer':
      return t('payment-failure-call-issuer');
    case 'card_not_supported':
      return t('payment-failure-card-not-supported');
    case 'currency_not_supported':
      return t('payment-failure-currency-not-supported');
    case 'do_not_honor':
      return t('payment-failure-do-not-honor');
    case 'do_not_try_again':
      return t('payment-failure-do-not-try-again');
    case 'generic_decline':
      return t('payment-failure-generic-decline');
    case 'fraudulent':
      return t('payment-failure-fraudulent');
    case 'issuer_not_available':
      return t('payment-failure-issuer-not-available');
    case 'no_action_taken':
      return t('payment-failure-no-action-taken');
    case 'not_permitted':
      return t('payment-failure-not-permitted');
    case 'stop_payment_order':
      return t('payment-failure-stop-payment-order');
    case 'try_again_later':
      return t('payment-failure-try-again-later');
    default:
      return null;
  }
};

export const stripeElementsAppearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#946800',
    colorBackground: '#ffffff',
    colorText: '#1B1B18',
    colorDanger: '#CE2C31',
    fontFamily: 'Poppins, sans-serif',
    spacingUnit: '5px',
    borderRadius: '12px'
    // See all possible variables below
  },
  rules: {
    '.AccordionItem': {
      fontWeight: '500'
    },
    '.Label': {
      fontSize: '14px'
    },
    // '.AccordionItem--selected': {
    //   borderColor: '#1B1B18'
    // },
    '.AccordionItem:hover': {
      borderColor: '#c8c7c1'
    }
  }
};

// This is used in src/pages/checkout-global/PaymentPanelContainer.jsx
export const upiHelpMethods = [
  {
    label: 'Google Pay',
    id: 'google-pay',
    imageProps: {},
    renderContent: () => {
      return (
        <div className="p-20 font-poppins">
          <img
            alt="google-pay"
            src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/google-pay-example.png`}
            className="w-full rounded-8"></img>
          <div className="mt-16 whitespace-pre-line text-body-md text-npl-text-icon-on-light-surface-primary">
            <div>
              {t('locate-the-upi-id-in-the')}{' '}
              <span className="font-semibold">{t('main-screen')}</span>
            </div>
            <div>{t('copy-the-id')}</div>
            <div>{t('paste-it-in-the-checkout-pa')}</div>
          </div>
        </div>
      );
    },
    icon: (
      <img
        src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/google-pay.png`}
        alt="google pay"
        height={24}
        width={34}
      />
    )
  },
  {
    label: 'PhonePe',
    id: 'phonePe',
    imageProps: {},
    icon: (
      <img
        src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/phone-pe.png`}
        alt="phone-pe"
        height={24}
        width={34}
      />
    ),

    renderContent: () => {
      return (
        <div className="p-20 font-poppins">
          <img
            alt="phone-pe"
            src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/phone-pe-example.png`}
            className="w-full rounded-8"></img>
          <div className="mt-16 whitespace-pre-line text-body-md text-npl-text-icon-on-light-surface-primary">
            <div>
              {t('locate-the-upi-id-in-the')}{' '}
              <span className="font-semibold">{t('main-screen')}</span>
            </div>
            <div>{t('copy-the-id')}</div>
            <div>{t('paste-it-in-the-checkout-pa')}</div>
          </div>
        </div>
      );
    }
  },
  {
    label: 'Paytm',
    id: 'paytm',
    imageProps: {},
    icon: (
      <img
        src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/pay-tm.png`}
        alt="pay-tm"
        height={24}
        width={34}
      />
    ),

    renderContent: () => {
      return (
        <div className="p-20 font-poppins">
          <img
            alt="pay-tm-example"
            src={`${STATIC_ASSET_BASE_URL}/learning-portal/png/pay-tm-example.png`}
            className="w-full rounded-8"></img>
          <div className="mt-16 whitespace-pre-line text-body-md text-npl-text-icon-on-light-surface-primary">
            <div>
              {t('locate-the-upi-id-in-the')}{' '}
              <span className="font-semibold">{t('main-screen')}</span>
            </div>
            <div>{t('copy-the-id')}</div>
            <div>{t('paste-it-in-the-checkout-pa')}</div>
          </div>
        </div>
      );
    }
  }
];

export const LOCAL_PAYMENT_PROVIDER_ICONS = {
  BRL: [
    'ebanx_card',
    'ebanx_pix',
    'ebanx_mercado',
    'ebanx_boleto',
    'ebanx_nu'
  ],
  MXN: ['ebanx_card', 'ebanx_oxxo', 'ebanx_mercado', 'ebanx_spei'],
  PEN: ['ebanx_card', 'ebanx_pagoefectivo', 'ebanx_safetypay'],
  COP: ['ebanx_card', 'ebanx_efecty', 'ebanx_nequi']
};
