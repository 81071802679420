import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  NodeKey,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef
} from 'react';

import type {
  MentionedCardLinkType,
  MentionedCardSizeType
} from '@/components/common/MentionedCard/types';

import { $isMentionedProductNode, MentionedProductNode } from '..';
import type { MentionedEntityDataType } from '../types';
import MentionedProductCardContainer from './MentionedProductCardContainer';

type MentionedProductNodeComponentProps = {
  className: Readonly<{ base: string; focus: string }>;
  entityData: MentionedEntityDataType;
  link: string;
  linkType: MentionedCardLinkType;
  nodeKey: NodeKey;
  size: MentionedCardSizeType;
};

const MentionedProductNodeComponent: React.FC<
  MentionedProductNodeComponentProps
> = ({ linkType, size, nodeKey, className, entityData, link }) => {
  const elementRef = useRef<null | HTMLDivElement>(null);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();
  const activeEditorRef = useRef<LexicalEditor | null>(null);

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if (node && $isMentionedProductNode(node)) {
          node.remove();
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.shiftKey) {
        setSelected(!isSelected);
      } else {
        clearSelection();
        setSelected(true);
      }
    },
    [isSelected, setSelected, clearSelection]
  );

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (elementRef.current === event.target) {
        $setSelection(null);
        editor.update(() => {
          setSelected(true);
          const parentRootElement = editor.getRootElement();
          if (parentRootElement !== null) {
            parentRootElement.focus();
          }
        });
        return true;
      }
      return false;
    },
    [editor, setSelected]
  );
  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      const latestSelection = $getSelection();
      const currentElement = elementRef.current;

      if (
        isSelected &&
        $isNodeSelection(latestSelection) &&
        latestSelection.getNodes().length === 1
      ) {
        const imageNode = $getNodeByKey(nodeKey);
        const paragraphNode = $createParagraphNode();
        imageNode?.insertAfter(paragraphNode);
        paragraphNode.select();
        if (
          currentElement !== null &&
          currentElement !== document.activeElement
        ) {
          event.preventDefault();
          currentElement.focus();
          return true;
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        onEscape,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        onEnter,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      unregister();
    };
  }, [
    clearSelection,
    editor,
    isSelected,
    nodeKey,
    onDelete,
    onClick,
    setSelected,
    onEnter,
    onEscape
  ]);

  return (
    <BlockWithAlignableContents
      className={className}
      format="center"
      nodeKey={nodeKey}>
      <div
        className="flex w-full h-full cursor-pointer flex-row items-center justify-center"
        ref={elementRef}
        onClick={onClick}>
        <MentionedProductCardContainer
          entityData={entityData}
          isEditable={editor.isEditable()}
          updateSize={(updatedSize) =>
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if (node && $isMentionedProductNode(node)) {
                (node as MentionedProductNode).setSize(updatedSize);
              }
            })
          }
          updateLinkType={(updatedLinkType) => {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if (node && $isMentionedProductNode(node)) {
                (node as MentionedProductNode).setLinkType(
                  updatedLinkType
                );
              }
            });
          }}
          deleteNode={() => {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if (node && $isMentionedProductNode(node)) {
                node.remove();
              }
            });
          }}
          linkType={linkType}
          size={size}
          link={link}
        />
      </div>
    </BlockWithAlignableContents>
  );
};

export default MentionedProductNodeComponent;
