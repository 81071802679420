import useSWR from 'swr';

import { getAllCommunities } from '@/services/communitiesService';

// Define types
type Props = {
  fetchParams: {
    includePendingSubs?: boolean;
    showManager?: boolean;
    showPaid?: boolean;
  };
};

const useUserCommunities = ({
  fetchParams = {
    showManager: false,
    showPaid: false,
    includePendingSubs: true
  }
}: Props) => {
  const { data, isLoading } = useSWR(
    fetchParams,
    (params) => getAllCommunities(params),
    {
      revalidateOnFocus: false
    }
  );

  const userCommunities = data?.data?.data || [];
  const sortedManagerFirst = userCommunities.sort((a, b) => {
    if (a.isManager && !b.isManager) return -1;
    if (!a.isManager && b.isManager) return 1;
    return a.title.localeCompare(b.title);
  });

  return {
    isLoading,
    userCommunities: sortedManagerFirst
  };
};
export default useUserCommunities;
