import { communitySubscriptionStatus } from '@/data/communities';
import { useMemo } from 'react';
import useSWR from 'swr';

import { getIsMemberBySubscription } from '@/utility/communityHelpers';
import config from '@/utility/config';

import { axiosFetcher } from '@/services/helpers/swr';

// Define types
type Props = {
  communityId: string | null;
  hasToken: boolean;
};

const useUserRoleInCommunity = ({ communityId, hasToken }: Props) => {
  const shouldFetch = hasToken && communityId;
  const apiPath = shouldFetch
    ? `${config?.communityApiBasePath}/api/v1/communities/${communityId}/learner`
    : '';

  const {
    data,
    isLoading,
    mutate: refetchUserRoleInCommunity
  } = useSWR(apiPath, axiosFetcher);

  const isFetching = shouldFetch && (!data || isLoading);
  const communityDataWithUserRole = data?.data;
  const isUserCommunityRoleFetched = shouldFetch
    ? !isFetching && communityDataWithUserRole
    : true;

  const isCommunityAdmin = Boolean(communityDataWithUserRole?.isManager);
  const isPendingApproval =
    communityDataWithUserRole?.subscription?.status ===
    communitySubscriptionStatus?.pending;
  const isCommunityMember = useMemo(
    () =>
      getIsMemberBySubscription(communityDataWithUserRole?.subscription),
    [communityDataWithUserRole]
  );

  const isOnGracePeriod =
    communityDataWithUserRole?.subscription?.onGracePeriod;

  return {
    communityDataWithUserRole,
    isPendingApproval,
    isCommunityMember,
    isCommunityAdmin,
    isOnGracePeriod,
    isFetching,
    isUserCommunityRoleFetched,
    error: data?.error,
    refetchUserRoleInCommunity
  };
};
export default useUserRoleInCommunity;
