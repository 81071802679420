import classNames from 'classnames';
import { LazyMotion, domMax, m } from 'framer-motion';
import { FC } from 'react';

import { useNPLModal } from '../context/NPLModalContext';
import { NPLModalContainerProps } from '../types';

const NPLModalContainer: FC<NPLModalContainerProps> = ({
  children,
  size = 550
}) => {
  const { isClosed, onClose, animationDuration } = useNPLModal();

  return (
    <div className="z-over-intercom fixed">
      <LazyMotion features={domMax} strict>
        <m.div
          initial={{ y: '8%', opacity: 0 }}
          animate={{ y: isClosed ? '8%' : 0, opacity: isClosed ? 0 : 1 }}
          onAnimationComplete={(definition) => {
            const { opacity } = definition as { opacity: number };
            if (opacity === 0) {
              onClose?.();
            }
          }}
          transition={{
            type: 'tween',
            duration: animationDuration / 1000
          }}
          className={classNames(
            'fixed inset-x-16 mx-auto bottom-40 md:inset-0 my-auto md:m-auto max-h-[calc(100vh-80px)] h-fit overflow-auto',
            'bg-white-default rounded-28'
          )}
          style={{ maxWidth: `${size}px` }}>
          <div className="flex flex-col w-full h-fit max-h-[calc(100dvh-80px)]">
            {children}
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
};

export default NPLModalContainer;
