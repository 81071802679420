import React from 'react';

import { t } from '@/utility/localization';

import InfiniteList from '@/components/common/InfiniteList';
import Popover from '@/components/common/Popover';

import SearchBox from '@/pages/portal/components/SearchBox/SearchBox';

import Icon from '../Icon';
import NPLSearchDropdownItem from './components/NPLSearchDropdownItem';
import NPLSearchDropdownContent from './components/NPLSearchDropdownItemContent';
import NPLSearchDropdownLead from './components/NPLSearchDropdownLead';
import NPLSearchDropdownTail from './components/NPLSearchDropdownTail';
import type {
  NPLSearchDropdownOptionProps,
  NPLSearchDropdownProps
} from './types';

const NPLSearchDropdown: React.FC<NPLSearchDropdownProps> & {
  Content: typeof NPLSearchDropdownContent;
  Item: typeof NPLSearchDropdownItem;
  Lead: typeof NPLSearchDropdownLead;
  Tail: typeof NPLSearchDropdownTail;
} = ({
  searchPlaceholder,
  hasSearch,
  onSearch,
  onChange,
  searchValue,
  isMultiSelect,
  children,
  options,
  loading,
  fetchMore,
  emptyMessage,
  emptyIcon,
  hasMore,
  renderTrigger
}) => {
  // TODO-neil
  // multiselect footer with save and select all

  return (
    <Popover
      placement="bottom-start"
      content={(onClose) => (
        <div>
          <div className="flex flex-col w-full relative shadow-lg min-w-[300px] max-w-[400px] max-h-[400px] overflow-y-auto overflow-hidden rounded-8 bg-npl-base-white px-8 py-12 min-h-0">
            {hasSearch && (
              <div className="w-full px-8 pt-4 pb-8">
                <SearchBox
                  loading={loading}
                  placeholder={searchPlaceholder}
                  initialValue={searchValue}
                  onChange={onSearch}
                />
              </div>
            )}
            {options.length === 0 && (
              <div className="w-full px-8 py-12 text-label-md font-medium text-npl-text-secondary-on-light flex flex-col items-center">
                <div className="rounded-full h-48 w-48 p-10 bg-npl-neutral-light-alpha-3 mb-12 mt-12">
                  {
                    <Icon
                      name={emptyIcon || 'box'}
                      fill="#1B1B1899"
                      height={28}
                      width={28}
                    />
                  }
                </div>
                <p>{emptyMessage || t('no-items-found')}</p>
              </div>
            )}
            <div className="flex-1 overflow-auto">
              <InfiniteList
                items={options}
                isLoading={loading}
                isChunked={false}
                hasMore={hasMore}
                loadMore={() => {
                  fetchMore && fetchMore();
                }}
                renderSkeleton={() => (
                  <div className="h-[56px] w-full rounded-4 bg-npl-neutral-light-alpha-3" />
                )}>
                {(option: NPLSearchDropdownOptionProps, index) => {
                  return (
                    <div key={index}>
                      {children({
                        ...option,
                        index,
                        isMultiSelect,
                        onClose,
                        onClick: () => {
                          onChange(option?.value);
                          !isMultiSelect && onClose();
                        }
                      })}
                    </div>
                  );
                }}
              </InfiniteList>
            </div>
          </div>
        </div>
      )}>
      <button>{renderTrigger?.() || <Icon name="arrow-down" />}</button>
    </Popover>
  );
};

NPLSearchDropdown.Tail = NPLSearchDropdownTail;
NPLSearchDropdown.Lead = NPLSearchDropdownLead;
NPLSearchDropdown.Content = NPLSearchDropdownContent;
NPLSearchDropdown.Item = NPLSearchDropdownItem;

export default NPLSearchDropdown;
